<template>
    <div class="modal fade" tabindex="-1" role="dialog" ref="modalFeedback">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Your ad is being processed ...</h4>
                </div>
                <div class="modal-body">
                    <p><span class="fa fa-spinner fa-spin"/>{{ message }}</p>
                    <p class="bg-danger" v-if="hasFailed"><strong>Something went wrong. Retry please.</strong></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import stateMixin from '../../../../../../../Common/js/mixins/state';

  export default {
    constants: {
      STATE: stateMixin.constants.STATE,
    },
    props: {
      state: {
        type: String,
        required: true,
      },

      message: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        hasFailed: false,
      };
    },

    watch: {
      state(newState) {
        this.hasFailed = false;
        const isProcessing = newState == this.STATE.PROCESSING;
        const hasFailed = newState == this.STATE.FAILURE;
        this.hasFailed = hasFailed;
        const showModal = isProcessing || hasFailed;
        $(this.$refs.modalFeedback).modal(showModal ? 'show' : 'hide');
      },
    },
  };
</script>
