import { render, staticRenderFns } from "./PlaceAnAdPipe.vue?vue&type=template&id=62f2f52d&"
import script from "./PlaceAnAdPipe.vue?vue&type=script&lang=js&"
export * from "./PlaceAnAdPipe.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports