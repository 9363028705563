import apolloClient from "../../../../../Common/js/modules/service/GraphQLClient";

// Common resources
import * as commonActions from '../../../../../Common/js/modules/store/actions';

// GraphQL Mutations & queries
import {
  USER_SUBSCRIBE_MUTATION,
  SET_MEMBER_CATEGORY_SUBSCRIPTIONS_MUTATION,
  SUBSCRIBE_TO_CATEGORY_MUTATION,
} from "../graphql/mutations";
import {
  LOAD_MY_CLASSIFIED_ADS_CATEGORIES,
} from "../graphql/queries";

export default {
  ...commonActions.default,

  loadMyClassifiedAdsCategories({ commit }, { uuid }) {
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: LOAD_MY_CLASSIFIED_ADS_CATEGORIES,
        variables: { uuid },
      }).then((response) => {
        const categories = response.data['Member']['myClassifiedAdsCategories'];
        resolve(categories);
      }, reject);
    });
  },

  userSubscribe({ commit, dispatch }, { payload }) {
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: USER_SUBSCRIBE_MUTATION,
        variables: {
          payload,
        },
      }).then(resolve, reject);
    });
  },

  setCategorySubscriptions({ commit, dispatch }, { uuid, payload }) {
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: SET_MEMBER_CATEGORY_SUBSCRIPTIONS_MUTATION,
        variables: {
          uuid,
          payload,
        },
      }).then(resolve, reject);
    });
  },

  subscribeToCategory({ commit, dispatch }, { uuid, categoryUuid }) {
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: SUBSCRIBE_TO_CATEGORY_MUTATION,
        variables: {
          uuid,
          categoryUuid,
        },
      }).then(resolve, reject);
    });
  },

}
