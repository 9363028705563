/**
 * @see PHP \App\Application\FrontOffice\Offer\Payload\PlaceAnAddPaymentMethod
 */
const PremiumOptionSelection = {
  SINGLE_PREMIUM_AD: "SINGLE_PREMIUM_AD",
  FREE: "FREE",
  PURCHASE_PACK: "PURCHASE_PACK",
};

export default PremiumOptionSelection;
