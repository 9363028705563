var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-bloc" }, [
      _c("h2", { staticClass: "form-bloc__title" }, [
        _vm._v("Please select a category for your classified ad"),
      ]),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isAuction,
              expression: "isAuction",
            },
          ],
          staticClass: "form-bloc__help",
        },
        [
          _vm._v(
            "\n            You can post a free ad for 24 hours or a Premium classified ad for 1 month (185 euros)\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "fa fa-spin fa-spinner" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass: "list-group",
        },
        [
          _c("li", { staticClass: "list-group-item list-group__previous" }, [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("goToPrevious")
                  },
                },
              },
              [
                _c("i", { staticClass: "icon icon--back" }),
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.typeLabel) +
                    "\n                "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.industries, function (industry) {
            return _c("li", { staticClass: "list-group-item" }, [
              _c("div", { staticClass: "radio" }, [
                _c("input", {
                  attrs: {
                    type: "radio",
                    name: "auction-industry",
                    id: "industry-" + industry.uuid,
                  },
                  domProps: { checked: industry.uuid == _vm.categoryUuid },
                  on: {
                    click: function ($event) {
                      return _vm.setIndustry(industry.uuid)
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "industry-" + industry.uuid } }, [
                  _vm._v(_vm._s(industry.name)),
                ]),
              ]),
            ])
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "shadow-container__footer" }, [
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "inner-footer" }, [
        _c(
          "button",
          {
            staticClass: "button-back btn btn--empty",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("goToPrevious")
              },
            },
          },
          [
            _c("i", { staticClass: "icon icon--previous" }),
            _vm._v("\n                Back\n            "),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }