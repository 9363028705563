var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: { "has-error": _vm.props.errors.length > 0 },
    },
    [
      _c("label", { attrs: { for: _vm.props.inputName } }, [
        _vm._v("\n        " + _vm._s(_vm.props.label) + "\n    "),
      ]),
      _vm._v(" "),
      _vm._t("input", null, { _class: "form-control" }),
      _vm._v(" "),
      _vm.props.errors.length > 0
        ? [
            _c("span", { staticClass: "help-block" }, [
              _c(
                "ul",
                { staticClass: "list-unstyled" },
                _vm._l(_vm.props.errors, function (message) {
                  return _c("li", [
                    _c("i", { staticClass: "fa fa-exclamation-triangle" }),
                    _vm._v(" " + _vm._s(message) + "\n                "),
                  ])
                }),
                0
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }