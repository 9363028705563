var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-bloc" }, [
      _c("h1", { staticClass: "form-bloc__title" }, [
        _vm._v("Please choose your option"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pricing" }, [
        _vm.suggestFree
          ? _c("div", { staticClass: "pricing__item" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "pricing__footer" }, [
                _c("span", { staticClass: "price" }, [_vm._v("Free")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onSubmitFree.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Choose this plan")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.suggestSinglePremium
          ? _c("div", { staticClass: "pricing__item" }, [
              _vm._m(2),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _vm._m(4),
              _vm._v(" "),
              _vm._m(5),
              _vm._v(" "),
              _vm._m(6),
              _vm._v(" "),
              _vm._m(7),
              _vm._v(" "),
              _vm._m(8),
              _vm._v(" "),
              _vm._m(9),
              _vm._v(" "),
              _c("div", { staticClass: "pricing__footer" }, [
                _vm._m(10),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--premium",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onSubmitSingle.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Choose this plan")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pricing__item pricing__item--package" },
          [
            _vm._m(11),
            _vm._v(" "),
            _vm._m(12),
            _vm._v(" "),
            _vm._m(13),
            _vm._v(" "),
            _vm._m(14),
            _vm._v(" "),
            _vm._m(15),
            _vm._v(" "),
            _vm._m(16),
            _vm._v(" "),
            _vm._m(17),
            _vm._v(" "),
            _vm._l(_vm.premiumPacks, function (pack) {
              return _c("div", { staticClass: "pricing__offer" }, [
                _c("div", { staticClass: "radio" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedPack,
                        expression: "selectedPack",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "pricing-choice",
                      id: pack.uuid,
                    },
                    domProps: {
                      value: pack.uuid,
                      checked: _vm._q(_vm.selectedPack, pack.uuid),
                    },
                    on: {
                      change: function ($event) {
                        _vm.selectedPack = pack.uuid
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: pack.uuid } }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.numberOfAds(pack)) +
                        " Premium Ads\n                            "
                    ),
                    pack.numberOfAds
                      ? _c("span", [
                          _vm._v(
                            "€" +
                              _vm._s(_vm.pricePerAdAndMonth(pack)) +
                              " per Ad/month"
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "price" }, [
                  _vm._v("€ " + _vm._s(parseInt(pack.price))),
                ]),
              ])
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pricing__footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn--premium",
                  attrs: { disabled: !_vm.selectedPack },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitPack.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Choose this plan")]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "conditions" }, [
                _c("span", [_vm._v("*")]),
                _vm._v("\n                        Feel free to "),
                _c("a", { attrs: { href: _vm.contactUsUri } }, [
                  _vm._v("contact us"),
                ]),
                _vm._v(
                  " for more information on these services, or if you have any questions\n                    "
                ),
              ]),
            ]),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__header" }, [
      _c("span", { staticClass: "title" }, [_vm._v("Basic Classified Ad")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("5 photos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__header" }, [
      _c("span", { staticClass: "title" }, [_vm._v("Premium Classified Ad")]),
      _vm._v(" "),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v("Premium status ensures maximum exposure for your product"),
      ]),
      _vm._v(" "),
      _c("img", { attrs: { src: "/build/images/premium.png", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Your Premium Ad is validated immediately")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Unlimited photos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Unlimited PDF files")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Unlimited videos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Your product is at the top of search listings")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Your Premium Ad is published on our Newsletter")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Your product will be online until sold")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "price" }, [
      _vm._v(
        "\n                            Only 39 euros for 1 year\n                            "
      ),
      _c("span", [_vm._v("(€3.25 per month)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__header" }, [
      _c("span", { staticClass: "title" }, [
        _vm._v("Special packages for professional"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v("Need to place more than 10 Premium Classified Ads ?"),
      ]),
      _vm._v(" "),
      _c("img", { attrs: { src: "/build/images/premium.png", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Special package price")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Your premium ad is validated immediately")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Unlimited photos, PDF files & videos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Your product is at the top of the search listings")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Inclusion of your Ads in our Weekly Newsletter")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "Our system automatically downloads and updates your offers, saving you time and money "
        ),
        _c("sup", { staticClass: "asterisk" }, [_vm._v("*")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }