<template>
    <div>
        <div class="form-bloc">
            <h2 class="form-bloc__title">Please select a category for your classified ad</h2>
            <p class="form-bloc__help" v-show="isAuction">
                You can post a free ad for 24 hours or a Premium classified ad for 1 month (185 euros)
            </p>

            <div class="fa fa-spin fa-spinner" v-if="loading" />

            <ul class="list-group" v-show="!loading">
                <li class="list-group-item list-group__previous">
                    <a href="#" @click.prevent="$emit('goToPrevious')">
                        <i class="icon icon--back"></i>
                        {{ typeLabel }}
                    </a>
                </li>
                <li class="list-group-item" v-for="industry in industries">
                    <div class="radio">
                        <input type="radio" name="auction-industry" :id="'industry-' + industry.uuid" @click="setIndustry(industry.uuid)" :checked="industry.uuid == categoryUuid">
                        <label :for="'industry-' + industry.uuid">{{ industry.name }}</label>
                    </div>
                </li>
            </ul>
        </div>
        <div class="shadow-container__footer">
            <hr>
            <div class="inner-footer">
                <button class="button-back btn btn--empty" @click.prevent="$emit('goToPrevious')">
                    <i class="icon icon--previous"></i>
                    Back
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import {OfferType as OFFER_TYPES} from '../../../../../../../Common/js/helpers/enums';
  import { getChoiceLabel, getChoiceForOffer } from "./TypeChoices";
  import { mapActions, mapGetters } from 'vuex';

  export default {
    inject: ['App'],

    constants: {
      OFFER_TYPES: OFFER_TYPES,
    },

    props: {
      offer: {
        type: Object,
        required: true,
      },
    },

    data: function() {
      return {
        categoryUuid: null,
        loading: false,
      };
    },

    created() {
      this.loading = true;
      this.categoryUuid = this.offer.categoryUuid;
      this.App.registerOnReadyListener(() => {
        this.loadCategories().then(() => {
          this.loading = false;
        })
      });
    },

    methods: {
      ...mapActions([
        'loadCategories',
      ]),

      setIndustry(uuid) {
        this.categoryUuid = uuid;
        this.$emit('updated', { categoryUuid: this.categoryUuid });
      },
    },

    watch: {
      offer: function (val) {
        this.categoryUuid = val.categoryUuid;
      },
    },

    computed: {
      ...mapGetters([
        'industries',
      ]),

      typeLabel() {
        return getChoiceLabel(getChoiceForOffer(this.offer));
      },

      isAuction() {
        return this.offer.type === this.OFFER_TYPES.AUCTION
      },
    }
  };
</script>
