import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import Vue from "vue";

export default {
  /**
   * @see https://docs.sentry.io/error-reporting/configuration/?platform=browser
   * @see https://docs.sentry.io/enriching-error-data/context/?platform=browser
   */
  configure() {
    // XXX: uncomment and see why process.env.SENTRY_DSN is not defined

    if ("dev" === process.env.APP_ENV) {
      // dev mode configured, skip:
      return;
    }

    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      integrations: [new BrowserTracing()],
      environment: process.env.DEPLOY_ENV,
      release: "wotol@" + process.env.APP_VERSION, // ? XXX: change version number
    });

    Sentry.configureScope((scope) => {
      scope.setTag("project", "wotol");
      scope.setTag("app", "frontend");
      scope.setTag("node_env", process.env.NODE_ENV);
    });
  },

  setUser(email, uuid) {
    if ("dev" === process.env.APP_ENV) {
      // dev mode configured, skip:
      return;
    }

    Sentry.configureScope((scope) => {
      scope.setUser({ id: uuid, email });
    });
  },
};
