var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.loading
      ? _c("div", [_c("i", { staticClass: "fa fa-spin fa-spinner" })])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading",
          },
        ],
        ref: "select2",
        class: _vm.classes,
        attrs: { id: _vm.id, required: _vm.required, multiple: _vm.multiple },
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.value = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      [
        !_vm.required
          ? _c("option", { attrs: { value: "" } }, [_vm._v("No country")])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.orderedCountries, function (country) {
          return _c("option", { domProps: { value: country.uuid } }, [
            _vm._v("\n            " + _vm._s(country.name) + "\n        "),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }