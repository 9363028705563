var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "shadow-container__content" }, [
        _c("div", [
          _vm.sendError
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("\n                An error occurred\n            "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.states.isSaved
            ? _c("div", { staticClass: "alert alert-success" }, [
                _vm._v(
                  "\n                Your Ad has successfully been updated\n            "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        !_vm.offer
          ? _c(
              "div",
              {
                staticClass: "panel-loading",
                staticStyle: { height: "100px" },
              },
              [_c("span", { staticClass: "fa fa-spin fa-spinner" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.states.isSaved
          ? _c("div", { staticClass: "alert alert-success" }, [
              _vm._v(
                "\n            Your ad was successfully updated!\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.sendError
          ? _c("div", { staticClass: "alert alert-success" }, [
              _vm._v(
                "\n            An error occurred while updating your ad!\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.offer
          ? _c("div", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-8" }, [
                  _c("div", { staticClass: "form-bloc" }, [
                    _c(
                      "div",
                      { staticClass: "form-bloc__row edit-page__breadcrumb" },
                      [
                        _c("label", [_vm._v("Category")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "breadcrumb" },
                          _vm._l(_vm.categoryHierarchy, function (category) {
                            return _c("li", [_vm._v(_vm._s(category.name))])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-bloc" }, [
                    _c("div", { staticClass: "form-bloc__row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "edit-offer-title" },
                        },
                        [_vm._v("Offer title")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.title,
                            expression: "form.title",
                          },
                        ],
                        staticClass: "form-control form-control--large",
                        attrs: {
                          type: "text",
                          id: "edit-offer-title",
                          maxlength: _vm.maxLengths.TITLE,
                          required: "",
                          disabled: _vm.isTypeMachine,
                        },
                        domProps: { value: _vm.form.title },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "title", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: { click: _vm.copyTitleToClipboard },
                          },
                          [_c("i", { staticClass: "fa fa-clipboard" })]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.fieldError("title")
                      ? _c("div", { staticClass: "form-bloc__error" }, [
                          _c(
                            "ul",
                            _vm._l(_vm.fieldError("title"), function (message) {
                              return _c("li", [
                                _c("i", {
                                  staticClass: "fa fa-exclamation-triangle",
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(message) +
                                    "\n                                "
                                ),
                              ])
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _vm.isTypeMachine
                    ? _c("div", { staticClass: "form-bloc" }, [
                        _c(
                          "div",
                          { staticClass: "form-bloc__row" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "edit-manufacturer" } },
                              [_vm._v("Manufacturer")]
                            ),
                            _vm._v(" "),
                            _vm.states.offerIsLoaded
                              ? _c("SelectManufacturer", {
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "edit-manufacturer",
                                    "category-uuid": _vm.offer.category,
                                  },
                                  model: {
                                    value: _vm.form.manufacturerUuids,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "manufacturerUuids",
                                        $$v
                                      )
                                    },
                                    expression: "form.manufacturerUuids",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isTypeMachine ? _c("hr") : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-bloc" },
                    [
                      _vm._l(_vm.offer.freeFields, function (freeField) {
                        return _c("div", { staticClass: "form-bloc__row" }, [
                          _c("label", [_vm._v(_vm._s(freeField.name))]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.lazy.trim",
                                value:
                                  _vm.form.freeFields[freeField.uuid] &&
                                  _vm.form.freeFields[freeField.uuid].value,
                                expression:
                                  "form.freeFields[freeField.uuid] && form.freeFields[freeField.uuid].value",
                                modifiers: { lazy: true, trim: true },
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "offer-free-field-" + freeField.uuid,
                              type: "text",
                              maxlength: _vm.maxLengths.FREE_FIELD_VALUE,
                            },
                            domProps: {
                              value:
                                _vm.form.freeFields[freeField.uuid] &&
                                _vm.form.freeFields[freeField.uuid].value,
                            },
                            on: {
                              change: function ($event) {
                                _vm.$set(
                                  _vm.form.freeFields[freeField.uuid] &&
                                    _vm.form.freeFields[freeField.uuid],
                                  "value",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ])
                      }),
                      _vm._v(" "),
                      _vm.isTypeMachine
                        ? _c("div", { staticClass: "form-bloc__row" }, [
                            _c("label", { attrs: { for: "edit-quantity" } }, [
                              _vm._v("Quantity"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.form.quantity,
                                  expression: "form.quantity",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "input-error": _vm.fieldError("quantity"),
                              },
                              attrs: {
                                type: "number",
                                min: "0",
                                id: "edit-quantity",
                                required: "",
                              },
                              domProps: { value: _vm.form.quantity },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "quantity",
                                    _vm._n($event.target.value)
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.fieldError("quantity")
                              ? _c("div", { staticClass: "error" }, [
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.fieldError("quantity"),
                                      function (message) {
                                        return _c("li", [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-exclamation-triangle",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(message) +
                                              "\n                                    "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isTypeMachine
                        ? _c(
                            "div",
                            { staticClass: "form-bloc__row" },
                            [
                              _c("label", { attrs: { for: "edit-year" } }, [
                                _vm._v("Year(s)"),
                              ]),
                              _vm._v(" "),
                              _vm.states.offerIsLoaded
                                ? _c("Tags", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "edit-year",
                                      options: _vm.form.years,
                                      "value-type": "int",
                                    },
                                    model: {
                                      value: _vm.form.years,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "years", $$v)
                                      },
                                      expression: "form.years",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isTypeMachine
                        ? _c("div", { staticClass: "form-bloc__row" }, [
                            _c("label", { attrs: { for: "edit-price" } }, [
                              _vm._v("Price"),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.price,
                                  expression: "form.price",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "edit-price",
                                maxlength: _vm.maxLengths.SELLER_PRICE,
                              },
                              domProps: { value: _vm.form.price },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "price",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-bloc__row" },
                        [
                          _c("label", { attrs: { for: "edit-country" } }, [
                            _vm._v("Country"),
                          ]),
                          _vm._v(" "),
                          _c("SelectCountry", {
                            attrs: {
                              id: "edit-country",
                              classes: "form-control",
                            },
                            model: {
                              value: _vm.form.countryUuid,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "countryUuid", $$v)
                              },
                              expression: "form.countryUuid",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-bloc__row" }, [
                        _c("label", { attrs: { for: "edit-reference" } }, [
                          _vm._v("Your reference"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.reference,
                              expression: "form.reference",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "edit-reference",
                            maxlength: _vm.maxLengths.SELLER_REFERENCE,
                          },
                          domProps: { value: _vm.form.reference },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "reference",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-bloc__row" }, [
                        _c("label", { attrs: { for: "wotol-reference" } }, [
                          _vm._v("Wotol reference"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.offer.resellerReference,
                              expression: "offer.resellerReference",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            disabled: "",
                            type: "text",
                            id: "wotol-reference",
                          },
                          domProps: { value: _vm.offer.resellerReference },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.offer,
                                "resellerReference",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _vm.isTypeAuction
                        ? _c(
                            "div",
                            { staticClass: "form-bloc__row" },
                            [
                              _c("label", [_vm._v("Start")]),
                              _vm._v(" "),
                              _vm.states.offerIsLoaded ? _vm._m(1) : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.fieldError("startsAt")
                        ? _c("div", { staticClass: "error" }, [
                            _c(
                              "ul",
                              _vm._l(
                                _vm.fieldError("startsAt"),
                                function (message) {
                                  return _c("li", [
                                    _c("i", {
                                      staticClass: "fa fa-exclamation-triangle",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(message) +
                                        "\n                                "
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isTypeAuction
                        ? _c(
                            "div",
                            { staticClass: "form-bloc__row" },
                            [
                              _c("label", [_vm._v("End")]),
                              _vm._v(" "),
                              _vm.states.offerIsLoaded ? _vm._m(2) : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.fieldError("endsAt")
                        ? _c("div", { staticClass: "error" }, [
                            _c(
                              "ul",
                              _vm._l(
                                _vm.fieldError("endsAt"),
                                function (message) {
                                  return _c("li", [
                                    _c("i", {
                                      staticClass: "fa fa-exclamation-triangle",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(message) +
                                        "\n                                "
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isTypeAuction
                        ? _c("div", { staticClass: "form-bloc__row" }, [
                            _c("label", [_vm._v("External link")]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.originalUrl,
                                  expression: "form.originalUrl",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                maxlength: _vm.maxLengths.ORIGINAL_URL,
                                placeholder: "https://",
                              },
                              domProps: { value: _vm.form.originalUrl },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "originalUrl",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c("div", { staticClass: "edit-page__description" }, [
                        _c("label", {
                          staticClass: "sr-only",
                          attrs: { for: "edit-description" },
                        }),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.description,
                              expression: "form.description",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "edit-description",
                            placeholder: "Description",
                          },
                          domProps: { value: _vm.form.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-4" }, [
                  _c(
                    "div",
                    { staticClass: "product-contact product-contact--light" },
                    [
                      _vm.offer.premium
                        ? [
                            _c(
                              "h3",
                              { staticClass: "product-contact__title" },
                              [
                                _vm._v(
                                  "\n                                Premium until "
                                ),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        _vm.offer.premiumUntil,
                                        "D MMM. YYYY"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "premium-image",
                              attrs: {
                                src: "/build/images/premium.png",
                                alt: "",
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.offer.premium
                        ? [
                            _vm._m(3),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "premium-image",
                              attrs: {
                                src: "/build/images/not-premium.png",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("UpgradeAdToPremiumButton", {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "center",
                              },
                              attrs: {
                                uuid: _vm.offerUuid,
                                "is-auction": _vm.isTypeAuction,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ onClick, label }) {
                                      return _c(
                                        "button",
                                        {
                                          staticClass: "btn btn--premium",
                                          attrs: {
                                            type: "button",
                                            disabled:
                                              !_vm.canBeUpgradedToPremium,
                                          },
                                          on: { click: onClick },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(label) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                1584578727
                              ),
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "edit-page__recap" }, [
                    _c("label", [_vm._v("Date")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(
                            _vm.offer.status.createdAt,
                            "D MMM. YYYY"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "edit-page__recap" }, [
                    _c("label", [_vm._v("Status")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.offerAvailabilityLabel))]),
                  ]),
                  _vm._v(" "),
                  _vm.offer.requestsCount > 0
                    ? _c("p", { staticClass: "edit-page__recap" }, [
                        _c("label", [_vm._v("Requests")]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "bullet-number bullet-number--primary",
                          },
                          [_vm._v(_vm._s(_vm.offer.requestsCount))]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("p", [
                    _c(
                      "button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onDeleteOffer()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "icon icon--delete" }),
                        _vm._v(
                          "\n                            Delete this ad\n                            "
                        ),
                        _vm.states.isDeleting
                          ? _c("span", { staticClass: "fa fa-spin fa-spinner" })
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("h2", { staticClass: "form-bloc__title" }, [
                    _vm._v("Please select pictures for your classified ad"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "drop" }, [
                    _c("p", { staticClass: "drop__title" }, [
                      _vm._v(
                        "Photos are very important to promote your equipment"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "drop__zone" }, [
                      _c("div", { staticClass: "drop__target" }, [
                        _c("input", {
                          staticClass: "input-file",
                          attrs: {
                            type: "file",
                            multiple: "",
                            accept: _vm.acceptedMimeTypes,
                          },
                          on: { change: _vm.onNewMedia },
                        }),
                        _vm._v(" "),
                        _c("i", { staticClass: "icon icon--upload" }),
                        _vm._v(" "),
                        _vm._m(4),
                      ]),
                      _vm._v(" "),
                      _vm._m(5),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "photo-list" },
                    [
                      _c("p", { staticClass: "photo-list__title" }, [
                        _vm._v(
                          "You can post up to 10 photos. If you don't have photos yet, you can add them later."
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.media, function (medium) {
                        return _c(
                          "div",
                          {
                            staticClass: "photo-list__item",
                            class: {
                              "photo-list__item--video": _vm.isVideo(medium),
                              "photo-list__item--pdf": _vm.isPdf(medium),
                            },
                          },
                          [
                            _c("div", {
                              staticClass: "image",
                              class: {
                                "image--removed": _vm.isMediaRemoved(medium),
                              },
                              style:
                                "background: url(" +
                                _vm.getThumbnail(medium) +
                                ")",
                            }),
                            _vm._v(" "),
                            !_vm.isMediaRemoved(medium)
                              ? _c(
                                  "button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.removeMedia(medium)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "icon icon--delete",
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Delete"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isMediaRemoved(medium)
                              ? _c(
                                  "button",
                                  {
                                    attrs: { title: "Restore media" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.restoreMedia(medium)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fa fa-undo" }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Restore"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "shadow-container__footer" }, [
        _c("div", [
          _vm.sendError
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v("\n                An error occurred\n            "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.states.isSaved
            ? _c("div", { staticClass: "alert alert-success" }, [
                _vm._v(
                  "\n                Your Ad has successfully been updated\n            "
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn--primary",
            attrs: {
              type: "submit",
              disabled: !_vm.offer || _vm.states.isSaving,
            },
          },
          [
            _vm.states.isSaving
              ? _c("span", { staticClass: "fa fa-spin fa-spinner" }, [
                  _vm._v(" "),
                ])
              : _vm._e(),
            _vm._v("\n            Save modifications\n        "),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "shadow-container__header" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Edit your Classified Ad")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("DatePicker", {
      staticClass: "form-control",
      attrs: { required: "" },
      on: { "update-date": _vm.onStartsAtUpdated },
      model: {
        value: _vm.form.startsAt,
        callback: function ($$v) {
          _vm.$set(_vm.form, "startsAt", $$v)
        },
        expression: "form.startsAt",
      },
    })
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("DatePicker", {
      staticClass: "form-control",
      attrs: { startDate: new Date(), required: "" },
      on: { "update-date": _vm.onEndsAtUpdated },
      model: {
        value: _vm.form.endsAt,
        callback: function ($$v) {
          _vm.$set(_vm.form, "endsAt", $$v)
        },
        expression: "form.endsAt",
      },
    })
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "product-contact__title" }, [
      _vm._v("\n                                Become premium "),
      _c("br"),
      _vm._v(" for more visibility\n                            "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", [_vm._v("Drop files here or")]),
      _vm._v(" "),
      _c("button", { staticClass: "btn btn--primary" }, [
        _c("span", [_vm._v("Browse")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "drop__help" }, [
      _c("p", [_vm._v("File formats accepted : GIF, JPEG, JPG")]),
      _vm._v(" "),
      _c("p", [_vm._v("Automatic photo resizing")]),
      _vm._v(" "),
      _c("p", [_vm._v("Recommended size : above 800 pixels width")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }