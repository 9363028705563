<template>
    <component :is="tag">
        <slot v-if="!authenticated" name="unauthenticated" />
        <template v-else>
            <slot v-if="isInitial" :onClick="onClick" />
            <slot v-if="isProcessing" name="loading" />
            <slot v-if="hasSucceeded" name="success" />
            <slot v-if="hasFailed" name="error" />
        </template>
    </component>
</template>

<script>
  import { mapActions } from 'vuex';
  import stateMixin from '../../../../../Common/js/mixins/state';

  export default {
    mixins: [stateMixin],

    props: {
      tag: {
        type: String,
        required: false,
        default: () => 'span',
      },
      memberUuid: {
        type: String,
        required: false,
        default: () => null,
      },
      categoryUuid: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        state: this.STATE.INITIAL,
      };
    },

    computed: {
      authenticated() {
        return this.memberUuid !== null;
      }
    },

    methods: {
      ...mapActions([
        'subscribeToCategory',
      ]),

      onClick() {
        this.state = this.STATE.PROCESSING;
        this.subscribeToCategory({
          uuid: this.memberUuid,
          categoryUuid: this.categoryUuid,
        }).then(this.onSuccess, this.onFailure);
      },

      onSuccess() {
        this.state = this.STATE.SUCCESS;
      },

      onFailure() {
        this.state = this.STATE.FAILURE;
      },
    },
  }
</script>
