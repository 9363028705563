var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("SelectPremiumOptionForAuction", {
        attrs: { "suggest-free": "" },
        on: { input: _vm.onSelectedOption },
      }),
      _vm._v(" "),
      _vm._t("modalFeedback"),
      _vm._v(" "),
      _c("SelectPremiumFooter", { attrs: { back: _vm.back } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }