import Vue from 'vue';
import Vuex from 'vuex';
import common from './geo/index';
import member from '../../Member/store';
import offer from '../../Offer/store';
import plugins from '../../Common/store/plugins';

import {
  ADD_ENTITIES,
  CLEAR_ENTITIES,
} from './mutation-types';
import Schema from '../../../normalizr.schema';
import {get} from "dot-prop-immutable";
import {ADD_LOADED_QUERIES} from "../../../../../Common/js/modules/store/mutation-types";
import localStoragePlugin from '../../Common/store/plugins/localStoragePlugin';

Vue.use(Vuex);

// Main Vuex store, made up of different modules (cf. https://vuex.vuejs.org/en/modules.html)
const store = new Vuex.Store({
  plugins: [localStoragePlugin],
  state: {
    /**
     * List of important queries already executed & processed.
     * This allows to cleverly avoid processing some entities twice or more
     * while all has already been processed earlier.
     */
    loadedQueries: [],
  },

  modules: {
    common,
    offer,
    member,
    plugins,
  },

  strict: process.env.NODE_ENV !== 'production',

  getters: {
    /**
     * Help to denormalize a collection of associated data.
     * For instance, "associated(`offer.offers.${offerUuid}.channels`, 'messaging.channels')" will return
     * channels stored in the "messaging" module at path "messaging.channels",
     * associated to the offer identified by "offerUuid", stored in "offer" module at path "offer.offers".
     */
    associated(rootState) {
      return (holderPath, storagePath) => (get(rootState, holderPath) || [])
        .map((channelUuid) => get(rootState, `${storagePath}.${channelUuid}`) || null)
        .filter(x => x)
    },

    /**
     * @return {Function} ({String} query) => {Bool} Was the query already loaded before?
     */
    isQueryLoaded(rootState) {
      return (query) => rootState.loadedQueries.includes(query);
    },
  },

  mutations: {

    /**
     * This main mutation controls the global state with normalized data,
     * allowing to have a flat store for manipulated entities.
     *
     * @param rootState
     * @param normalizedData Normalizr normalized data
     */
    [ADD_ENTITIES](rootState, normalizedData) {
      Object.keys(normalizedData.entities).forEach((key) => {
        let items = normalizedData.entities[key];

        const schema = Object.values(Schema).find((schema) => {
          return schema._key === key;
        });

        if (undefined === schema) {
          throw new Error(`Unable to determine how to store items with unknown key "${key}".`);
        }

        if (typeof schema.postProcess === 'function') {
          Object.entries(items).forEach(([id, item]) => {
            items[id] = schema.postProcess(item);
          });
        }

        if (typeof schema.store !== 'function') {
          throw new Error(`Missing "store" function for schema with key "${key}".`);
        }

        schema.store(rootState, items);
      });
    },

    [CLEAR_ENTITIES](rootState, schema) {
      if (typeof schema.store !== 'function') {
        throw new Error(`Missing "clear" function for schema with key "${schema._key}".`);
      }

      schema.clear(rootState);
    },

    /**
     * Use this mutation to track important queries that should not be processed twice.
     *
     * @param {String} query The query identifier (constant)
     */
    [ADD_LOADED_QUERIES](rootState, query) {
      rootState.loadedQueries.push(query);
    },
  }
});

export default store;
