var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    { tag: "component" },
    [
      !_vm.authenticated
        ? _vm._t("unauthenticated")
        : [
            _vm.isInitial
              ? _vm._t("default", null, { onClick: _vm.onClick })
              : _vm._e(),
            _vm._v(" "),
            _vm.isProcessing ? _vm._t("loading") : _vm._e(),
            _vm._v(" "),
            _vm.hasSucceeded ? _vm._t("success") : _vm._e(),
            _vm._v(" "),
            _vm.hasFailed ? _vm._t("error") : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }