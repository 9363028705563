<!-- https://fr.vuejs.org/v2/examples/select2.html -->
<template>
    <select multiple="multiple" />
</template>

<script>
  import select2 from '../../mixins/select2';

  export default {
    mixins: [select2],
    props: {
      // select options
      options: {
        type: Array,
        required: false,
        default: () => [],
      },
      // v-model
      value: {
        type: Array,
        required: false,
        default: () => [],
      },
      valueType: {
        type: String,
        required: false,
        default: 'string',
        validator: function (v) {
          return ['string', 'int'].includes(v);
        },
      },
    },

    mounted: function () {
      const vm = this;

      this.getSelect2Input()
        // init select2
        .select2({
          data: this.options,
          tags: true,
          tokenSeparators: [',', ' '],
        })
        // with initial value and trigger change
        .val(this.value)
        .trigger('change')
        // emit event on change.
        .on('change', function (e, payload = {}) {
          let values = $(this).val();

          if (vm.valueType === 'int') {
            values = values.map(v => parseInt(v)).filter(v => v);
          }

          vm.__select2_emit(values)(e, payload);
        });
    },

    watch: {
      value(newValue, oldValue) {
        this.__select2_watch(newValue, oldValue);
      },
    },
  };
</script>
