<template>
    <div>
        <div class="form-bloc">
            <h2 class="form-bloc__title">Please select a type for your classified ad</h2>
            <div class="category-select">
                <div class="category-select__icon">
                    <i class="icon icon--machine"></i>
                </div>
                <ul class="category-select__list">
                    <li class="category-select__item">
                        <div class="radio">
                            <input type="radio" name="category" id="used" :value="CHOICES.USED_MACHINE" v-model="choice" />
                            <label for="used">{{ choiceLabel(CHOICES.USED_MACHINE) }}</label>
                        </div>
                    </li>
                    <li class="category-select__item">
                        <div class="radio">
                            <input type="radio" name="category" id="new" :value="CHOICES.NEW_MACHINE" v-model="choice" />
                            <label for="new">{{ choiceLabel(CHOICES.NEW_MACHINE) }}</label>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="category-select">
                <div class="category-select__icon">
                    <i class="icon icon--auction"></i>
                </div>
                <ul class="category-select__list">
                    <li class="category-select__item">
                        <div class="radio">
                            <input type="radio" name="category" id="auctions" :value="CHOICES.AUCTION" v-model="choice" />
                            <label for="auctions">{{ choiceLabel(CHOICES.AUCTION) }}</label>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="category-select mocked-element">
                <div class="category-select__icon">
                    <i class="icon icon--job"></i>
                </div>
                <ul class="category-select__list">
                    <li class="category-select__item">
                        <div class="radio">
                            <input type="radio" name="category" id="job" :value="CHOICES.JOB" v-model="choice" disabled />
                            <label for="job">{{ choiceLabel(CHOICES.JOB) }}</label>
                        </div>
                    </li>
                    <li class="category-select__item">
                        <div class="radio">
                            <input type="radio" name="category" id="resume" :value="CHOICES.RESUME" v-model="choice" disabled />
                            <label for="resume">{{ choiceLabel(CHOICES.RESUME) }}</label>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="shadow-container__footer">
            <hr>
            <div class="inner-footer">
                <button class="button-next btn btn--primary" :disabled="!choice" @click.prevent="onSubmit()">
                    Next
                    <i class="icon icon--next"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import {OfferType as OFFER_TYPES} from '../../../../../../../Common/js/helpers/enums';
  import { default as CHOICES, getChoiceLabel, getChoiceForOffer } from './TypeChoices';

  export default {
    props: {
      offer: {
        type: Object,
        required: true,
      },
    },
    constants: {
      CHOICES: CHOICES,
    },
    data: function() {
      return {
        choice: null,
      };
    },
    created() {
      this.choice = getChoiceForOffer(this.offer);
    },
    methods: {
      onSubmit() {
        window.scrollTo(0,0);
        this.$emit('updated', this.dataFromChoice);
      },
      choiceLabel(choice) {
        return getChoiceLabel(choice);
      },
    },
    watch: {
      offer: function(val) {
        this.choice = getChoiceForOffer(val);
      },
    },
    computed: {
      dataFromChoice() {
        switch(this.choice) {
          case this.CHOICES.NEW_MACHINE:
            return {
              type: OFFER_TYPES.MACHINE,
              used: false,
            };
          case this.CHOICES.USED_MACHINE:
            return {
              type: OFFER_TYPES.MACHINE,
              used: true,
            };
          case this.CHOICES.AUCTION:
            return {
              type: OFFER_TYPES.AUCTION,
              used: false,
            };
          case this.CHOICES.JOB:
            return {
              type: OFFER_TYPES.JOB,
              used: false,
            };
          case this.CHOICES.RESUME:
            return {
              type: OFFER_TYPES.RESUME,
              used: false,
            };
          default:
            return {
              type: OFFER_TYPES.MACHINE,
              used: true,
            };
        }
      },
    }
  };
</script>
