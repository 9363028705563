/**
 * On click on elements with [data-toggle="url"], go to [data-target] url
 */
$(() => {
  $('body').on('click', '[data-toggle="url"]', function (e) {
    const targetUrl = $(this).data('target');
    const eventTarget = $(e.target);

    if (eventTarget !== $(this) && eventTarget.is('a, a > *, input, button, button > *, .check, .check > *')) {
      // Ignore children clicks on interactive elements handled natively by the browser or other listeners (links, inputs & buttons)
      return;
    }

    if (!targetUrl) {
      throw new Error('Missing "data-target" attribute');

      return;
    }

    window.location = targetUrl;
    e.preventDefault();
    e.stopPropagation();
  });
});
