const stateMixin = {
  constants: {
    STATE: {
      INITIAL: 'INITIAL',
      SAVING: 'SAVING',
      EDITING: 'EDITING',
      PROCESSING: 'PROCESSING',
      SUCCESS: 'SUCCESS',
      FAILURE: 'FAILURE',
    },
  },
  data() {
    return {
      state: this.STATE.INITIAL,
    };
  },
  methods: {
    resetInitialState() {
      this.state = this.STATE.INITIAL;
    },
    setState(state) {
      this.state = state;
    },
  },
  computed: {
    isInitial() {
      return this.state === this.STATE.INITIAL;
    },
    isProcessing() {
      return this.state === this.STATE.PROCESSING;
    },
    hasFailed() {
      return this.state === this.STATE.FAILURE;
    },
    hasSucceeded() {
      return this.state === this.STATE.SUCCESS;
    },
  },
};

export default stateMixin;
