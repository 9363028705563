<template>
    <div>
        <div v-if="isPremiumUser" class="form-bloc">
            <h1 class="form-bloc__title">Using your premium pack</h1>
            <p>We're creating your new classified ad using your active Premium Pack...</p>
        </div>

        <SelectPremiumOption @input="onSelectedOption" v-if="!isPremiumUser" suggest-free suggest-single-premium />

        <slot name="modalFeedback"></slot>

        <SelectPremiumFooter :back="back" />
    </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import SelectPremiumOption from '../../Premium/SelectPremiumOption';
  import SelectPremiumFooter from '../../Premium/SelectPremiumFooter';

  const EVENT_PAYMENT_METHOD_SELECTED = 'paymentMethodSelected';

  export default {
    components: { SelectPremiumOption, SelectPremiumFooter },
    inject: ['Security'],
    props: {
      // Whether the step is currently the active one or not:
      active: {
        type: Boolean,
        required: true,
      }
    },

    data: function() {
      return {
        selectedPack: null,
      };
    },

    created() {
      setTimeout(this.loadPremiumPacks, 0);
    },

    methods: {
      ...mapActions([
        'loadPremiumPacks',
      ]),

      onSelectedOption(selectedOption) {
        this.$emit(EVENT_PAYMENT_METHOD_SELECTED, selectedOption);
      },

      numberOfAds(pack) {
        return pack.numberOfAds || 'Unlimited';
      },

      pricePerAdAndMonth(pack) {
        if (!pack.numberOfAds) {
          return null;
        }

        return (Math.round(((pack.price / 12) / pack.numberOfAds) * 10) / 10).toFixed(2);
      },

      back() {
        this.$emit('goToPrevious');
      },
    },

    computed: {
      ...mapState({
          premiumPacks: state => state.offer.premiumPacks,
      }),

      isPremiumUser() {
        // TODO: Ideally this should be called live, so we ensure it isn't stale & multi-tabs compliant
        return this.Security.getUser().premium;
      },
    },

    watch: {
      active(active) {
        // When current step becomes active, check if payment is actually required:
        if (active) {
          if (this.isPremiumUser) {
            // User is premium (has an active pack), no payment method required:
            this.$emit(EVENT_PAYMENT_METHOD_SELECTED, null)
          }
        }
      }
    }
  };
</script>
