var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isAuction
        ? _c("SelectPremiumOption", {
            attrs: { suggestSinglePremium: "" },
            on: { input: _vm.onSelectedPremiumOption },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isAuction
        ? _c("SelectPremiumOptionForAuction", {
            on: { input: _vm.onSelectedPremiumOption },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("SelectPremiumFooter", { attrs: { back: _vm.back } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }