<template>
    <div>
        <div class="form-bloc form-bloc--striped">
            <h2 class="form-bloc__title">Please fill in the description of your classified ad</h2>
            <div class="form-bloc__row">
                <div>
                    <label>Category</label>
                    <ul class="breadcrumb" v-if="offer.categoryUuid">
                        <li class="breadcrumb__item">{{ industryName }}</li>
                        <li class="breadcrumb__item">{{ parentCategoryName }}</li>
                        <li class="breadcrumb__item">{{ categoryName }}</li>
                    </ul>
                </div>
            </div>
            <div class="form-bloc__row">
                <div>
                    <label>Manufacturer</label>
                    <SelectManufacturer
                        class="form-control"
                        v-model="form.manufacturerUuids"
                        :category-uuid="offer.categoryUuid"
                    />
                    <p class="help">If the manufacturer does not appear in the list, type OTHER. <br> Also, you can <a :href="contactPageUri" target="_blank">contact us</a> to add this manufacturer.</p>
                </div>
            </div>
            <div class="form-bloc__row">
                <!-- Dynamic fields for free fields -->
                <template v-for="freeField in categoryFreeFields">
                    <div>
                        <label>{{ freeField.name }}</label>
                        <input
                            :id="'offer-free-field-' + freeField.uuid"
                            class="form-control"
                            type="text"
                            v-model.lazy.trim="form.freeFields[freeField.uuid]"
                        />
                    </div>
                </template>
                <!-- End of free fields -->
                <div>
                    <label for="offer-information-quantity">Quantity</label>
                    <input type="number" class="form-control" v-model.number="form.quantity" id="offer-information-quantity" :class="{
                        'input-error': fieldError('quantity'),
                    }">
                    <div class="error" v-if="fieldError('quantity')">Quantity is required</div>
                </div>
                <div>
                    <label>Years</label>
                    <Tags
                        v-model="form.years"
                        :options="form.years"
                        value-type="int"
                        class="form-control"
                    />
                </div>
                <div>
                    <label for="offer-information-price">Price</label>
                    <input type="text" class="form-control" :maxlength="maxLengths.SELLER_PRICE" v-model="form.price" id="offer-information-price">
                </div>
                <div>
                    <label>Country</label>
                    <SelectCountry
                        classes="form-control"
                        v-model="form.countryUuid"
                    />
                </div>
                <div>
                    <label for="offer-information-reference">Your reference</label>
                    <input type="text" class="form-control" :maxlength="maxLengths.SELLER_REFERENCE" v-model="form.reference" id="offer-information-reference">
                </div>
            </div>
            <div class="form-bloc__row form-bloc__row--vertical">
                <label for="offer-information-description">Description</label>
                <textarea class="form-control" id="offer-information-description" v-model="form.description"></textarea>
            </div>
        </div>
        <div class="shadow-container__footer">
            <hr>
            <div class="inner-footer">
                <button class="button-back btn btn--empty" @click.prevent="$emit('goToPrevious')">
                    <i class="icon icon--previous"></i>
                    Back
                </button>
                <button class="button-next btn btn--primary" @click.prevent="onSubmit()">
                    Next
                    <i class="icon icon--next"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import SelectCountry from '../../../../../../../Common/js/components/form/SelectCountry';
  import { MaxLengths } from '../../../../../../../Common/js/model/Offer/Schema';
  import SelectManufacturer from '../../../../../../../BackOffice/js/modules/Offer/components/form/SelectManufacturer';
  import Tags from '../../../../../../../Common/js/components/form/Tags';
  import routes from '../../../../Common/routes';

  import { mapGetters } from 'vuex';

  export default {
    inject: ['Router'],

    components: {
      SelectCountry,
      SelectManufacturer,
      Tags,
    },
    props: {
      offer: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        maxLengths: MaxLengths,
        form: {
          quantity: 1,
          countryUuid: null,
          manufacturerUuids: [],
          years: [],
          price: null,
          reference: null,
          description: null,
          freeFields: {}, // Object containing free field values indexed by free field Uuid
        },
        errors: {},
      };
    },
    created() {
      this.initWithOffer();
    },
    watch: {
      offer: function (val) {
        this.form.quantity = val.quantity;
        this.form.countryUuid = val.countryUuid;
        this.form.manufacturerUuids = val.manufacturerUuids;
        this.form.years = val.years;
        this.form.price = val.price;
        this.form.reference = val.reference;
        this.form.description = val.description;
        this.form.freeFields = val.freeFields;
      },
    },
    methods: {
      onSubmit() {
        this.validateForm();
        if (Object.keys(this.errors).length > 0) {
          return;
        }
        window.scrollTo(0,0);
        this.$emit('updated', this.form);
      },
      initWithOffer() {
        this.form = {
          quantity: this.offer.quantity,
          countryUuid: this.offer.countryUuid,
          manufacturerUuids: this.offer.manufacturerUuids,
          years: this.offer.years,
          price: this.offer.price,
          reference: this.offer.reference,
          description: this.offer.description,
          freeFields: {
            ...this.defaultFreeFieldValues,
            ...this.offer.freeFields,
          },
        };
      },
      validateForm() {
        const errors = {};
        if (!this.form.quantity) {
          errors.quantity = true;
        }
        this.errors = errors;
      },
      fieldError(field) {
        return this.errors[field] || false;
      },
    },
    computed: {
      ...mapGetters([
        'getCategory',
      ]),
      category() {
        return this.offer.categoryUuid && this.getCategory(this.offer.categoryUuid);
      },
      categoryName() {
        return this.category && this.category.name;
      },
      parentCategory() {
        return this.category && this.getCategory(this.category.parent);
      },
      parentCategoryName() {
        return this.parentCategory && this.parentCategory.name;
      },
      industry() {
        return this.parentCategory && this.getCategory(this.parentCategory.parent);
      },
      industryName() {
        return this.industry && this.industry.name;
      },
      categoryFreeFields() {
        return (this.category && this.category.freeFields) || [];
      },
      defaultFreeFieldValues() {
        const freeFieldValues = {};
        this.categoryFreeFields.forEach((freeField) => {
            freeFieldValues[freeField.uuid] = null;
        });

        return freeFieldValues;
      },
      contactPageUri() {
        return this.Router.generate(routes.CONTACT);
      },
    },
  };
</script>

<style lang="scss" scoped>
    .input-error {
        border: 1px solid #ff0000;
    }
    .error {
        color: #ff0000;
        padding: 5px;
    }
</style>
