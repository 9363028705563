var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-bloc" }, [
      _c("h1", { staticClass: "form-bloc__title" }, [
        _vm._v("Please choose your option"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pricing" }, [
        _vm.suggestFree
          ? _c("div", { staticClass: "pricing__item" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "pricing__footer" }, [
                _c("span", { staticClass: "price" }, [
                  _vm._v("Free ad for 24H"),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onSubmitFree.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Choose this option")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "pricing__item" }, [
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _vm._m(5),
          _vm._v(" "),
          _vm._m(6),
          _vm._v(" "),
          _vm._m(7),
          _vm._v(" "),
          _c("div", { staticClass: "pricing__footer" }, [
            _c("span", { staticClass: "price" }, [
              _vm._v(
                "\n                        185 euros for 1 month\n                    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn--premium",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onSubmitSingle.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Choose this option")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__header" }, [
      _c("span", { staticClass: "title" }, [_vm._v("Basic Classified Ad")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("5 photos")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__header" }, [
      _c("span", { staticClass: "title" }, [_vm._v("Premium Classified Ad")]),
      _vm._v(" "),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v("Premium status ensures maximum exposure for your Auction"),
      ]),
      _vm._v(" "),
      _c("img", { attrs: { src: "/build/images/premium.png", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [
        _vm._v("Viewable by all visitors through the end of the auction"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Your auction is at the top of the search listings")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [
        _vm._v("Direct link to your lot listings/catalog on your website"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "Inclusion of your auction through its end date in our Weekly Auction Newsletter"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pricing__advantage" }, [
      _c("i", { staticClass: "icon icon--check" }),
      _vm._v(" "),
      _c("span", [_vm._v("Unlimited photos, pdf files and videos")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }