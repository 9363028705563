import { get } from 'dot-prop-immutable';

export default {

  getOffer(state) {
    return (uuid) => get(state, `offers.${uuid}`) || null;
  },

  getCategory(state) {
    return (categoryUuid) => get(state, `categories.${categoryUuid}`) || null;
  },

  getCategories(state, getters) {
    return (categoriesUuids) => categoriesUuids.map(getters.getCategory).filter(x => x);
  },

  /**
   * Get the category hierarchy (i.e from cat lvl 3, get lvl 2 & lvl 1 cats too).
   * Returns the result from highest category to lowest (lvl1, lvl2 & lvl3).
   */
  getCategoryHierarchy(state, getters) {
    return (categoryUuid) => {
      const categories = [];
      let currentUuid = categoryUuid;

      do {
        const category = getters.getCategory(currentUuid);
        if (!category) {
          console.error(`Cannot find category ${currentUuid}`);
          return categories;
        }
        categories.unshift(category);
        currentUuid = category.parent;
      } while (currentUuid);

      return categories;
    };
  },

  getCategoryChildren(state, getters) {
    return (categoryUuid) => {
      const category = getters.getCategory(categoryUuid);

      return category === null ? [] : getters.getCategories(category.children || []);
    }
  },

  getCategoryParentUuid(state) {
    return (categoryUuid) => get(state, `categories.${categoryUuid}.parent`) || null;
  },

  getOfferMedia(state, getters, rootState, rootGetters) {
    return (offerUuid) => rootGetters.associated(`offer.offers.${offerUuid}.media`, 'offer.media');
  },


  industries(state) {
    const industries = Object.values(state.categories).filter(c => !c.parent);

    // Sort industries by their rank
    industries.sort((a, b) => {
      switch (true) {
        case 0 === a.rank:
          return 1;
        case 0 === b.rank:
          return -1;
        default:
          return a.rank - b.rank;
      }
    });

    return industries;
  },

  getManufacturer(state) {
    return (manufacturerUuid) => get(state, `manufacturers.${manufacturerUuid}`) || null;
  },

  getAllParents(state) {
    return Object.values(state.categories).filter(c => c.children);
  },
};
