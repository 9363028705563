<template functional>
    <div class="form-group" :class="{'has-error': props.errors.length > 0 }">
        <label :for="props.inputName">
            {{ props.label }}
        </label>
        <slot name="input" _class="form-control" />

        <!-- Errors -->
        <template v-if="props.errors.length > 0">
            <span class="help-block">
                <ul class="list-unstyled">
                    <li v-for="message in props.errors">
                        <i class="fa fa-exclamation-triangle"/>&nbsp;{{ message }}
                    </li>
                </ul>
            </span>
        </template>
    </div>
</template>

<script>
  export default {
    functional: true,

    props: {
      label: {
        type: String,
        required: true,
      },

      errors: {
        type: Array,
        required: false,
        default: () => [],
      },

      inputName: {
        type: String,
        required: true,
      },
    },
  };
</script>
