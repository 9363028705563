import * as types from '../../../../../../Common/js/modules/store/mutation-types';

/**
 * Dedicated store for specific Vue/Vuex plugins
 */

const state = {
  // See Vuex local storage plugin (assets/BackOffice/js/modules/Common/store/plugins/localStoragePlugin.js)
  localStorageReady: false,
};

export default {
  state,
  getters: {},
  actions: {},
  mutations: {
    [types.LOCAL_STORAGE_READY](state) {
      state.localStorageReady = true;
    },
  },
};
