<template>
    <div>
        <div class="shadow-container__content">
            <div class="fa fa-spin fa-spinner" v-if="loading" />
            <div class="form-bloc" v-show="!loading">
                <h2 class="form-bloc__title">Categories</h2>
                <button
                    type="submit"
                    class="btn btn--primary flex-right"
                    @click="onSubmit"
                    :disabled="saving"
                ><span class="fa fa-spin fa-spinner" v-if="saving" />Save modifications</button>
                <div class="alert alert-danger" v-show="sendError">
                    An error occurred
                </div>
                <div class="alert alert-success" v-show="sendSuccess">
                    Your subscriptions have been registered
                </div>
                <div id="accordion" class="accordion" role="tablist">
                    <div class="panel">
                        <div class="panel-heading">
                            Total of alerts
                            <div class="bullet-number" v-bind:class="{'bullet-number--primary': subscriptionsCount.all > 0 }">{{ subscriptionsCount.all }}</div>
                        </div>
                    </div>
                    <div class="panel" v-for="industry in industries" :key="industry.uuid">
                        <div class="panel-heading" role="tab">
                            <div v-bind:class="[isMinus(industry.uuid) ? 'check check--minus' : 'check']">
                                <input
                                    type="checkbox"
                                    v-bind:id="industry.uuid"
                                    v-bind:value="industry.uuid"
                                    v-model="allCheckedCategoriesUuids"
                                >
                                <label v-bind:for="industry.uuid"></label>
                            </div>
                            <!-- do not display toggle link if there are no sub-categories -->
                            <a v-if="getCategoryChildren(industry.uuid).length > 0" role="button" data-toggle="collapse" data-parent="#accordion" v-bind:href="'#' + industry.uuid + '-collapse'" aria-expanded="false">
                                <span>{{ industry.name }}</span>
                                <div class="bullet-number" v-bind:class="{'bullet-number--primary': subscriptionsCount[industry.uuid] > 0 }">{{ subscriptionsCount[industry.uuid] }}</div>
                            </a>
                            <a v-else class="no-collapse-display">
                                <span>{{ industry.name }}</span>
                                <div class="bullet-number" v-bind:class="{'bullet-number--primary': subscriptionsCount[industry.uuid] > 0 }">{{ subscriptionsCount[industry.uuid] }}</div>
                            </a>
                        </div>
                        <div v-bind:id="industry.uuid + '-collapse'" class="panel-collapse collapse" role="tabpanel">
                            <div class="panel-body">
                                <div class="panel-row" v-for="child in getCategoryChildren(industry.uuid)" :key="child.uuid">
                                    <div v-bind:class="[isMinus(child.uuid) ? 'check check--minus' : 'check']">
                                        <input
                                            type="checkbox"
                                            v-bind:id="child.uuid"
                                            v-bind:value="child.uuid"
                                            v-model="allCheckedCategoriesUuids"
                                        >
                                        <label v-bind:for="child.uuid"></label>
                                    </div>
                                    <!-- do not display toggle link if there are no leaf categories -->
                                    <a v-if="getCategoryChildren(child.uuid).length > 0" class="category-level" role="button" data-toggle="collapse" v-bind:href="'#' + child.uuid + '-collapse'" aria-expanded="false">{{ child.name }}</a>
                                    <a v-else class="category-level" >{{ child.name }}</a>
                                    <div class="category-level last collapse" v-bind:id="child.uuid + '-collapse'">
                                        <div>
                                            <div class="check" v-for="grandChild in getCategoryChildren(child.uuid)" :key="grandChild.uuid">
                                                <input
                                                    type="checkbox"
                                                    v-bind:id="grandChild.uuid"
                                                    v-bind:value="grandChild.uuid"
                                                    v-model="allCheckedCategoriesUuids"
                                                >
                                                <label v-bind:for="grandChild.uuid">{{ grandChild.name }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shadow-container__footer">
            <div class="alert alert-danger" v-show="sendError">
                An error occurred
            </div>
            <div class="alert alert-success" v-show="sendSuccess">
                Your subscriptions have been registered
            </div>
            <hr>
            <button
                type="submit"
                class="btn btn--primary"
                @click="onSubmit"
                :disabled="saving"
            ><span class="fa fa-spin fa-spinner" v-if="saving" />Save modifications</button>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    inject: ['App'],
    props: {
      initialMember: {
        type: Object,
        required: true,
      }
    },

    data() {
      return {
        loading: false,
        saving: false,
        // All categories that are checked, including parent categories
        // Used for display only
        allCheckedCategoriesUuids: [],
      form: {
          // Only leaf categories uuids (no industry or sub-category)
          leafCategoriesUuids: [],
        },
        subscriptionsCount: {},
        sendError: false,
        sendSuccess: false,
        parentCategoriesUuids : [],
      }
    },
    watch: {
      selectedUuids: function(val, oldVal) {
        if (!this.loading) {
          let checked = oldVal.length < val.length;
          const uuid = checked ? val.filter(uuid => oldVal.indexOf(uuid) === -1)[0] : oldVal.filter(uuid => val.indexOf(uuid) === -1)[0];

        /*
        * Goal : Behaviour like Gmail when checking / unchecking a parent category
        * - If children categories are checked (parent appears with a "-"), clicking the parent checbox should uncheck all children categories and parent
        * - If no children categories are checked, checking the parent should check all children categories
        */

          if (this.parentCategoriesUuids.indexOf(uuid) > -1)
          {
            const children = this.getCategoryChildren(uuid);
            const childrenAreMinus = children.filter(child => this.minusUuids.indexOf(uuid) > -1);
            if (childrenAreMinus.length > 0) {
              checked = false;
              const indexOfParent = this.allCheckedCategoriesUuids.indexOf(uuid);
              if(indexOfParent > -1) {
                this.allCheckedCategoriesUuids.splice(indexOfParent, 1);
              }
            }
          }

          if (typeof(uuid) !== "undefined") {
            this.toggleAllChildren(uuid, checked);
            this.toggleParent(this.getCategoryParentUuid(uuid));
            this.countSubscriptions();
          }
        }
      },
    },

    created() {
      this.loading = true;
      this.App.registerOnReadyListener(() => {
        this.loadCategories().then(() => {
          this.initForm();
          this.loading = false;
        })
      });
      this.parentCategoriesUuids = this.getAllParents.map(parent => parent.uuid);
    },

    computed: {
      ...mapState({
        categories: state => state.offer.categories,
      }),

      ...mapGetters([
        'industries',
        'getCategoryChildren',
        'getCategoryParentUuid',
        'getAllParents',
      ]),

      selectedUuids() {
        return this.allCheckedCategoriesUuids;
      },

      minusUuids() {

        const parentsWithCheckedChildren = this.parentCategoriesUuids.filter(parentUuid => {
          const allChildren = this.getCategoryChildren(parentUuid);
          const selectedCat = this.filterSelectedCategories(allChildren);
          return (selectedCat.length > 0 && allChildren.length > selectedCat.length);
        });

        const parentsWithChildrenMinus = [];

        parentsWithCheckedChildren.forEach(parentUuid => {
          let parentCategory = this.getCategoryParentUuid(parentUuid);
          if(parentCategory !== null) {
            if(parentsWithChildrenMinus.indexOf(parentCategory) === -1 && parentsWithCheckedChildren.indexOf(parentCategory) === -1){
              parentsWithChildrenMinus.push(parentCategory);
            }
          }
        });

        return parentsWithCheckedChildren.concat(parentsWithChildrenMinus);
      }
    },

    methods: {
      ...mapActions([
        'loadCategories',
        'loadMemberCategorySubscriptions',
        'setCategorySubscriptions',
      ]),

      isMinus(uuid) {
        return (this.minusUuids.indexOf(uuid) > -1);
      },

      toggleAllChildren(uuid, checked) {
        const childrenCategories = this.getCategoryChildren(uuid);

        // Remove leaf category from actual subscriptions
        const indexInPayload = this.form.leafCategoriesUuids.indexOf(uuid);
        if (!checked && childrenCategories.length === 0 && indexInPayload >= 0) {
          this.form.leafCategoriesUuids.splice(indexInPayload, 1);

        // Add leaf category to actual subscriptions
        } else if (checked && childrenCategories.length === 0 && indexInPayload === -1) {
          this.form.leafCategoriesUuids.push(uuid);
        }

        // Get all children categories and add all their uuids in selected subcriptions
        // And do the same for grandchildren categories
        // DO NOT add child uuid in payload, it will already have been done if it's a leaf category
        childrenCategories.forEach(function (child) {
          const indexInForm = this.allCheckedCategoriesUuids.indexOf(child.uuid);
          if (!checked && indexInForm >= 0) {
            this.allCheckedCategoriesUuids.splice(indexInForm, 1);
          } else if (checked && indexInForm === -1) {
            this.allCheckedCategoriesUuids.push(child.uuid);
          }
          this.toggleAllChildren(child.uuid, checked);
        }, this);
      },

      saveSubscriptions() {
        this.saving = true;
        const payload = Object.assign({}, this.form);
        this.errors = {};
        this.setCategorySubscriptions({
          uuid: this.initialMember.uuid,
          payload
        }).then(this.onSuccess, this.onFailure);
      },

      onSuccess() {
        // Success callback
        this.sendError = false;
        this.sendSuccess = true;
        this.saving = false;
      },

      onFailure(error) {
        this.sendError = true;
        this.sendSuccess = false;
        this.saving = false;

        // TODO: global error helper:
        (error.graphQLErrors || []).forEach((graphQLError) => {
          ((graphQLError.api_problem && graphQLError.api_problem.violations) || []).forEach(error => {
            if (!this.errors[error.path]) {
              this.errors[error.path] = [];
            }

            this.errors[error.path].push(error.reason);
          })
        });
      },

      onSubmit() {
        this.saveSubscriptions();
      },

      filterSelectedCategories(categories){
        return categories.filter(category => this.allCheckedCategoriesUuids.indexOf(category.uuid) > -1);
      },

      toggleParent(parentUuid) {
        const allChildren = this.getCategoryChildren(parentUuid);
        const diff = this.filterSelectedCategories(allChildren);

        // If all children are found in allCheckedCategoriesUuids, it means all children categories were selected
        // Therefore, we add parent uuid to allCheckedCategoriesUuids so it is checked too
        if (allChildren.length > 0 && allChildren.length === diff.length) {
          this.allCheckedCategoriesUuids.push(parentUuid);
          this.toggleParent(this.getCategoryParentUuid(parentUuid));

          // If not, it means that at least a child category is not checked
          // Therefore, we must uncheck parent if it was already checked
        } else if ((allChildren.length === 0 || allChildren.length > diff.length) && this.allCheckedCategoriesUuids.indexOf(parentUuid) > -1) {
          this.allCheckedCategoriesUuids.splice(this.allCheckedCategoriesUuids.indexOf(parentUuid),1);
          this.toggleParent(this.getCategoryParentUuid(parentUuid));
        }
      },

      initForm() {
        this.loading = true;
        this.allCheckedCategoriesUuids = this.initialMember.subscribedCategories.map(function(category) {
          return category.uuid
        });
        this.form.leafCategoriesUuids = this.initialMember.subscribedCategories.map(function(category) {
          return category.uuid
        });

        // If a category has all its children checked
        // Check it and parent if necessary
        this.initialMember.subscribedCategories.forEach(function (category){
          if (category.parent && this.allCheckedCategoriesUuids.indexOf(category.parent.uuid) === -1) {
            this.toggleParent(category.parent.uuid);
          }
        }, this);

        this.countSubscriptions();
      },

      countSubscriptions() {
        this.subscriptionsCount.all = this.form.leafCategoriesUuids.length;

        this.industries.forEach(function(industry){
          this.subscriptionsCount[industry.uuid] = 0;

          this.getCategoryChildren(industry.uuid).forEach(function(child){
            this.subscriptionsCount[child.parent] += this.filterSelectedCategories(this.getCategoryChildren(child.uuid)).length;
          }, this);
        }, this);
      },
    },
  }

</script>
