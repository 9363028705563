import actions from './actions';
import getters from './getters';

const state = {
  categories: {},
  manufacturers: {},
  premiumPacks: {},
  offers: {},
  media: {},
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
