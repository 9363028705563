var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isPremiumUser
        ? _c("div", { staticClass: "form-bloc" }, [
            _c("h1", { staticClass: "form-bloc__title" }, [
              _vm._v("Using your premium pack"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "We're creating your new classified ad using your active Premium Pack..."
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isPremiumUser
        ? _c("SelectPremiumOption", {
            attrs: { "suggest-free": "", "suggest-single-premium": "" },
            on: { input: _vm.onSelectedOption },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("modalFeedback"),
      _vm._v(" "),
      _c("SelectPremiumFooter", { attrs: { back: _vm.back } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }