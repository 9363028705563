var render = function render(_c, _vm) {
  return _c("div", { staticClass: "shadow-container__footer" }, [
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "inner-footer" }, [
      _c(
        "button",
        {
          staticClass: "button-back btn btn--empty",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.props.back()
            },
          },
        },
        [
          _c("i", { staticClass: "icon icon--previous" }),
          _vm._v("\n            Back\n        "),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function (_c, _vm) {
    return _c("div", { staticClass: "payment" }, [
      _c("span", [_vm._v("SSL Secure Payment")]),
      _vm._v(" "),
      _c("img", { attrs: { src: "/build/images/paypal.svg", alt: "Paypal" } }),
      _vm._v(" "),
      _c("img", { attrs: { src: "/build/images/visa.svg", alt: "Visa" } }),
      _vm._v(" "),
      _c("img", {
        staticClass: "mastercard",
        attrs: { src: "/build/images/mastercard.svg", alt: "Mastercard" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }