<template>
    <form @submit.prevent="onSubmit">
        <div class="shadow-container__header">
            <h1 class="title">Edit your Classified Ad</h1>
        </div>

        <div class="shadow-container__content">
            <div>
                <div class="alert alert-danger" v-if="sendError">
                    An error occurred
                </div>
                <div class="alert alert-success" v-if="states.isSaved">
                    Your Ad has successfully been updated
                </div>
            </div>
            <div class="panel-loading" style="height: 100px" v-if="!offer">
                <span class="fa fa-spin fa-spinner"></span>
            </div>
            <div v-if="states.isSaved" class="alert alert-success">
                Your ad was successfully updated!
            </div>
            <div v-if="sendError" class="alert alert-success">
                An error occurred while updating your ad!
            </div>
            <div v-if="offer">
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-bloc">
                            <div class="form-bloc__row edit-page__breadcrumb">
                                <label>Category</label>
                                <ul class="breadcrumb">
                                    <li v-for="category in categoryHierarchy">{{ category.name }}</li>
                                </ul>
                            </div>
                        </div>
                        <hr>
                        <div class="form-bloc">
                            <div class="form-bloc__row">
                                <label for="edit-offer-title" class="sr-only">Offer title</label>
                                <input type="text" id="edit-offer-title" class="form-control form-control--large" :maxlength="maxLengths.TITLE" required v-model="form.title" :disabled="isTypeMachine" />
                                <span>
                                    <button type="button" @click="copyTitleToClipboard">
                                        <i class="fa fa-clipboard"></i>
                                    </button>
                                </span>
                            </div>
                            <div class="form-bloc__error" v-if="fieldError('title')">
                                <ul>
                                    <li v-for="message in fieldError('title')">
                                        <i class="fa fa-exclamation-triangle"/>&nbsp;{{ message }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr>
                        <div class="form-bloc" v-if="isTypeMachine">
                            <div class="form-bloc__row">
                                <label for="edit-manufacturer">Manufacturer</label>
                                <SelectManufacturer
                                    id="edit-manufacturer"
                                    v-if="states.offerIsLoaded"
                                    class="form-control"
                                    v-model="form.manufacturerUuids"
                                    :category-uuid="offer.category"
                                />
                            </div>
                        </div>
                        <hr v-if="isTypeMachine">
                        <div class="form-bloc">
                            <!-- Dynamic fields for free fields -->
                            <div v-for="freeField in offer.freeFields" class="form-bloc__row">
                                <label>{{ freeField.name }}</label>
                                <input
                                    :id="'offer-free-field-' + freeField.uuid"
                                    class="form-control"
                                    type="text"
                                    :maxlength="maxLengths.FREE_FIELD_VALUE"
                                    v-model.lazy.trim="form.freeFields[freeField.uuid] && form.freeFields[freeField.uuid].value"
                                />
                            </div>
                            <!-- End of free fields -->

                            <div class="form-bloc__row" v-if="isTypeMachine">
                                <label for="edit-quantity">Quantity</label>
                                <input type="number" min="0" id="edit-quantity" class="form-control" v-model.number="form.quantity" :class="{
                                    'input-error': fieldError('quantity'),
                                }" required>
                                <div class="error" v-if="fieldError('quantity')">
                                    <ul>
                                        <li v-for="message in fieldError('quantity')">
                                            <i class="fa fa-exclamation-triangle"/>&nbsp;{{ message }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="form-bloc__row" v-if="isTypeMachine">
                                <label for="edit-year">Year(s)</label>
                                <Tags
                                    id="edit-year"
                                    v-model="form.years"
                                    :options="form.years"
                                    value-type="int"
                                    class="form-control"
                                    v-if="states.offerIsLoaded"
                                />
                            </div>
                            <div class="form-bloc__row" v-if="isTypeMachine">
                                <label for="edit-price">Price</label>
                                <input type="text" id="edit-price" class="form-control" :maxlength="maxLengths.SELLER_PRICE" v-model="form.price">
                            </div>
                            <div class="form-bloc__row">
                                <label for="edit-country">Country</label>
                                <SelectCountry
                                    id="edit-country"
                                    classes="form-control"
                                    v-model="form.countryUuid"
                                />
                            </div>
                            <div class="form-bloc__row">
                                <label for="edit-reference">Your reference</label>
                                <input type="text" id="edit-reference" class="form-control" :maxlength="maxLengths.SELLER_REFERENCE" v-model="form.reference">
                            </div>
                            <div class="form-bloc__row">
                                <label for="wotol-reference">Wotol reference</label>
                                <input disabled type="text" id="wotol-reference" class="form-control" v-model="offer.resellerReference">
                            </div>
                            <div class="form-bloc__row" v-if="isTypeAuction">
                                <label>Start</label>
                                <DatePicker
                                    @update-date="onStartsAtUpdated"
                                    class="form-control"
                                    v-if="states.offerIsLoaded"
                                    v-model="form.startsAt"
                                    v-once
                                    required
                                />
                            </div>
                            <div class="error" v-if="fieldError('startsAt')">
                                <ul>
                                    <li v-for="message in fieldError('startsAt')">
                                        <i class="fa fa-exclamation-triangle"/>&nbsp;{{ message }}
                                    </li>
                                </ul>
                            </div>
                            <div class="form-bloc__row" v-if="isTypeAuction">
                                <label>End</label>
                                <DatePicker
                                    @update-date="onEndsAtUpdated"
                                    class="form-control"
                                    v-if="states.offerIsLoaded"
                                    v-model="form.endsAt"
                                    :startDate="new Date()"
                                    v-once
                                    required
                                />
                            </div>
                            <div class="error" v-if="fieldError('endsAt')">
                                <ul>
                                    <li v-for="message in fieldError('endsAt')">
                                        <i class="fa fa-exclamation-triangle"/>&nbsp;{{ message }}
                                    </li>
                                </ul>
                            </div>
                            <div class="form-bloc__row" v-if="isTypeAuction">
                                <label>External link</label>
                                <input type="text" class="form-control" :maxlength="maxLengths.ORIGINAL_URL" v-model="form.originalUrl" placeholder="https://">
                            </div>
                            <hr>
                            <div class="edit-page__description">
                                <label for="edit-description" class="sr-only"></label>
                                <textarea id="edit-description" class="form-control" placeholder="Description" v-model="form.description" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="product-contact product-contact--light">
                            <!-- Offer is already premium: -->
                            <template v-if="offer.premium">
                                <h3 class="product-contact__title">
                                    Premium until <br> {{ offer.premiumUntil|formatDate('D MMM. YYYY') }}
                                </h3>
                                <img src="/build/images/premium.png" alt="" class="premium-image">
                            </template>
                            <!-- Offer is not premium, suggest to upgrade: -->
                            <template v-if="!offer.premium">
                                <h3 class="product-contact__title">
                                    Become premium <br> for more visibility
                                </h3>
                                <img src="/build/images/not-premium.png" alt="" class="premium-image">
                                <UpgradeAdToPremiumButton :uuid="offerUuid" :is-auction="isTypeAuction" style="display: flex; justify-content: center;">
                                    <button
                                        slot-scope="{ onClick, label }"
                                        type="button"
                                        :disabled="!canBeUpgradedToPremium"
                                        @click="onClick"
                                        class="btn btn--premium">{{ label }}
                                    </button>
                                </UpgradeAdToPremiumButton>
                            </template>
                        </div>
                        <p class="edit-page__recap">
                            <label>Date</label>
                            <span>{{ offer.status.createdAt|formatDate('D MMM. YYYY') }}</span>
                        </p>
                        <p class="edit-page__recap">
                            <label>Status</label>
                            <span>{{ offerAvailabilityLabel }}</span>
                        </p>
                        <!--<p class="edit-page__recap">
                            <label>Views</label>
                            <span class="bullet-number">45</span>
                        </p>-->
                        <p class="edit-page__recap" v-if="offer.requestsCount > 0">
                            <label>Requests</label>
                            <span class="bullet-number bullet-number--primary">{{ offer.requestsCount }}</span>
                        </p>
                        <hr>
                        <p>
                            <button @click.prevent="onDeleteOffer()" type="button">
                                <i class="icon icon--delete"></i>
                                Delete this ad
                                <span class="fa fa-spin fa-spinner" v-if="states.isDeleting"></span>
                            </button>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="form-bloc__title">Please select pictures for your classified ad</h2>
                        <div class="drop">
                            <p class="drop__title">Photos are very important to promote your equipment</p>
                            <div class="drop__zone">
                                <div class="drop__target">
                                    <input type="file" multiple @change="onNewMedia" :accept="acceptedMimeTypes" class="input-file">
                                    <i class="icon icon--upload"></i>
                                    <div>
                                        <span>Drop files here or</span>
                                        <button class="btn btn--primary">
                                            <span>Browse</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="drop__help">
                                    <p>File formats accepted : GIF, JPEG, JPG</p>
                                    <p>Automatic photo resizing</p>
                                    <p>Recommended size : above 800 pixels width</p>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="photo-list">
                            <p class="photo-list__title">You can post up to 10 photos. If you don't have photos yet, you can add them later.</p>
                            <div class="photo-list__item" v-for="medium in media" :class="{
                                'photo-list__item--video': isVideo(medium),
                                'photo-list__item--pdf': isPdf(medium),
                            }">
                                <div class="image" :style="'background: url('+getThumbnail(medium)+')'" :class="{
                                    'image--removed': isMediaRemoved(medium),
                                }">

                                </div>
                                <!--<span>{{ thumbnail.name }}</span> No name for now -->
                                <button @click.prevent="removeMedia(medium)" v-if="!isMediaRemoved(medium)">
                                    <i class="icon icon--delete"></i>
                                    <span class="sr-only">Delete</span>
                                </button>
                                <button @click.prevent="restoreMedia(medium)" v-if="isMediaRemoved(medium)" title="Restore media">
                                    <i class="fa fa-undo"></i>
                                    <span class="sr-only">Restore</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shadow-container__footer">
            <div>
                <div class="alert alert-danger" v-if="sendError">
                    An error occurred
                </div>
                <div class="alert alert-success" v-if="states.isSaved">
                    Your Ad has successfully been updated
                </div>
            </div>
            <hr>
            <button type="submit" class="btn btn--primary" :disabled="!offer || states.isSaving">
                <span class="fa fa-spin fa-spinner" v-if="states.isSaving">&nbsp;</span>
                Save modifications
            </button>
        </div>
    </form>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import dateHelpers from '../../../../../Common/js/filters';
  import {OfferType as OFFER_TYPES} from '../../../../../Common/js/helpers/enums';

  import { MaxLengths } from "../../../../../Common/js/model/Offer/Schema";
  import SelectCountry from '../../../../../Common/js/components/form/SelectCountry';
  import DatePicker from '../../../../../Common/js/components/form/DatePicker';
  import SelectManufacturer from '../../../../../BackOffice/js/modules/Offer/components/form/SelectManufacturer';
  import Tags from '../../../../../Common/js/components/form/Tags';
  import UpgradeAdToPremiumButton from '../components/PremiumUpgrade/UpgradeAdToPremiumButton';
  import { Availability, AvailabilityReadables } from '../../../../../Common/js/helpers/enums';

  export default {
    inject: ['Security', 'App'],
    components: {
      DatePicker,
      UpgradeAdToPremiumButton,
      SelectCountry,
      SelectManufacturer,
      Tags,
    },

    constants: {
      MIME_TYPES: [
        'image/*',
      ],
      IMAGE_SOURCES: {
        pdf: '/build/images/file.7f0f1666.png',
        video: '/build/images/placeholder.jpg',
      },
    },

    props: {
      offerUuid: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        maxLengths: MaxLengths,
        form: {
          title: null,
          quantity: null,
          countryUuid: null,
          manufacturerUuids: [],
          years: [],
          price: null,
          reference: null,
          description: null,
          freeFields: {}, // List of { uuid, value } indexed by uuid
          newMedia: [],
          mediaToRemove: [],
          originalUrl: null,
          startsAt: null,
          endsAt: null,
        },
        errors: {},
        states: {
          offerIsLoaded: false,
          isSaving: false,
          isDeleting: false,
          isSaved: false,
        },
        sendError: false,
      };
    },

    created() {
      this.App.registerOnReadyListener(() => {
        this.loadMyClassifiedAd({ uuid: this.offerUuid }).then(this.onOfferLoaded);
      });
    },

    computed: {
      ...mapGetters([
        'getOffer',
        'getOfferMedia',
        'getCategoryHierarchy',
        'getCategory',
      ]),

      offer() {
        return this.getOffer(this.offerUuid);
      },

      offerAvailabilityLabel() {
        return AvailabilityReadables[this.offer.availabilityStatus];
      },

      canBeUpgradedToPremium() {
        return this.offer.availabilityStatus === Availability.ONLINE || this.offer.availabilityStatus === Availability.PENDING;
      },

      isTypeMachine() {
        return this.offer.type === OFFER_TYPES.MACHINE;
      },

      isTypeAuction() {
        return this.offer.type === OFFER_TYPES.AUCTION;
      },

      categoryHierarchy() {
        return this.getCategoryHierarchy(this.offer.category);
      },

      /**
       * @return {Array} Array of existing media along with added ones in a normalized format:
       *                { uuid, url, type } for existing one or { file } for a newly created one to upload.
       */
      media() {
        const media = this.getOfferMedia(this.offerUuid).map(({ uuid, url, type }) => ({ uuid, url, type }));
        this.form.newMedia.forEach((file) => {
          media.push({ file });
        });

        return media;
      },

      acceptedMimeTypes() {
        return this.MIME_TYPES.join(',');
      },
    },

    methods: {
      ...mapActions([
        'loadMyClassifiedAd',
        'editMyClassifiedAd',
      ]),

      copyTitleToClipboard(){
        const dummy = document.createElement("input");
        document.body.appendChild(dummy);
        dummy.setAttribute('value', this.form.title)
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
      },

      onOfferLoaded() {
        this.form = {
          title: this.offer.fullTitle,
          quantity: this.offer.quantity,
          countryUuid: this.offer.country,
          manufacturerUuids: this.offer.manufacturers,
          years: this.offer.years,
          price: this.offer.sellerPrice,
          reference: this.offer.sellerReference,
          description: this.offer.description,
          freeFields: this.offer.freeFields
              .map(({ uuid, value }) => ({ uuid, value })) // Only keep required fields
              .reduce((result, field) => { // index by uuids
                result[field.uuid] = field;
                return result;
              }, {})
          ,
          newMedia: [],
          mediaToRemove: [],
          originalUrl: this.offer.originalUrl,
          startsAt: this.offer.startsAt,
          endsAt: this.offer.endsAt,
        };
        this.states.offerIsLoaded = true;
      },

      fieldError(field) {
        return this.errors[field] || false;
      },

      isPdf(medium) {
        if (medium.file) {
          return medium.file.type.match(/^application\/pdf$/);
        }

        return medium.url.match(/\.pdf$/);
      },

      isVideo(medium) {
        if (medium.file) {
          return medium.file.type.match(/^video/);
        }

        return medium.url.match(/\.(mp4|mpg|mpeg|mov|avi|flv|mkv)$/);
      },

      getThumbnail(medium) {
        if (this.isVideo(medium)) {
          return this.IMAGE_SOURCES.video;
        }

        if (this.isPdf(medium)) {
          return this.IMAGE_SOURCES.pdf;
        }

        // Existing image with url or new one to upload:
        return medium.url || window.URL.createObjectURL(medium.file);
      },

      removeMedia(medium) {
        if (medium.uuid) {
          // Medium already exists and must be removed from our base:
          this.form.mediaToRemove.push(medium.uuid);
        } else {
          // Medium does not already exist and should just be removed from newMedia:
          this.form.newMedia.splice(this.form.newMedia.indexOf(medium.file), 1);
        }
      },

      restoreMedia(medium) {
        this.form.mediaToRemove.splice(this.form.mediaToRemove.indexOf(medium.uuid), 1);
      },

      isMediaRemoved(medium) {
        return medium.uuid ? this.form.mediaToRemove.includes(medium.uuid) : false;
      },

      onNewMedia(e) {
        /** @var FileList fileList */
        const fileList = e.target.files;
        for (let i = 0; fileList.length && i < fileList.length; i++) {
          this.form.newMedia.push(fileList[i]);
        }
      },

      onEndsAtUpdated(date) {
        this.form.endsAt = dateHelpers.formatDateRFC3339(date);
      },

      onStartsAtUpdated(date) {
        this.form.startsAt = dateHelpers.formatDateRFC3339(date);
      },

      onSubmit() {
        this.states.isSaving = true;
        const payload = Object.assign({}, this.form);

        payload.manufacturerUuids = payload.manufacturerUuids || [];
        payload.freeFields = Object.values(payload.freeFields); // remove indexes

        this.errors = {};

        this.editMyClassifiedAd({
          uuid: this.offer.uuid,
          payload,
        }).then(this.onSuccess, this.onFailure);
      },

      onSuccess() {
        this.states.isSaved = true;
        this.states.isSaving = false;

        setTimeout(() => {
          this.states.isSaved = false;
        }, 5000);
      },

      onDeleteOffer() {
        this.states.isDeleting = true;

        if (confirm("Do you really want to delete this add ?")) {
          $('#deleteAdForm').submit();
        }

        this.states.isDeleting = false;
      },

      onFailure(error) {
        this.states.isSaving = false;
        this.sendError = error.message;

        // TODO: global error helper:
        (error.graphQLErrors || []).forEach((graphQLError) => {
          ((graphQLError.api_problem && graphQLError.api_problem.violations) || []).forEach(error => {
            if (!this.errors[error.path]) {
              this.errors[error.path] = [];
            }

            this.errors[error.path].push(error.reason);
          })
        });
      },
    },
  };
</script>
