// Source : https://gist.github.com/trungnghia112/5821f2e6515971d9afec51dd659e68af

$(document).on({
  'show.bs.modal': function() {
    var zIndex = 1040 + (10 * $('.modal:visible').length);
    $(this).css('z-index', zIndex);
    setTimeout(function() {
      $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    }, 0);
  },
  'hidden.bs.modal': function() {
    if ($('.modal:visible').length > 0) {
      // restore the modal-open class to the body element, so that scrolling works
      // properly after de-stacking a modal.
      setTimeout(function() {
        $(document.body).addClass('modal-open');
      }, 0);
    }
  }
}, '.modal');
