<template>
    <div>
        <SelectPremiumOption v-if="!isAuction" @input="onSelectedPremiumOption" suggestSinglePremium />
        <SelectPremiumOptionForAuction v-if="isAuction" @input="onSelectedPremiumOption" />
        <SelectPremiumFooter :back="back" />
    </div>
</template>

<script>
  import SelectPremiumOption from '../Premium/SelectPremiumOption';
  import SelectPremiumOptionForAuction from '../Premium/SelectPremiumOptionForAuction';
  import SelectPremiumFooter from '../Premium/SelectPremiumFooter';

  export default {
    components: { SelectPremiumOption, SelectPremiumFooter, SelectPremiumOptionForAuction },

    props: {
      formName: {
        type: String,
        required: true,
      },
      premiumOptionField: {
        type: String,
        required: true,
      },
      premiumPackField: {
        type: String,
        required: true,
      },
      isAuction: {
        type: Boolean,
        required: true
      },
    },

    methods: {

      onSelectedPremiumOption(selectedOption) {
        const form = $(`form[name="${this.formName}"]`);

        form.find(`[name="${this.premiumOptionField}"]`).val(selectedOption.method.toLowerCase()) ;
        form.find(`[name="${this.premiumPackField}"]`).val(selectedOption.packUuid);

        form.submit();
      },

      back() {
        window.history.back();
      }
    },
  };
</script>
