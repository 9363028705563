var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-bloc form-bloc--striped" }, [
      _c("h2", { staticClass: "form-bloc__title" }, [
        _vm._v("Please fill in the description of your classified ad"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-bloc__row" }, [
        _c("div", [
          _c("label", [_vm._v("Category")]),
          _vm._v(" "),
          _vm.offer.categoryUuid
            ? _c("ul", { staticClass: "breadcrumb" }, [
                _c("li", { staticClass: "breadcrumb__item" }, [
                  _vm._v(_vm._s(_vm.industryName)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb__item" }, [
                  _vm._v(_vm._s(_vm.parentCategoryName)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "breadcrumb__item" }, [
                  _vm._v(_vm._s(_vm.categoryName)),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-bloc__row" }, [
        _c(
          "div",
          [
            _c("label", [_vm._v("Manufacturer")]),
            _vm._v(" "),
            _c("SelectManufacturer", {
              staticClass: "form-control",
              attrs: { "category-uuid": _vm.offer.categoryUuid },
              model: {
                value: _vm.form.manufacturerUuids,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "manufacturerUuids", $$v)
                },
                expression: "form.manufacturerUuids",
              },
            }),
            _vm._v(" "),
            _c("p", { staticClass: "help" }, [
              _vm._v(
                "If the manufacturer does not appear in the list, type OTHER. "
              ),
              _c("br"),
              _vm._v(" Also, you can "),
              _c(
                "a",
                { attrs: { href: _vm.contactPageUri, target: "_blank" } },
                [_vm._v("contact us")]
              ),
              _vm._v(" to add this manufacturer."),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-bloc__row" },
        [
          _vm._l(_vm.categoryFreeFields, function (freeField) {
            return [
              _c("div", [
                _c("label", [_vm._v(_vm._s(freeField.name))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.lazy.trim",
                      value: _vm.form.freeFields[freeField.uuid],
                      expression: "form.freeFields[freeField.uuid]",
                      modifiers: { lazy: true, trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "offer-free-field-" + freeField.uuid,
                    type: "text",
                  },
                  domProps: { value: _vm.form.freeFields[freeField.uuid] },
                  on: {
                    change: function ($event) {
                      _vm.$set(
                        _vm.form.freeFields,
                        freeField.uuid,
                        $event.target.value.trim()
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ]
          }),
          _vm._v(" "),
          _c("div", [
            _c("label", { attrs: { for: "offer-information-quantity" } }, [
              _vm._v("Quantity"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.form.quantity,
                  expression: "form.quantity",
                  modifiers: { number: true },
                },
              ],
              staticClass: "form-control",
              class: {
                "input-error": _vm.fieldError("quantity"),
              },
              attrs: { type: "number", id: "offer-information-quantity" },
              domProps: { value: _vm.form.quantity },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "quantity", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
            _vm._v(" "),
            _vm.fieldError("quantity")
              ? _c("div", { staticClass: "error" }, [
                  _vm._v("Quantity is required"),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("label", [_vm._v("Years")]),
              _vm._v(" "),
              _c("Tags", {
                staticClass: "form-control",
                attrs: { options: _vm.form.years, "value-type": "int" },
                model: {
                  value: _vm.form.years,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "years", $$v)
                  },
                  expression: "form.years",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c("label", { attrs: { for: "offer-information-price" } }, [
              _vm._v("Price"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.price,
                  expression: "form.price",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: _vm.maxLengths.SELLER_PRICE,
                id: "offer-information-price",
              },
              domProps: { value: _vm.form.price },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "price", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("label", [_vm._v("Country")]),
              _vm._v(" "),
              _c("SelectCountry", {
                attrs: { classes: "form-control" },
                model: {
                  value: _vm.form.countryUuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "countryUuid", $$v)
                  },
                  expression: "form.countryUuid",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c("label", { attrs: { for: "offer-information-reference" } }, [
              _vm._v("Your reference"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.reference,
                  expression: "form.reference",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                maxlength: _vm.maxLengths.SELLER_REFERENCE,
                id: "offer-information-reference",
              },
              domProps: { value: _vm.form.reference },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "reference", $event.target.value)
                },
              },
            }),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "form-bloc__row form-bloc__row--vertical" }, [
        _c("label", { attrs: { for: "offer-information-description" } }, [
          _vm._v("Description"),
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.description,
              expression: "form.description",
            },
          ],
          staticClass: "form-control",
          attrs: { id: "offer-information-description" },
          domProps: { value: _vm.form.description },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "description", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "shadow-container__footer" }, [
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "inner-footer" }, [
        _c(
          "button",
          {
            staticClass: "button-back btn btn--empty",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("goToPrevious")
              },
            },
          },
          [
            _c("i", { staticClass: "icon icon--previous" }),
            _vm._v("\n                Back\n            "),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button-next btn btn--primary",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onSubmit()
              },
            },
          },
          [
            _vm._v("\n                Next\n                "),
            _c("i", { staticClass: "icon icon--next" }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }