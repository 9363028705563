var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shadow-container" },
    [
      _c("div", { staticClass: "shadow-container__header" }, [
        _c(
          "div",
          { staticClass: "step" },
          _vm._l(_vm.steps, function (label, step) {
            return _c(
              "div",
              {
                staticClass: "step__item",
                class: {
                  "step__item--active": _vm.isActive(step),
                  "step__item--done": _vm.isDone(step),
                },
                on: {
                  click: function ($event) {
                    return _vm.goToStep(step)
                  },
                },
              },
              [
                _c("span", { staticClass: "icon icon--check" }),
                _vm._v(" "),
                _c("span", { staticClass: "number" }, [
                  _vm._v(_vm._s(step + 1)),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "name" }, [_vm._v(_vm._s(label))]),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("TypeStep", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isCurrent(_vm.STEPS.TYPE),
            expression: "isCurrent(STEPS.TYPE)",
          },
        ],
        attrs: { offer: _vm.form },
        on: { updated: _vm.onUpdated },
      }),
      _vm._v(" "),
      _c("CategoryStep", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isCurrent(_vm.STEPS.CATEGORY) && !_vm.isAuction,
            expression: "isCurrent(STEPS.CATEGORY) && !isAuction",
          },
        ],
        attrs: { offer: _vm.form },
        on: {
          updated: _vm.onUpdated,
          goToPrevious: function ($event) {
            return _vm.goPrevious()
          },
        },
      }),
      _vm._v(" "),
      _c("IndustryStep", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isCurrent(_vm.STEPS.CATEGORY) && _vm.isAuction,
            expression: "isCurrent(STEPS.CATEGORY) && isAuction",
          },
        ],
        attrs: { offer: _vm.form },
        on: {
          updated: _vm.onUpdated,
          goToPrevious: function ($event) {
            return _vm.goPrevious()
          },
        },
      }),
      _vm._v(" "),
      _c("DescriptionStep", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isCurrent(_vm.STEPS.DESCRIPTION) && !_vm.isAuction,
            expression: "isCurrent(STEPS.DESCRIPTION) && !isAuction",
          },
        ],
        attrs: { offer: _vm.form },
        on: {
          updated: _vm.onUpdated,
          goToPrevious: function ($event) {
            return _vm.goPrevious()
          },
        },
      }),
      _vm._v(" "),
      _c("DescriptionForAuctionStep", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isCurrent(_vm.STEPS.DESCRIPTION) && _vm.isAuction,
            expression: "isCurrent(STEPS.DESCRIPTION) && isAuction",
          },
        ],
        attrs: { offer: _vm.form },
        on: {
          updated: _vm.onUpdated,
          goToPrevious: function ($event) {
            return _vm.goPrevious()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "MediaStep",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isCurrent(_vm.STEPS.PHOTOS),
              expression: "isCurrent(STEPS.PHOTOS)",
            },
          ],
          attrs: { offer: _vm.form },
          on: {
            updated: _vm.onMediaSelected,
            goToPrevious: function ($event) {
              return _vm.goPrevious()
            },
          },
        },
        [
          _c("FeedbackModal", {
            attrs: {
              slot: "modalFeedback",
              state: _vm.state,
              message: "Uploading photos might take time. Please be patient!",
            },
            slot: "modalFeedback",
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isMachine
        ? _c(
            "PaymentStep",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCurrent(_vm.STEPS.STATUS),
                  expression: "isCurrent(STEPS.STATUS)",
                },
              ],
              attrs: {
                offer: _vm.form,
                active: _vm.isCurrent(_vm.STEPS.STATUS),
              },
              on: {
                paymentMethodSelected: _vm.onPaymentMethodSelected,
                goToPrevious: function ($event) {
                  return _vm.goPrevious()
                },
              },
            },
            [
              _c("FeedbackModal", {
                attrs: {
                  slot: "modalFeedback",
                  state: _vm.state,
                  message: "Your order is being processed. Please be patient!",
                },
                slot: "modalFeedback",
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAuction
        ? _c(
            "PaymentForAuctionStep",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isCurrent(_vm.STEPS.STATUS),
                  expression: "isCurrent(STEPS.STATUS)",
                },
              ],
              on: {
                paymentMethodSelected: _vm.onPaymentMethodSelected,
                goToPrevious: function ($event) {
                  return _vm.goPrevious()
                },
              },
            },
            [
              _c("FeedbackModal", {
                attrs: {
                  slot: "modalFeedback",
                  state: _vm.state,
                  message: "Your order is being processed. Please be patient!",
                },
                slot: "modalFeedback",
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }