import { get } from 'dot-prop-immutable';
import createPersistedState from 'vuex-persistedstate';
import { CLEAR_ENTITIES, ADD_LOADED_QUERIES, LOCAL_STORAGE_READY  } from '../../../../../../Common/js/modules/store/mutation-types';
import { LOAD_CATEGORIES_QUERY } from '../../../Offer/graphql/queries';
import Schema from '../../../../normalizr.schema';
import Router from '../../../../../../Common/js/modules/service/Router';
import routes from '../../routes';

const CATEGORIES_CACHE_KEY = 'categories';
const CATEGORIES_TTL = 24 * 60 * 60; // 24 hours

/**
 * Local storage plugin built on top of "vuex-persistedstate"
 *
 * Used to store:
 *  - categories: as they are not updated often, loading them once a day should be enough.
 *
 * @see https://vuex.vuejs.org/en/plugins.html
 * @see https://github.com/robinvdvleuten/vuex-persistedstate
 *
 * @param store
 */
const localStoragePlugin = (store) => {
  const categoriesPath = 'offer.categories';
  const cacheKey = (p) => `vuex:cache_keys:${p}`;

  createPersistedState({
    paths: [categoriesPath],
  })(store);

  fetch(Router.generate(routes.GET_CACHE_KEY, {
    cacheKey: 'categories',
    ttl: CATEGORIES_TTL,
  })).then((response) => {
    response.text().then((text) => {
      // Check categories are not stale:
      if (text !== localStorage.getItem(cacheKey(CATEGORIES_CACHE_KEY))) {
        localStorage.setItem(cacheKey(CATEGORIES_CACHE_KEY), text); // save new key
        store.commit(CLEAR_ENTITIES, Schema.Category); // clear previously saved categories
      }

      if (Object.values(get(store.state, categoriesPath) || {}).length > 0) {
        // mark related queries as loaded if we already have categories in the local storage:
        store.commit(ADD_LOADED_QUERIES, LOAD_CATEGORIES_QUERY);
      }

      // Store is ready
      store.commit(LOCAL_STORAGE_READY);
    });
  });
};

/**
 * Do not execute query if already loaded from local storage.
 *
 * TODO: Should ideally be an Apollo Client plugin
 *
 * @param {Object} getters Vuex getters
 * @param {Object} query The query to check
 * @param {Function} originalCode Original code to execute
 *
 * @return {Promise<any>}
 */
export const ignoringOnLocalStoredQuery = (getters, query, originalCode) => {
  if (getters.isQueryLoaded(query)) {
    return new Promise(resolve => resolve());
  }

  return new Promise(originalCode);
};

export default localStoragePlugin;
