// Add entities (normalizr data)
export const ADD_ENTITIES = 'ADD_ENTITIES';

export const CLEAR_ENTITIES = 'CLEAR_ENTITIES';

// Vuex local storage plugin
export const LOCAL_STORAGE_READY = 'LOCAL_STORAGE_READY';

// Register important loaded queries
export const ADD_LOADED_QUERIES = 'ADD_LOADED_QUERIES';
