<template>
    <div>
        <div class="form-bloc">
            <h2 class="form-bloc__title">Please select a category for your classified ad</h2>
            <p class="form-bloc__help" v-show="isMachine">
                Your classified ad will be online for 1 year.<br />
                At the end of posting, you will be able to choose between free ad or Premium ad.
            </p>
            <p class="form-bloc__help" v-show="isJob || isResume">
                You can post a free classified ad for 1 year
            </p>

            <div class="fa fa-spin fa-spinner" v-if="loading" />

            <transition-group tag="ul" name="fade" class="categories">
                <ul class="list-group" v-show="currentLevel == LEVELS.INDUSTRY && !loading" :key="LEVELS.INDUSTRY">
                    <li class="list-group-item list-group__previous">
                        <a href="#" @click.prevent="$emit('goToPrevious')">
                            <i class="icon icon--back"></i>
                            {{ typeLabel }}
                        </a>
                    </li>
                    <li class="list-group-item" v-for="industry in industries">
                        <div class="radio">
                            <input type="radio" name="industry" :id="industry.uuid" @click="setIndustry(industry.uuid)" :checked="industry.uuid == industryUuid">
                            <label :for="industry.uuid">{{ industry.name }}</label>
                        </div>
                    </li>
                </ul>
                <ul class="list-group" v-show="currentLevel == LEVELS.PARENT_CATEGORY" :key="LEVELS.PARENT_CATEGORY">
                    <li class="list-group-item list-group__previous">
                        <a href="#" @click.prevent="goToLevel(LEVELS.INDUSTRY)">
                            <i class="icon icon--back"></i>
                            {{ currentIndustry && currentIndustry.name }}
                        </a>
                    </li>
                    <li class="list-group-item" v-for="parentCategory in parentCategories">
                        <div class="radio">
                            <input type="radio" name="parentCategory" :id="parentCategory.uuid" @click="setParentCategory(parentCategory.uuid)" :checked="parentCategory.uuid == parentCategoryUuid">
                            <label :for="parentCategory.uuid">{{ parentCategory.name }}</label>
                        </div>
                    </li>
                </ul>
                <ul class="list-group" v-show="currentLevel == LEVELS.CATEGORY" :key="LEVELS.CATEGORY">
                    <li class="list-group-item list-group__previous">
                        <a href="#" @click.prevent="goToLevel(LEVELS.INDUSTRY)">
                            <i class="icon icon--back"></i>
                            {{ currentIndustry && currentIndustry.name }}
                        </a>
                        <a href="#" @click.prevent="goToLevel(LEVELS.PARENT_CATEGORY)">
                            <i class="icon icon--back"></i>
                            {{ currentParentCategory && currentParentCategory.name }}
                        </a>
                    </li>
                    <li class="list-group-item" v-for="category in categories">
                        <div class="radio">
                            <input type="radio" name="category" :id="category.uuid" @click="setCategory(category.uuid)" :checked="category.uuid == categoryUuid">
                            <label :for="category.uuid">{{ category.name }}</label>
                        </div>
                    </li>
                </ul>
            </transition-group>
        </div>
        <div class="shadow-container__footer">
            <hr>
            <div class="inner-footer">
                <button class="button-back btn btn--empty" @click.prevent="$emit('goToPrevious')">
                    <i class="icon icon--previous"></i>
                    Back
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import {OfferType as OFFER_TYPES} from '../../../../../../../Common/js/helpers/enums'
  import { getChoiceForOffer, getChoiceLabel } from "./TypeChoices";

  import { mapActions, mapGetters } from 'vuex';

  export default {
    inject: ['App'],
    constants: {
      OFFER_TYPES: OFFER_TYPES,
      LEVELS: {
        INDUSTRY: 1,
        PARENT_CATEGORY: 2,
        CATEGORY: 3,
      },
    },
    props: {
      offer: {
        type: Object,
        required: true,
      },
    },
    data: function() {
      return {
        categoryUuid: null,
        parentCategoryUuid: null,
        industryUuid: null,
        loading: false,
        currentLevel: this.LEVELS.INDUSTRY,
      };
    },
    created() {
      this.categoryUuid = this.offer.categoryUuid;
      this.loading = true;
      this.App.registerOnReadyListener(() => {
        this.loadCategories().then(() => {
          this.loading = false;
          this.refreshCategoryPath();
        })
      });
    },
    methods: {
      ...mapActions([
        'loadCategories',
      ]),
      goToLevel(level) {
        window.scrollTo(0,0);
        this.currentLevel = level;
      },
      setIndustry(uuid) {
        this.industryUuid = uuid;
        this.goToLevel(this.LEVELS.PARENT_CATEGORY);
      },
      setParentCategory(uuid) {
        this.parentCategoryUuid = uuid;
        this.goToLevel(this.LEVELS.CATEGORY);
      },
      setCategory(uuid) {
        this.categoryUuid = uuid;
        this.$emit('updated', { categoryUuid: this.categoryUuid });
      },
      refreshCategoryPath() {
        this.parentCategoryUuid = this.getCategory(this.categoryUuid) && this.getCategory(this.categoryUuid).parent ;
        this.industryUuid = this.getCategory(this.parentCategoryUuid) && this.getCategory(this.parentCategoryUuid).parent;
      },
    },
    watch: {
      offer: function (val) {
        if (!val.categoryUuid) {
          this.goToLevel(this.LEVELS.INDUSTRY);
        }
        this.categoryUuid = val.categoryUuid;
        this.refreshCategoryPath();
      },
    },
    computed: {
      ...mapGetters([
        'industries',
        'getCategory',
        'getCategoryChildren',
      ]),
      typeLabel() {
       return getChoiceLabel(getChoiceForOffer(this.offer));
      },
      currentCategory() {
        return this.getCategory(this.categoryUuid);
      },
      currentParentCategory() {
        return this.getCategory(this.parentCategoryUuid);
      },
      currentIndustry() {
        return this.getCategory(this.industryUuid);
      },
      parentCategories() {
        return this.industryUuid && this.getCategoryChildren(this.industryUuid) || [];
      },
      categories() {
        return this.parentCategoryUuid && this.getCategoryChildren(this.parentCategoryUuid) || [];
      },
      isMachine() { return this.offer.type === this.OFFER_TYPES.MACHINE },
      isAuction() { return this.offer.type === this.OFFER_TYPES.AUCTION },
      isJob() { return this.offer.type === this.OFFER_TYPES.JOB },
      isResume() { return this.offer.type === this.OFFER_TYPES.RESUME },
    }
  };
</script>

<style lang="scss" scoped>
    .categories {
        overflow: hidden;
        position: relative;
        min-height: 500px;
    }

    /* Animations */
    .fade-leave-active,
    .fade-enter-active {
        transition: opacity .5s;
        position: absolute;
        top: 0;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
