<template>
    <div>
        <slot :onClick="onClick" :label="label" />
    </div>
</template>

<script>
  import routes from '../../../../modules/Common/routes';

  export default {
    inject: ['Security', 'Router'],
    props: {
      // Offer uuid
      uuid: {
        type: String,
        required: true,
      },
      isAuction: {
        type: Boolean,
        required: true,
      },
    },

    computed: {
      isPremiumMember() {
        return this.Security.user.premium;
      },

      label() {
        return this.isAuction || this.isPremiumMember ? 'Upgrade to premium?' : 'Become Premium';
      },

      orderUri() {
        return this.Router.generate(routes.ORDER_CLASSIFIED_AD_PREMIUM_UPGRADE, { uuid: this.uuid });
      }
    },

    methods: {
      onClick() {
        !this.isAuction && this.isPremiumMember ? this.triggerModal() : this.redirectToOrder();
      },
      redirectToOrder() {
        window.location = this.orderUri;
      },
      triggerModal() {
        $(`#modal-upgrade_to_premium-${ this.uuid }`).modal('show');
      },
    },
  };
</script>
