import apolloClient from "../service/GraphQLClient";
import * as types from "./mutation-types";
import Schema from "../../normalizr.schema";
import { LOAD_ZONES_QUERY } from "../../../../BackOffice/js/modules/Offer/graphql/queries";
import { normalize } from "normalizr";

export default {
  /**
   * Load all geographical zones and their countries
   */
  loadZones({commit}) {
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: LOAD_ZONES_QUERY,
      }).then((response) => {
        const zones = response.data['Zones'];
        const normalized = normalize(zones, [Schema.Zone]);
        commit(types.ADD_ENTITIES, normalized);
        resolve();
      }, reject);
    });
  },
}
