import {
  EDIT_MY_CLASSIFIED_AD_MUTATION,
  PLACE_AN_AD,
  PLACE_AN_AD_UPDATE_PAYMENT_MUTATION,
  REQUEST_OFFER_PRICE,
} from "../graphql/mutations";
import {
  LOAD_CATEGORIES_QUERY,
  LOAD_INDUSTRIES_QUERY,
  LOAD_MY_CLASSIFIED_AD,
  LOAD_PREMIUM_PACKS,
} from "../graphql/queries";

import apolloClient from "../../../../../Common/js/modules/service/GraphQLClient";
import * as commonTypes from "../../../../../Common/js/modules/store/mutation-types";
import { ignoringOnLocalStoredQuery } from "../../Common/store/plugins/localStoragePlugin";
import { normalize } from "normalizr";
import Schema from "../../../normalizr.schema";

export default {
  requestPrice({ commit }, { uuid, payload }) {
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: REQUEST_OFFER_PRICE,
        variables: {
          uuid,
          payload,
        },
      }).then((response) => {
        const messageUuid = response.data['RequestOfferPrice'].uuid;
        resolve(messageUuid);
      }, reject);
    });
  },

  /**
   * Load all categories
   */
  loadCategories({ commit, getters }) {
    return ignoringOnLocalStoredQuery(getters, LOAD_CATEGORIES_QUERY, (resolve, reject) => {
      apolloClient.query({
        query: LOAD_CATEGORIES_QUERY,
      }).then((response) => {
        // Do only process response if the query wasn't already processed earlier:
        if (!getters.isQueryLoaded(LOAD_CATEGORIES_QUERY)) {
          const categories = response.data['Categories'];
          const normalized = normalize(categories, [Schema.Category]);
          commit(commonTypes.ADD_ENTITIES, normalized);
          commit(commonTypes.ADD_LOADED_QUERIES, LOAD_CATEGORIES_QUERY);
        }
        resolve();
      }, reject);
    });
  },

  loadIndustries({ commit }) {
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: LOAD_INDUSTRIES_QUERY,
      }).then((response) => {
        const industries = response.data['Categories'];
        const normalized = normalize(industries, [Schema.Category]);
        commit(commonTypes.ADD_ENTITIES, normalized);
        resolve();
      }, reject);
    });
  },

  loadPremiumPacks({ commit }) {
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: LOAD_PREMIUM_PACKS,
      }).then((response) => {
        const premiumPacks = response.data['PremiumPacks'];
        const normalized = normalize(premiumPacks, [Schema.PremiumPack]);
        commit(commonTypes.ADD_ENTITIES, normalized);
        resolve();
      }, reject);
    });
   },

  loadMyClassifiedAd({ commit }, { uuid }) {
    return new Promise((resolve, reject) => {
      apolloClient.query({
        query: LOAD_MY_CLASSIFIED_AD,
        variables: { uuid },
      }).then((response) => {
        const offer = response.data['MyClassifiedAd'];
        const normalized = normalize(offer, Schema.Offer);
        commit(commonTypes.ADD_ENTITIES, normalized);
        resolve();
      }, reject);
    });
  },

  editMyClassifiedAd({ commit }, { uuid, payload }) {
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: EDIT_MY_CLASSIFIED_AD_MUTATION,
        variables: {
          uuid,
          payload,
        },
      }).then(resolve, reject);
    });
  },

  placeAnAd({ commit, dispatch }, { payload }) {
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: PLACE_AN_AD,
        variables: {
          payload,
        },
      }).then((response) => {
        const newOfferData = response.data['PlaceAnAd'];
        resolve(newOfferData);
      }, reject);
    });
  },

  placeAnAdUpdatePayment({ commit, dispatch }, { uuid, payload }) {
    return new Promise((resolve, reject) => {
      apolloClient.mutate({
        mutation: PLACE_AN_AD_UPDATE_PAYMENT_MUTATION,
        variables: {
          uuid,
          payload,
        },
      }).then((response) => {
        const newOfferData = response.data['PlaceAnAdUpdatePayment'];
        resolve(newOfferData);
      }, reject);
    });
  },
};
