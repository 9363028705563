<template>
    <select
        v-model="value"
        @input="newValue => { $emit('input', newValue) }"
        :multiple="multiple"
    />
</template>

<script>
  import { mapGetters } from 'vuex';
  import select2 from '../../../../../../Common/js/mixins/select2';

  export default {
    inject: ['Router'],
    mixins: [select2],
    props: {
      // v-model: manufacturers uuid
      value: {
        type: Array,
        required: false,
        default: () => [],
      },
      // Category uuid to give hints about current manufacturers used in this category
      categoryUuid: {
        type: String,
        required: false,
        default: null,
      },
      // Option to use component without multiple choice, for filter offers view
      multiple: {
        type: Boolean,
        required: false,
        default: () => true,
      },
    },

    created() {
      this.$nextTick(this.selectize);
    },

    computed: {
      ...mapGetters([
        'getManufacturer',
      ]),
      manufacturer(uuid) {
        return uuid && this.getManufacturer(uuid);
      },
    },

    watch: {
      value(newValue, oldValue) {
        this.__select2_watch(newValue, oldValue);
      },
    },

    methods: {

      /**
       * Create select-list's option for initial value
       *
       * @see https://select2.org/data-sources/ajax#default-pre-selected-values
       * @see https://select2.org/programmatic-control/add-select-clear-items#creating-new-options-in-the-dropdown
       *
       * @returns {Option}
       */
      getOptionsForInitialValue() {
        if (this.value.length < 0) {
            return [];
        }

        return this.value.map((uuid) => {
          const manufacturer = this.getManufacturer(uuid);
          const data = {
            id: manufacturer.uuid,
            text: manufacturer.name,
          };

          return new Option(data.text, data.id, true, true);
        });
      },

      selectize() {
        const vm = this;

        // Add options for initial data if not empty:
        vm.getOptionsForInitialValue().forEach(option => { this.getSelect2Input().append(option) });

        this.getSelect2Input()
        // init select2
          .select2({
            minimumInputLength: 0,
            dataType: 'json',
            ajax: {
              url: this.Router.generate('common_manufacturers_search'),
              delay: 500,
              data: function (params) {
                return {
                  search: params.term || '',
                  categoryUuid: vm.categoryUuid,
                  page: params.page || 1,
                  perPage: 100,
                  _type: 'query_append',
                };
              },
              cache: true,
              processResults: data => {
                const processed = {
                  pagination: {
                    more: data.pageInfo.hasNextPage,
                  },
                };

                const searchResults = data.edges.map(manufacturer => {
                  return {
                    id: manufacturer.uuid,
                    text: manufacturer.name,
                  }
                });

                if (data.pageInfo.offset > 0) {
                  // if not the first page, just return new results. No suggestion, no group to avoid duplication.
                  processed.results = searchResults;

                  return processed;
                }

                const searchResultsGroup = {
                  text: 'Other manufacturers',
                  children: searchResults
                };

                const suggestedGroup = {
                  text: 'Suggested for this category',
                  children: data.suggested.map(manufacturer => {
                    return {
                      id: manufacturer.uuid,
                      text: manufacturer.name,
                    }
                  })
                };

                processed.results = [
                  suggestedGroup.children.length > 0 ? suggestedGroup : null,
                  searchResultsGroup
                ].filter(v => v);

                return processed;
              },
            },
          })
          // Init with initial value and trigger change
          .val(vm.value)
          .trigger('change')
          // emit event on change.
          .on('change', this.__select2_emit());
      },
    },
  };
</script>
