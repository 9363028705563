<template>
    <div class="shadow-container">
        <div class="shadow-container__header">
            <div class="step">
                <div v-for="(label, step) in steps" class="step__item" :class="{
                    'step__item--active': isActive(step),
                    'step__item--done': isDone(step),
                }" @click="goToStep(step)">
                    <span class="icon icon--check"></span>
                    <span class="number">{{ step + 1 }}</span>
                    <span class="name">{{ label }}</span>
                </div>
            </div>
        </div>

        <TypeStep :offer="form" @updated="onUpdated" v-show="isCurrent(STEPS.TYPE)" />

        <CategoryStep :offer="form" @updated="onUpdated" @goToPrevious="goPrevious()" v-show="isCurrent(STEPS.CATEGORY) && !isAuction" />

        <!-- Auctions are directly associated to an industry, not to a child category -->
        <IndustryStep :offer="form" @updated="onUpdated" @goToPrevious="goPrevious()" v-show="isCurrent(STEPS.CATEGORY) && isAuction" />

        <DescriptionStep :offer="form" @updated="onUpdated" @goToPrevious="goPrevious()" v-show="isCurrent(STEPS.DESCRIPTION) && !isAuction" />

        <!-- Specific description step for auctions -->
        <DescriptionForAuctionStep :offer="form" @updated="onUpdated" @goToPrevious="goPrevious()" v-show="isCurrent(STEPS.DESCRIPTION) && isAuction" />

        <MediaStep :offer="form" @updated="onMediaSelected" @goToPrevious="goPrevious()" v-show="isCurrent(STEPS.PHOTOS)">
            <FeedbackModal :state="state" slot="modalFeedback" message="Uploading photos might take time. Please be patient!" />
        </MediaStep>

        <PaymentStep
            v-if="isMachine"
            :offer="form"
            @paymentMethodSelected="onPaymentMethodSelected"
            @goToPrevious="goPrevious()"
            v-show="isCurrent(STEPS.STATUS)"
            :active="isCurrent(STEPS.STATUS)"
        >
            <FeedbackModal :state="state" slot="modalFeedback" message="Your order is being processed. Please be patient!" />
        </PaymentStep>

        <!-- Specific payment step for auctions -->
        <PaymentForAuctionStep
            v-if="isAuction"
            @paymentMethodSelected="onPaymentMethodSelected"
            @goToPrevious="goPrevious()"
            v-show="isCurrent(STEPS.STATUS)"
        >
            <FeedbackModal :state="state" slot="modalFeedback" message="Your order is being processed. Please be patient!" />
        </PaymentForAuctionStep>
    </div>
</template>

<script>
  import {OfferType as OFFER_TYPES} from '../../../../../../Common/js/helpers/enums';

  import { mapActions } from 'vuex';
  import stateMixin from '../../../../../../Common/js/mixins/state';
  import dateHelpers from '../../../../../../Common/js/filters';

  import TypeStep from './Steps/Type';
  import CategoryStep from './Steps/Category';
  import IndustryStep from './Steps/Industry';
  import DescriptionStep from './Steps/Description';
  import DescriptionForAuctionStep from './Steps/DescriptionForAuction';
  import MediaStep from './Steps/Media';
  import PaymentStep from './Steps/Payment';
  import PaymentForAuctionStep from './Steps/PaymentForAuction';
  import FeedbackModal from './Steps/FeedbackModal';

  export default {
    mixins: [stateMixin],
    inject: ['Security', 'Router'],
    components: {
      TypeStep,
      CategoryStep, // Used for machines
      IndustryStep, // Used for auctions (select an industry, not a child category)
      DescriptionStep,
      DescriptionForAuctionStep, // Specific description step for auctions
      MediaStep,
      PaymentStep,
      PaymentForAuctionStep, // Specific payment step for auctions
      FeedbackModal,
    },
    constants: {
      OFFER_TYPES: OFFER_TYPES,
      STEPS: {
        TYPE: "Type",
        CATEGORY: "Category",
        DESCRIPTION: "Description",
        PHOTOS: "Photos",
        STATUS: "Status",
        CONFIRMATION: "Confirmation",
      },
      STATE: {
        INITIAL: 'INITIAL',
        PROCESSING: 'PROCESSING',
        FAILURE: 'FAILURE',
      },
    },

    props: {
      categoryUuid: {
        type: String,
        required: false,
        default: () => null,
      },
      type: {
        type: String,
        required: false,
        default: () => OFFER_TYPES.MACHINE,
      },
      used: {
        type: Boolean,
        required: false,
        default: () => true,
      },
    },

    data() {
      return {
        steps: [
          this.STEPS.TYPE,
          this.STEPS.CATEGORY,
          this.STEPS.DESCRIPTION,
          this.STEPS.PHOTOS,
          this.STEPS.STATUS,
          this.STEPS.CONFIRMATION,
        ],
        form: this.initialData(),
        currentStep: 0,
        lastPostedOffer: null,
        currentOffer: null,
      };
    },

    created() {
      if (!this.Security.isAuthenticated()) {
        throw new Error('User not authenticated');
      }

      // If type, used & categoryUuid were provided, move to description step directly:
      if (this.type && this.used !== null && this.categoryUuid) {
        this.currentStep = this.steps.indexOf(this.STEPS.DESCRIPTION);
      }
    },

    methods: {
      ...mapActions([
        'placeAnAd',
        'placeAnAdUpdatePayment',
      ]),

      onUpdated(data) {
        this.form = {
          ...this.form,
          ...data,
        };

        this.goNext();
      },

      onMediaSelected(data) {
        this.form = {
          ...this.form,
          ...data,
        };

        this.setState(this.STATE.PROCESSING);
        const payload = { ...this.form };
        payload.freeFields = this.normalizedFreeFields;
        payload.startsAt = dateHelpers.formatDateRFC3339(this.form.startsAt);
        payload.endsAt = dateHelpers.formatDateRFC3339(this.form.endsAt);



        this.placeAnAd({
          payload,
        }).then(this.onPlacedAdSuccess, this.onFailure);
      },

      onPlacedAdSuccess(placedAd) {
        this.currentOffer = placedAd.offer;
        this.setState(this.STATE.SUCCESS);


        if (placedAd.offer.premium && this.type === OFFER_TYPES.MACHINE) {
          // Redirect to confirmation without payment page:
          window.location = this.Router.generate('frontoffice_placed_ad_without_order_confirmation', {
            uuid: placedAd.offer.uuid,
          });
        } else {
          this.goNext();
        }
      },

      /**
       * On payment method selected, we submit the payload:
       */
      onPaymentMethodSelected(payment) {
        this.setState(this.STATE.PROCESSING);
        const payload = {
          type: this.type,
          payment
        };

        this.placeAnAdUpdatePayment({
          uuid: this.currentOffer.uuid,
          payload,
        }).then(this.onPaymentSuccess, this.onFailure);
      },

      onPaymentSuccess(placedAd) {
        if (placedAd.paymentUrl) {
          // Payment required, redirect to url:
          window.location = placedAd.paymentUrl;
          return;
        }

        // Redirect to confirmation without payment page:
        window.location = this.Router.generate('frontoffice_placed_ad_without_order_confirmation', {
          uuid: placedAd.offer.uuid,
        });
      },

      onFailure() {
        this.setState(this.STATE.FAILURE);
        setTimeout(this.resetInitialState, 2000);
      },

      isActive(step) {
        return step === this.currentStep;
      },

      isDone(step) {
        return step < this.currentStep;
      },

      goPrevious() {
        if (!this.hasPreviousStep()) {
          return;
        }

        this.currentStep--;
      },

      goNext() {
        if (!this.hasNextStep()) {
          return;
        }
        window.scrollTo({top:0});
        this.currentStep++;
      },

      goToStep(targetedStep) {
        // Cannot jump back if the targeted step isn't already done or active:
        if (!this.isDone(targetedStep) && !this.isActive(targetedStep)) {
            return;
        }

        this.currentStep = targetedStep;
      },

      hasNextStep() {
        return this.currentStep < this.steps.length - 1;
      },

      hasPreviousStep() {
        // If offer has already been saved, you can't go back (for now)
        return this.currentStep > 0 && this.currentOffer === null;
      },

      isCurrent(stepLabel) {
        return this.steps[this.currentStep] == stepLabel;
      },

      initialData() {
        const user = this.Security.getUser();

        return {
          type: this.type,
          used: this.used,
          categoryUuid: this.categoryUuid,
          quantity: 1,
          countryUuid: user.countryUuid,
          manufacturerUuids: [],
          years: [],
          price: null,
          reference: null,
          description: null,
          title: null,       // Required by auctions, null for other types
          originalUrl: null, // Optional for auctions, null for other types
          startsAt: null,    // Required by auctions, null for other types
          endsAt: null,      // Required by auctions, null for other types
          freeFields: {},
          media: [],
        };
      },
    },

    computed: {
      /**
       * Normalize free fields to conform to expected Payload in GraphQL mutation
       *
       * @see config/graphql/types/payloads/OfferFreeFieldPayload.types.yaml
       *
       * @returns {Array}
       */
      normalizedFreeFields() {
        let normalized = [];
        for (let uuid in this.form.freeFields) {
            normalized.push({
              uuid,
              value: this.form.freeFields[uuid],
            });
        }
        return normalized;
      },
      isAuction() { return this.form.type === this.OFFER_TYPES.AUCTION },
      isMachine() { return this.form.type === this.OFFER_TYPES.MACHINE },
    },
  };
</script>
