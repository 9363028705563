var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-bloc" }, [
      _c("h2", { staticClass: "form-bloc__title" }, [
        _vm._v("Please select a type for your classified ad"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "category-select" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("ul", { staticClass: "category-select__list" }, [
          _c("li", { staticClass: "category-select__item" }, [
            _c("div", { staticClass: "radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.choice,
                    expression: "choice",
                  },
                ],
                attrs: { type: "radio", name: "category", id: "used" },
                domProps: {
                  value: _vm.CHOICES.USED_MACHINE,
                  checked: _vm._q(_vm.choice, _vm.CHOICES.USED_MACHINE),
                },
                on: {
                  change: function ($event) {
                    _vm.choice = _vm.CHOICES.USED_MACHINE
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "used" } }, [
                _vm._v(_vm._s(_vm.choiceLabel(_vm.CHOICES.USED_MACHINE))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "category-select__item" }, [
            _c("div", { staticClass: "radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.choice,
                    expression: "choice",
                  },
                ],
                attrs: { type: "radio", name: "category", id: "new" },
                domProps: {
                  value: _vm.CHOICES.NEW_MACHINE,
                  checked: _vm._q(_vm.choice, _vm.CHOICES.NEW_MACHINE),
                },
                on: {
                  change: function ($event) {
                    _vm.choice = _vm.CHOICES.NEW_MACHINE
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "new" } }, [
                _vm._v(_vm._s(_vm.choiceLabel(_vm.CHOICES.NEW_MACHINE))),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "category-select" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("ul", { staticClass: "category-select__list" }, [
          _c("li", { staticClass: "category-select__item" }, [
            _c("div", { staticClass: "radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.choice,
                    expression: "choice",
                  },
                ],
                attrs: { type: "radio", name: "category", id: "auctions" },
                domProps: {
                  value: _vm.CHOICES.AUCTION,
                  checked: _vm._q(_vm.choice, _vm.CHOICES.AUCTION),
                },
                on: {
                  change: function ($event) {
                    _vm.choice = _vm.CHOICES.AUCTION
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "auctions" } }, [
                _vm._v(_vm._s(_vm.choiceLabel(_vm.CHOICES.AUCTION))),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "category-select mocked-element" }, [
        _vm._m(2),
        _vm._v(" "),
        _c("ul", { staticClass: "category-select__list" }, [
          _c("li", { staticClass: "category-select__item" }, [
            _c("div", { staticClass: "radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.choice,
                    expression: "choice",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "category",
                  id: "job",
                  disabled: "",
                },
                domProps: {
                  value: _vm.CHOICES.JOB,
                  checked: _vm._q(_vm.choice, _vm.CHOICES.JOB),
                },
                on: {
                  change: function ($event) {
                    _vm.choice = _vm.CHOICES.JOB
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "job" } }, [
                _vm._v(_vm._s(_vm.choiceLabel(_vm.CHOICES.JOB))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "category-select__item" }, [
            _c("div", { staticClass: "radio" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.choice,
                    expression: "choice",
                  },
                ],
                attrs: {
                  type: "radio",
                  name: "category",
                  id: "resume",
                  disabled: "",
                },
                domProps: {
                  value: _vm.CHOICES.RESUME,
                  checked: _vm._q(_vm.choice, _vm.CHOICES.RESUME),
                },
                on: {
                  change: function ($event) {
                    _vm.choice = _vm.CHOICES.RESUME
                  },
                },
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: "resume" } }, [
                _vm._v(_vm._s(_vm.choiceLabel(_vm.CHOICES.RESUME))),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "shadow-container__footer" }, [
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "inner-footer" }, [
        _c(
          "button",
          {
            staticClass: "button-next btn btn--primary",
            attrs: { disabled: !_vm.choice },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onSubmit()
              },
            },
          },
          [
            _vm._v("\n                Next\n                "),
            _c("i", { staticClass: "icon icon--next" }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "category-select__icon" }, [
      _c("i", { staticClass: "icon icon--machine" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "category-select__icon" }, [
      _c("i", { staticClass: "icon icon--auction" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "category-select__icon" }, [
      _c("i", { staticClass: "icon icon--job" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }