<template functional>
    <div class="shadow-container__footer">
        <hr>
        <div class="inner-footer">
            <button class="button-back btn btn--empty" @click.prevent="props.back()">
                <i class="icon icon--previous"></i>
                Back
            </button>
            <div class="payment">
                <span>SSL Secure Payment</span>
                <img src="/build/images/paypal.svg" alt="Paypal">
                <img src="/build/images/visa.svg" alt="Visa">
                <img src="/build/images/mastercard.svg" alt="Mastercard" class="mastercard">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
      functional: true,
      props: {
        back: {
          type: Function,
          required: true,
        }
      }
    }
</script>
