var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("select", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.value,
        expression: "value",
      },
    ],
    attrs: { multiple: _vm.multiple },
    on: {
      input: (newValue) => {
        _vm.$emit("input", newValue)
      },
      change: function ($event) {
        var $$selectedVal = Array.prototype.filter
          .call($event.target.options, function (o) {
            return o.selected
          })
          .map(function (o) {
            var val = "_value" in o ? o._value : o.value
            return val
          })
        _vm.value = $event.target.multiple ? $$selectedVal : $$selectedVal[0]
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }