/**
 * Execute given Function fn if newValue differs from oldValue
 *
 * @param {*} newValue
 * @param {*} oldValue
 * @param {Function} fn
 */
export const executeOnChange = (newValue, oldValue, fn) => {
  // We can't just compare arrays by using == or ===
  if (newValue instanceof Array && JSON.stringify(oldValue) === JSON.stringify(newValue)) {
    return;
  }

  if (newValue === oldValue) {
    return;
  }

  fn();
};
