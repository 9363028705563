<template>
    <div>
        <div v-if="loading">
            <i class="fa fa-spin fa-spinner"></i>
        </div>
        <select
            :id="id"
            v-model="value"
            v-show="!loading"
            ref="select2"
            :required="required"
            :multiple="multiple"
            :class="classes"
        >
            <option v-if="!required" value="">No country</option>
            <option v-for="country in orderedCountries" v-bind:value="country.uuid">
                {{ country.name }}
            </option>
        </select>
    </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import select2 from '../../mixins/select2';
  import { alphabetically } from '../../helpers/sort';

  export default {
    mixins: [select2],
    props: {
      // v-model
      value: {
        type: [Array, String], // Uuid or array of uuids
        required: false,
        default: () => {
          return this.multiple ? [] : null;
        },
      },
      id: {
        type: String,
        required: false,
      },
      classes: {
        type: [Array, Object, String],
        required: false,
        default: () => '',
      },
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
      multiple: {
        type: Boolean,
        required: false,
        default: false,
      },
      // To be used when embedding a select2 input in a modal. Provide the selector to the modal.
      // See https://select2.org/troubleshooting/common-problems
      dropdownParent: {
        type: String,
        required: false,
        default: null,
      },
    },

    data() {
      return {
        loading: true,
      };
    },

    computed: {
      ...mapState({
        countries: (state) => Object.values(state.common.geo.countries),
      }),

      ...mapGetters([
        'getZoneCountries',
      ]),
      orderedCountries() {
        return this.countries.sort(
          (a, b) => alphabetically(a.name, b.name),
        );
      },

    },

    mounted() {
      this.loadZones().then(() => {
        this.loading = false;

        setTimeout(() => this.$nextTick(this.selectize), 0);
      });
    },

    methods: {
      ...mapActions([
        'loadZones',
      ]),

      /**
       * @override select2 mixin
       */
      getSelect2Input() {
        return $(this.$refs['select2']);
      },

      selectize() {
        const vm = this;

        this.getSelect2Input()
          // init select2
          .select2({
            // https://select2.org/troubleshooting/common-problems
            dropdownParent: this.dropdownParent && $(this.dropdownParent),
          })
          // with initial value and trigger change
          .val(this.value)
          .trigger('change')
          // emit event on change.
          .on('change', vm.__select2_emit());
      }
    },

    watch: {
      value(newValue, oldValue) {
        this.__select2_watch(newValue, oldValue);
      },
    },
  }
</script>
