import getters from './getters';

const state = {
  geo: {
    // List of all countries (for country select-lists)
    countries: {},
    // List of all geographical zones and their countries
    zones: {},
  }
};

export default {
  state,
  getters,
  actions: {},
  mutations: {},
};
