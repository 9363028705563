<script>
  import Vue from 'vue';
  import Sentry from '../../Common/js/sentry';
  import { mapState } from 'vuex';

  // Services
  import Router from '../../Common/js/modules/service/Router';

  // Vue components
  import PlaceAnAdPipe from './modules/Offer/components/PlaceAnAd/PlaceAnAdPipe';
  import RequestPrice from './modules/Offer/components/RequestPrice';
  import SubscriptionForm from './modules/Member/components/form/SubscriptionForm';
  import CategoriesTreeForm from './modules/Member/components/form/CategoriesTreeForm';
  import UpgradeAdToPremiumButton from './modules/Offer/components/PremiumUpgrade/UpgradeAdToPremiumButton';
  import Security from '../../Common/js/modules/service/Security';
  import OrderAdPremiumUpgrade from './modules/Offer/components/PremiumUpgrade/OrderAdPremiumUpgrade';
  import OrderPremiumPack from './modules/Member/components/Account/OrderPremiumPack';
  import AddCategorySubscriptionsToMemberForm from './modules/Member/components/form/AddCategorySubscriptionsToMemberForm';
  import EditYourClassifiedAdPage from './modules/Offer/pages/EditYourClassifiedAdPage';
  import SubscribeToCategory from './modules/Member/components/SubscribeToCategory';

  export default {
    components: {
      PlaceAnAdPipe,
      RequestPrice,
      SubscriptionForm,
      CategoriesTreeForm,
      UpgradeAdToPremiumButton,
      OrderAdPremiumUpgrade,
      OrderPremiumPack,
      AddCategorySubscriptionsToMemberForm,
      EditYourClassifiedAdPage,
      SubscribeToCategory,
    },

    props: {
      user: {
        type: Object,
        required: false, // Not essentially authenticated on FO
      },
    },

    data() {
      return {
        onReadyListeners: []
      };
    },

    computed: {
      ...mapState({
        localStorageReady: state => state.plugins.localStorageReady,
      }),

      ready() {
        return this.localStorageReady;
      }
    },

    watch: {
      ready(isReady) {
        if (isReady) {
          this.onReadyListeners.forEach(listener => listener());
        }
      },
    },

    methods: {
      /**
       * Register a callback to execute when App is fully ready
       */
      registerOnReadyListener(listener) {
        this.onReadyListeners.push(listener);
      },
    },

    // Component communication, dep injection & Global event bus pattern:
    // - https://alligator.io/vuejs/component-communication/
    // - https://alligator.io/vuejs/global-event-bus/
    // - https://vuejs.org/v2/guide/components.html#Non-Parent-Child-Communication
    provide() {
      return {
        /**
         * Allow to access the App in any component, to check the ready state or add a listener if required.
         * This might be useful to ensure the local storage is ready, so Vue components can start.
         * Otherwise, components can load data in the store before local storage
         * plugin is initiated, which clears the store by calling CLEAR_ENTITIES.
         * */
        App: this,
        // Event bus for simple communication between components
        EventBus: new Vue(),
        // JS Router based on FOSJsRoutingBundle
        Router,
        // Global security object we can access into any component
        Security: new Security(this.user),
      };
    },

    created() {
      if (this.user) {
        Sentry.setUser(this.user.email, this.user.uuid);
      }
    }
  }
</script>
