export default {
  getCountry(state) {
    return (countryUuid) => state.geo.countries[countryUuid] || null;
  },
  getZone(state) {
    return (zoneUuid) => state.geo.zones[zoneUuid] || null;
  },
  getZoneCountries(state, getters, rootState, rootGetters) {
    return (zoneUuid) => rootGetters.associated(`common.geo.zones.${zoneUuid}.countries`, 'common.geo.countries');
  },
};
