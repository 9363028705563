var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-bloc form-bloc--striped" }, [
      _c("h2", { staticClass: "form-bloc__title" }, [
        _vm._v("Please fill in the description of your classified ad"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-bloc__row" }, [
        _c("div", [
          _c("label", [_vm._v("Category")]),
          _vm._v(" "),
          _vm.offer.categoryUuid
            ? _c("ul", { staticClass: "breadcrumb" }, [
                _c("li", { staticClass: "breadcrumb__item" }, [
                  _vm._v(_vm._s(_vm.categoryName)),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-bloc__row" }, [
        _c("div", [
          _c("label", [_vm._v("Title")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.title,
                expression: "form.title",
              },
            ],
            staticClass: "form-control",
            class: {
              "input-error": _vm.fieldError("title"),
            },
            attrs: {
              type: "text",
              maxlength: _vm.maxLengths.TITLE,
              id: "auction-information-title",
            },
            domProps: { value: _vm.form.title },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "title", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.fieldError("title")
            ? _c("div", { staticClass: "error" }, [_vm._v("Title is required")])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-bloc__row" }, [
        _c(
          "div",
          [
            _c("label", [_vm._v("Country")]),
            _vm._v(" "),
            _c("SelectCountry", {
              attrs: { classes: "form-control" },
              model: {
                value: _vm.form.countryUuid,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "countryUuid", $$v)
                },
                expression: "form.countryUuid",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _c("label", { attrs: { for: "auction-information-reference" } }, [
            _vm._v("Your reference"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.reference,
                expression: "form.reference",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              maxlength: _vm.maxLengths.SELLER_REFERENCE,
              id: "auction-information-reference",
            },
            domProps: { value: _vm.form.reference },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "reference", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", [_vm._v("Dates")]),
          _vm._v(" "),
          _c("div", { staticClass: "input-daterange" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.startsAt,
                  expression: "form.startsAt",
                },
              ],
              staticClass: "form-control",
              class: {
                "input-error":
                  _vm.fieldError("startsAt") || _vm.fieldError("dates"),
              },
              attrs: {
                type: "text",
                placeholder: "from",
                "data-field": "startsAt",
              },
              domProps: { value: _vm.form.startsAt },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "startsAt", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.endsAt,
                  expression: "form.endsAt",
                },
              ],
              ref: "inputEndsAt",
              staticClass: "form-control",
              class: {
                "input-error":
                  _vm.fieldError("endsAt") || _vm.fieldError("dates"),
              },
              attrs: {
                type: "text",
                placeholder: "to",
                "data-field": "endsAt",
              },
              domProps: { value: _vm.form.endsAt },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "endsAt", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _vm.fieldError("endsAt") ||
        _vm.fieldError("startsAt") ||
        _vm.fieldError("dates")
          ? _c("ul", { staticClass: "date-errors" }, [
              _vm.fieldError("startsAt")
                ? _c("li", { staticClass: "error" }, [
                    _vm._v("Start date is required"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.fieldError("endsAt")
                ? _c("li", { staticClass: "error" }, [
                    _vm._v("End date is required"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.fieldError("dates")
                ? _c("li", { staticClass: "error" }, [
                    _vm._v("Start date cannot be greater than end date"),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", [
          _c("label", [_vm._v("External link")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.originalUrl,
                expression: "form.originalUrl",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              maxlength: _vm.maxLengths.ORIGINAL_URL,
              id: "auction-information-url",
              placeholder: "https://",
            },
            domProps: { value: _vm.form.originalUrl },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "originalUrl", $event.target.value)
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-bloc__row form-bloc__row--vertical" }, [
        _c("label", { attrs: { for: "auction-information-description" } }, [
          _vm._v("Description"),
        ]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.description,
              expression: "form.description",
            },
          ],
          staticClass: "form-control",
          attrs: { id: "auction-information-description" },
          domProps: { value: _vm.form.description },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "description", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "shadow-container__footer" }, [
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "inner-footer" }, [
        _c(
          "button",
          {
            staticClass: "button-back btn btn--empty",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("goToPrevious")
              },
            },
          },
          [
            _c("i", { staticClass: "icon icon--previous" }),
            _vm._v("\n                Back\n            "),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button-next btn btn--primary",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.onSubmit()
              },
            },
          },
          [
            _vm._v("\n                Next\n                "),
            _c("i", { staticClass: "icon icon--next" }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }