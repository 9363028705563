import { schema } from 'normalizr';
import { delete as del, merge } from 'dot-prop-immutable';

export function mergeLists(previousList, newList) {
  let merged = previousList;
  Object.keys(newList).forEach((id) => {
    // Merge with existing item or simply add if not existing
    merged = merge(merged, id, newList[id]);
  });
  return merged;
}

const baseDefinitionOptions = {
  idAttribute: 'uuid',
};

/* ********************************
 * Messaging
 ******************************** */

const Message = new schema.Entity('messages', {}, baseDefinitionOptions);

const Channel = new schema.Entity('channels', {
  messages: [Message],
}, baseDefinitionOptions);

// Describe how to store the messages in Vuex store
Channel.store = (rootState, items) => {
  rootState.messaging.channels = mergeLists(rootState.messaging.channels, items);
};

Message.define({
  channel: Channel, // Cyclic dependency, useful for updating channel messages when retrieving one.
});

// Clone the message, without channel reference, as we don't need/want to access the channel from a message.
Message.postProcess = (message) => del(message, 'channel');

Message.store = (rootState, items) => {
  rootState.messaging.messages = mergeLists(rootState.messaging.messages, items);
};

/* ********************************
 * Logging
 ******************************** */

const AuditLog = new schema.Entity('auditLogs', {
  channels: [Channel],
}, baseDefinitionOptions);

AuditLog.store = (rootState, items) => {
  rootState.logging.auditLogs = mergeLists(rootState.logging.auditLogs, items);
};
// Decode audit log context provided as JSON string:
AuditLog.postProcess = (log) => {
  log.context = typeof log.context === 'string' ? JSON.parse(log.context) : log.context;

  return log;
};


/* ********************************
 * Premium packs (in an offer context)
 ******************************** */

const PremiumPack = new schema.Entity('premiumPacks', {}, baseDefinitionOptions);

PremiumPack.store = (rootState, items) => {
  rootState.offer.premiumPacks = mergeLists(rootState.offer.premiumPacks, items);
};

/* ********************************
 * Paid premium packs (in a user context)
 ******************************** */

const PaidPremiumPack = new schema.Entity('paidPremiumPacks', {}, baseDefinitionOptions);

PaidPremiumPack.store = (rootState, items) => {
  rootState.user.paidPremiumPacks = mergeLists(rootState.user.paidPremiumPacks, items);
};

/* ********************************
 * Users
 ******************************** */

const User = new schema.Entity('users', {
  activePremiumPack: PaidPremiumPack,
}, baseDefinitionOptions);

User.store = (rootState, items) => {
  rootState.user.users = mergeLists(rootState.user.users, items);
};

/* ********************************
 * Geographical zones & countries
 ******************************** */

const Country = new schema.Entity('countries', {}, baseDefinitionOptions);

const Zone = new schema.Entity('zones', {
  countries: [Country],
}, baseDefinitionOptions);

Zone.store = (rootState, items) => {
  rootState.common.geo.zones = mergeLists(rootState.common.geo.zones, items);
};

Country.define({
  zone: Zone,
});

Country.store = (rootState, items) => {
  rootState.common.geo.countries = mergeLists(rootState.common.geo.countries, items);
};

/* ********************************
 * Categories
 ******************************** */

const Category = new schema.Entity('categories', {}, baseDefinitionOptions);
Category.define({
  parent: Category,
  children: [Category],
});
Category.store = (rootState, items) => {
  rootState.offer.categories = mergeLists(rootState.offer.categories, items);
};
Category.clear = (rootState) => {
  rootState.offer.categories = {};
};



/* ********************************
 * Members
 ******************************** */

const Memo = new schema.Entity('memos', {}, baseDefinitionOptions);


const Member = new schema.Entity('members', {
  logs: [AuditLog],
  user: User,

  country: Country,
  industries: [Category],
}, baseDefinitionOptions);

Memo.define({
  owner: Member,
  member: Member,
});

Member.define({
  memos: [Memo],
});

Memo.store = (rootState, items) => {
  rootState.member.memos = mergeLists(rootState.member.memos, items);
}
Member.store = (rootState, items) => {
  rootState.member.members = mergeLists(rootState.member.members, items);
};



/* ********************************
 * Manufacturers
 ******************************** */

const Manufacturer = new schema.Entity('manufacturers', {}, baseDefinitionOptions);

Manufacturer.store = (rootState, items) => {
  rootState.offer.manufacturers = mergeLists(rootState.offer.manufacturers, items);
};

/* ********************************
 * Media
 ******************************** */

// Media (pictures, catalogues, etc.)
const Media = new schema.Entity('media', {}, baseDefinitionOptions);

Media.store = (rootState, items) => {
  rootState.offer.media = mergeLists(rootState.offer.media, items);
};

/* ********************************
 * Offers
 ******************************** */

const Offer = new schema.Entity('offers', {
  channels: [Channel],
  logs: [AuditLog],
  country: Country,
  zone: Zone,
  manufacturers: [Manufacturer],
  category: Category,
  secondaryCategories: [Category],
  media: [Media],
  seller: Member,
  reseller: Member,
  availabilityBy: Member,
  status: {
    createdBy: Member,
    updatedBy: Member,
    lastCheckBy: Member,
    onTopBy: Member,
    onHomeBy: Member,
    hotDealBy: Member,
  },
}, baseDefinitionOptions);

Offer.store = (rootState, items) => {
  rootState.offer.offers = mergeLists(rootState.offer.offers, items);
};

const Schema = {
  Message,
  Channel,
  AuditLog,
  User,
  Member,
  Memo,
  Country,
  Category,
  Zone,
  PremiumPack,
  PaidPremiumPack,
  Media,
  Manufacturer,
  Offer,
};

export default Schema;
