<template>
    <div>
        <SelectPremiumOptionForAuction @input="onSelectedOption" suggest-free />

        <slot name="modalFeedback"></slot>

        <SelectPremiumFooter :back="back" />
    </div>
</template>

<script>
  import SelectPremiumOptionForAuction from '../../Premium/SelectPremiumOptionForAuction';
  import SelectPremiumFooter from '../../Premium/SelectPremiumFooter';

  const EVENT_PAYMENT_METHOD_SELECTED = 'paymentMethodSelected';

  export default {
    components: { SelectPremiumOptionForAuction, SelectPremiumFooter },
    methods: {
      onSelectedOption(selectedOption) {
        this.$emit(EVENT_PAYMENT_METHOD_SELECTED, selectedOption);
      },

      back() {
        this.$emit('goToPrevious');
      },
    },
  };
</script>
