var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "form-bloc" }, [
        _c("h2", { staticClass: "form-bloc__title" }, [
          _vm._v("Please select pictures for your classified ad"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "drop" }, [
          _c("p", { staticClass: "drop__title" }, [
            _vm._v("Photos are very important to promote your equipment"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "drop__zone" }, [
            _c("div", { staticClass: "drop__target" }, [
              _c("input", {
                staticClass: "input-file",
                attrs: {
                  type: "file",
                  multiple: "",
                  accept: _vm.acceptedMimeTypes(),
                  disabled: _vm.preventUpload,
                },
                on: { change: _vm.onChange },
              }),
              _vm._v(" "),
              _c("i", { staticClass: "icon icon--upload" }),
              _vm._v(" "),
              _c("div", [
                _c("span", [_vm._v("Drop files here or")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    class: {
                      "btn--primary": !_vm.preventUpload,
                      "btn-warning": _vm.preventUpload,
                    },
                  },
                  [
                    !_vm.preventUpload
                      ? _c("span", [_vm._v("Browse")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.preventUpload
                      ? _c("span", { staticClass: "fa fa-times-circle" }, [
                          _vm._v(" "),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "drop__help" }, [
              _c("p", [_vm._v("File formats accepted : GIF, JPEG, JPG ")]),
              _vm._v(" "),
              _c("p", [_vm._v("Automatic photo resizing")]),
              _vm._v(" "),
              _c("p", [_vm._v("Recommended size : above 800 pixels width")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Maximum size per file : " +
                    _vm._s(_vm.UPLOAD_LIMITATIONS.FILESIZE / 1000000) +
                    " Mo"
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "photo-list" },
          [
            _c("p", { staticClass: "photo-list__title" }, [
              _vm._v(
                "You can post up to " +
                  _vm._s(_vm.UPLOAD_LIMITATIONS.FILE_COUNT) +
                  " photos. If you don't have photos yet, you can add them later."
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.uploadLimitReached,
                    expression: "uploadLimitReached",
                  },
                ],
                staticClass: "photo-list__upload-limit-error",
              },
              [
                _vm._v(
                  "\n                You have reached the maximum number of files (" +
                    _vm._s(_vm.UPLOAD_LIMITATIONS.FILE_COUNT) +
                    ") or single file limit (" +
                    _vm._s(_vm.UPLOAD_LIMITATIONS.FILESIZE / 1000000) +
                    " Mo) or total file size (" +
                    _vm._s(_vm.UPLOAD_LIMITATIONS.TOTAL_FILESIZE / 1000000) +
                    " Mo).\n                Please, remove some of your files before trying to add new ones.\n            "
                ),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.thumbnails, function (thumbnail) {
              return _c(
                "div",
                {
                  staticClass: "photo-list__item",
                  class: {
                    "photo-list__item--video": _vm.isVideo(thumbnail),
                    "photo-list__item--pdf": _vm.isPdf(thumbnail),
                  },
                },
                [
                  _c("div", {
                    staticClass: "image",
                    style: "background: url('" + thumbnail.src + "');",
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "image-name" }, [
                    _vm._v(_vm._s(thumbnail.name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.removeMedia(thumbnail.key)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "icon icon--delete" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Delete"),
                      ]),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("p", { staticClass: "photo-list__title" }, [
          _vm._v(
            "Total: " +
              _vm._s(_vm.fileCount) +
              " file" +
              _vm._s(_vm.fileCount > 1 ? "s" : "") +
              " / " +
              _vm._s(_vm.displayTotalFileSize) +
              " (Mo)"
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._t("modalFeedback"),
      _vm._v(" "),
      _c("div", { staticClass: "shadow-container__footer" }, [
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "inner-footer" }, [
          _c(
            "button",
            {
              staticClass: "button-back btn btn--empty",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("goToPrevious")
                },
              },
            },
            [
              _c("i", { staticClass: "icon icon--previous" }),
              _vm._v("\n                Back\n            "),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "button-next btn btn--primary",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit()
                },
              },
            },
            [
              _vm._v("\n                Next\n                "),
              _c("i", { staticClass: "icon icon--next" }),
            ]
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }