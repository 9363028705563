<template>
    <div>
        <div class="form-bloc">
            <h1 class="form-bloc__title">Please choose your option</h1>
            <div class="pricing">

                <!-- Free option -->
                <div v-if="suggestFree" class="pricing__item">
                    <div class="pricing__header">
                        <span class="title">Basic Classified Ad</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>5 photos</span>
                    </div>
                    <div class="pricing__footer">
                        <span class="price">Free</span>
                        <button class="btn btn--primary" @click.prevent="onSubmitFree">Choose this plan</button>
                    </div>
                </div>

                <!-- Single Premium option-->
                <div v-if="suggestSinglePremium" class="pricing__item">
                    <div class="pricing__header">
                        <span class="title">Premium Classified Ad</span>
                        <div class="subtitle">Premium status ensures maximum exposure for your product</div>
                        <img src="/build/images/premium.png" alt="">
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Your Premium Ad is validated immediately</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Unlimited photos</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Unlimited PDF files</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Unlimited videos</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Your product is at the top of search listings</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Your Premium Ad is published on our Newsletter</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Your product will be online until sold</span>
                    </div>
                    <div class="pricing__footer">
                            <span class="price">
                                Only 39 euros for 1 year
                                <span>(€3.25 per month)</span>
                            </span>
                        <button class="btn btn--premium" @click.prevent="onSubmitSingle">Choose this plan</button>
                    </div>
                </div>

                <!-- Premium pack option -->
                <div class="pricing__item pricing__item--package">
                    <div class="pricing__header">
                        <span class="title">Special packages for professional</span>
                        <div class="subtitle">Need to place more than 10 Premium Classified Ads ?</div>
                        <img src="/build/images/premium.png" alt="">
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Special package price</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Your premium ad is validated immediately</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Unlimited photos, PDF files & videos</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Your product is at the top of the search listings</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Inclusion of your Ads in our Weekly Newsletter</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Our system automatically downloads and updates your offers, saving you time and money <sup class="asterisk">*</sup></span>
                    </div>
                    <div class="pricing__offer" v-for="pack in premiumPacks">
                        <div class="radio">
                            <input
                                type="radio"
                                name="pricing-choice"
                                :id="pack.uuid"
                                :value="pack.uuid"
                                v-model="selectedPack"
                            />
                            <label :for="pack.uuid">
                                {{ numberOfAds(pack) }} Premium Ads
                                <span v-if="pack.numberOfAds">€{{ pricePerAdAndMonth(pack) }} per Ad/month</span>
                            </label>
                        </div>
                        <span class="price">€ {{ parseInt(pack.price) }}</span>
                    </div>
                    <div class="pricing__footer">
                        <button class="btn btn--premium" :disabled="!selectedPack" @click.prevent="onSubmitPack">Choose this plan</button>
                        <p class="conditions">
                            <span>*</span>
                            Feel free to <a :href="contactUsUri">contact us</a> for more information on these services, or if you have any questions
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import routes from '../../../../modules/Common/routes';
  import PremiumOptionSelection from './../Premium/PremiumOptionSelection';

  export default {
    inject: ['Security', 'Router'],
    props: {
      suggestFree: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      suggestSinglePremium: {
        type: Boolean,
        required: false,
        default: () => false,
      },
    },

    data() {
      return {
        selectedPack: null,
      };
    },

    created() {
      this.loadPremiumPacks();
    },

    methods: {
      ...mapActions([
        'loadPremiumPacks',
      ]),

      onSubmitFree() {
        this.$emit('input', { method: PremiumOptionSelection.FREE });
      },
      onSubmitSingle() {
        this.$emit('input', { method: PremiumOptionSelection.SINGLE_PREMIUM_AD });
      },
      onSubmitPack() {
        this.$emit('input', { method: PremiumOptionSelection.PURCHASE_PACK, packUuid: this.selectedPack });
      },

      numberOfAds(pack) {
        return pack.numberOfAds || 'Unlimited';
      },

      pricePerAdAndMonth(pack) {
        if (!pack.numberOfAds) {
          return null;
        }

        return (Math.round(((pack.price / 12) / pack.numberOfAds) * 10) / 10).toFixed(2);
      },
    },

    computed: {
      ...mapState({
        premiumPacks: state => state.offer.premiumPacks,
      }),
      contactUsUri() {
        return this.Router.generate(routes.CONTACT);
      },
    },
  };
</script>
