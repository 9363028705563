<template>
    <div>
        <div class="form-bloc">
            <h2 class="form-bloc__title">Please select pictures for your classified ad</h2>
            <div class="drop">
                <p class="drop__title">Photos are very important to promote your equipment</p>
                <div class="drop__zone">
                    <div class="drop__target">
                        <input type="file" multiple @change="onChange" :accept="acceptedMimeTypes()" class="input-file" :disabled="preventUpload">
                        <i class="icon icon--upload"></i>
                        <div>
                            <span>Drop files here or</span>
                            <button class="btn" :class="{'btn--primary': !preventUpload, 'btn-warning': preventUpload}">
                                <span v-if="!preventUpload">Browse</span>
                                <span v-if="preventUpload" class="fa fa-times-circle">&nbsp;</span>
                            </button>
                        </div>
                    </div>
                    <div class="drop__help">
                        <p>File formats accepted : GIF, JPEG, JPG </p>
                        <p>Automatic photo resizing</p>
                        <p>Recommended size : above 800 pixels width</p>
                        <p>Maximum size per file : {{ UPLOAD_LIMITATIONS.FILESIZE / 1000000 }} Mo</p>
                    </div>
                </div>
            </div>
            <hr>
            <div class="photo-list">
                <p class="photo-list__title">You can post up to {{ UPLOAD_LIMITATIONS.FILE_COUNT }} photos. If you don't have photos yet, you can add them later.</p>
                <p class="photo-list__upload-limit-error" v-show="uploadLimitReached">
                    You have reached the maximum number of files ({{ UPLOAD_LIMITATIONS.FILE_COUNT }}) or single file limit ({{ UPLOAD_LIMITATIONS.FILESIZE / 1000000 }} Mo) or total file size ({{ UPLOAD_LIMITATIONS.TOTAL_FILESIZE / 1000000 }} Mo).
                    Please, remove some of your files before trying to add new ones.
                </p>
                <div class="photo-list__item" v-for="thumbnail in thumbnails" :class="{
                    'photo-list__item--video': isVideo(thumbnail),
                    'photo-list__item--pdf': isPdf(thumbnail),
                }">
                    <div class="image" :style="'background: url(\'' + thumbnail.src + '\');'"></div>
                    <span class="image-name">{{ thumbnail.name }}</span>
                    <button @click="removeMedia(thumbnail.key)">
                        <i class="icon icon--delete"></i>
                        <span class="sr-only">Delete</span>
                    </button>
                </div>
            </div>
            <p class="photo-list__title">Total: {{ fileCount }} file{{ fileCount > 1 ? 's' : '' }} / {{ displayTotalFileSize }} (Mo)</p>
        </div>
        <slot name="modalFeedback"></slot>
        <div class="shadow-container__footer">
            <hr>
            <div class="inner-footer">
                <button class="button-back btn btn--empty" @click.prevent="$emit('goToPrevious')">
                    <i class="icon icon--previous"></i>
                    Back
                </button>
                <button class="button-next btn btn--primary" @click.prevent="onSubmit()">
                    Next
                    <i class="icon icon--next"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications
   */
  export default {
    constants: {
      MIME_TYPES: [
        'image/*', // Just enable pictures for now
      ],
      IMAGE_SOURCES: {
        pdf: '/build/images/file.7f0f1666.png',
        video: '/build/images/placeholder.jpg',
      },
      UPLOAD_LIMITATIONS: {
        FILE_COUNT: 10, // Max number of uploadable files
        FILESIZE: 10000000, // Max size per file in octets = 10Mo
        TOTAL_FILESIZE: 100000000, // Maximum total file size in octets = 100 Mo
      },
    },
    props: {
      offer: {
        type: Object,
        required: true,
      },
    },
    data: function() {
      return {
        files: [],
        uploadLimitReached: false,
      };
    },
    created() {
      this.files = this.offer.media;
    },
    watch: {
      offer: function (val) {
        this.files = val.media;
      },
    },
    methods: {
      onSubmit() {
        this.$emit('updated', { media: this.files });
      },
      onChange(e) {
        /** @var FileList fileList */
        const fileList = e.target.files;
        for (let i = 0; fileList.length && i < fileList.length; i++) {
          const file = fileList[i];
          if (!this.fileCanBeUploaded(file)) {
            this.setUploadLimitReached();
            return;
          }
          this.files.push(file);
        }
      },
      removeMedia(key) {
        let files = this.files;
        files.splice(key, 1);
        this.files = files;
      },
      acceptedMimeTypes() {
        return this.MIME_TYPES.join(',');
      },
      getImageSrc(file) {
        if (file.type.match(/^image/)) {
          return window.URL.createObjectURL(file);
        }
        if (file.type.match(/^video/)) {
          return this.IMAGE_SOURCES.video;
        }
        if (file.type.match(/^application\/pdf$/)) {
          return this.IMAGE_SOURCES.pdf;
        }
      },
      isPdf(file) {
        return file.type.match(/^application\/pdf$/);
      },
      isVideo(file) {
        return file.type.match(/^video/);
      },
      fileCanBeUploaded(file) {
        if (file.size > this.UPLOAD_LIMITATIONS.FILESIZE) {
          return false;
        }
        if (this.fileCount + 1 > this.UPLOAD_LIMITATIONS.FILE_COUNT) {
          return false;
        }
        if (this.totalFileSize + file.size > this.UPLOAD_LIMITATIONS.TOTAL_FILESIZE) {
          return false;
        }

        return true;
      },
      setUploadLimitReached() {
        this.uploadLimitReached = true;
        setTimeout(() => {
          this.uploadLimitReached = false;
        }, 5000);
      },
    },
    computed: {
      thumbnails() {
        const thumbnails = [];
        for(let i = 0; i <this.files.length; i++) {
          const file = this.files[i];
          thumbnails.push({
            key: i,
            name: file.name,
            type: file.type,
            src: this.getImageSrc(file),
          });
        }

        return thumbnails;
      },
      fileCount() {
        return this.files.length;
      },
      totalFileSize() {
        return this.files.reduce((totalFileSize, file, currentIndex, array) => {
          return totalFileSize + file.size;
        }, 0);
      },
      displayTotalFileSize() {
        // Convert total size to Mega octets and round by 2
        return (this.totalFileSize/1000000).toFixed(2);
      },
      preventUpload() {
        return this.fileCount === this.UPLOAD_LIMITATIONS.FILE_COUNT;
      },
    },
  };
</script>

<style lang="scss" scoped>
    .drop__target {

        .input-file {
            opacity: 0; /* invisible but it's there! */
            width: 100%;
            height: 170px;
            position: absolute;
            cursor: pointer;
        }
    }
</style>
