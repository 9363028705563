const moment = require('moment');

/**
 * Some Vue.js custom filters (https://vuejs.org/v2/guide/filters.html)
 */
export default {
  capitalize: (value) => {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
  pluralize: (value) => {
    if (!value) return '';
    // Unimplemented yet: handle plural exceptions when the need arises
    return value + 's';
  },
  nl2br: (value) => {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br/>')
  },
  /**
   * Given a date, return it formatted according to format
   *
   * @param {Date|String|null} value
   * @param {String|null}      format
   *
   * @returns {String|null}
   */
  formatDate: (value, format = 'DD MMM YYYY') => {
    if (!value) {
      return null;
    }
    const date = moment(value);
    return date.format(format);
  },

  /**
   * Given a date, return it formatted in a RFC3339-compliant format. This is the format expected by GraphQL endpoints
   * for DateTime-typed data.
   *
   * @param {Date|String|null} value
   *
   * @returns {String|null} Eg "2018-03-22T09:37:52+00:00"
   */
  formatDateRFC3339: (value) => {
    if (!value) {
      return null;
    }

    const date = moment("string" == typeof value ? Date.parse(value) : value);

    return date.format();
  }
};
