var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.authenticated
      ? _c("div", { staticClass: "product-contact__login" }, [
          _c(
            "a",
            {
              staticClass: "btn",
              class: {
                "btn--premium": _vm.premium,
                "btn--auction": _vm.auction,
                "btn--primary": !_vm.premium,
              },
              attrs: { href: _vm.loginUri },
            },
            [_vm._v("Contact the seller")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasSucceeded,
            expression: "hasSucceeded",
          },
        ],
        staticClass: "has-succeeded",
      },
      [_c("span", [_vm._v("Your message has been sent to the seller")])]
    ),
    _vm._v(" "),
    _vm.authenticated && !_vm.hasSucceeded
      ? _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmit.apply(null, arguments)
              },
            },
          },
          [
            _vm.hasFailed && !_vm.errors.content
              ? _c("div", { staticClass: "has-error" }, [
                  _vm._v("An error occurred, please retry."),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group",
                class: { "has-error": !!_vm.errors.content },
              },
              [
                _c(
                  "label",
                  {
                    attrs: {
                      for:
                        "request-price-content-" +
                        _vm.uuid +
                        (_vm.mobile && "-mobile"),
                    },
                  },
                  [_vm._v("Your message")]
                ),
                _vm._v(" "),
                !_vm.isProcessing
                  ? _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.content,
                          expression: "form.content",
                        },
                      ],
                      staticClass: "form-control",
                      class: {
                        "has-error": !!_vm.errors.content,
                        "has-succeeded": _vm.hasSucceeded,
                        "form-control--auction": _vm.auction,
                        "form-control--premium": _vm.premium,
                        "form-control--primary": !_vm.premium,
                      },
                      attrs: {
                        id:
                          "request-price-content-" +
                          _vm.uuid +
                          (_vm.mobile && "-mobile"),
                      },
                      domProps: { value: _vm.form.content },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "content", $event.target.value)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.errors.content
                  ? _c("span", { staticClass: "help-block" }, [
                      _vm._v(_vm._s(_vm.errors.content)),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "text-center" }, [
                  _vm.isProcessing
                    ? _c("span", { staticClass: "fa fa-spin fa-spinner" })
                    : _vm._e(),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox" }, [
              !_vm.auction
                ? _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.subscribe,
                          expression: "form.subscribe",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.form.subscribe)
                          ? _vm._i(_vm.form.subscribe, null) > -1
                          : _vm.form.subscribe,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.form.subscribe,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.form,
                                  "subscribe",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "subscribe",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "subscribe", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(
                      " Send me email alerts for similar machines\n            "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn",
                class: {
                  "btn--fixed": _vm.mobile,
                  "btn--premium": _vm.premium,
                  "btn--auction": _vm.auction,
                  "btn--primary": !_vm.premium,
                },
              },
              [
                _c("button", { attrs: { type: "submit" } }, [
                  _vm._v(
                    "\n                Click to Contact the Seller\n            "
                  ),
                ]),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }