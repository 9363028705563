<template>
    <div class="account-filter__section classified-ads-filters__section">
        <div class="fa fa-spin fa-spinner" v-if="loading" />
        <ul v-show="!loading">
            <li class="filter-group">
                <label for="filter-classified-ad-industry">Industry :</label>
                <select
                    @input="setLevel2Categories($event.target.value)"
                    v-model="selectedIndustry"
                    class="form-control input-sm"
                    id="filter-classified-ad-industry"
                >
                    <option :value="null">All industries</option>
                    <option
                        v-for="industry in myIndustries"
                        :value="industry.uuid"
                    >
                        {{ industry.name }} ({{ getCount(industry.uuid) }})
                    </option>
                </select>
                <input
                    type="hidden"
                    name="classified_ads_filters[categoryUuid]"
                    :value="selectedCategory"
                    data-target-form="classified_ads_filters_form"
                    ref="formCategoryInput"
                />
            </li>
            <li
                class="filter-group"
                v-show="industryFilterSelected"
            >
                <label for="filter-classified-ad-category">Category :</label>
                <select
                    @input="setLevel3Categories($event.target.value)"
                    v-model="selectedLevel2Category"
                    class="form-control input-sm"
                    id="filter-classified-ad-category"
                >
                    <option :value="null">Select a category</option>
                    <option
                        v-for="category in level2Categories"
                        :value="category.uuid"
                    >
                        {{ category.name }} ({{ getCount(category.uuid) }})
                    </option>
                </select>
            </li>
            <li
                class="filter-group"
                v-show="level2CategoryFilterSelected"
            >
                <label for="filter-classified-ad-subcategory">Sub-category :</label>
                <select
                    v-model="selectedLevel3Category"
                    class="form-control input-sm"
                    id="filter-classified-ad-subcategory"
                >
                    <option :value="null">All sub-categories</option>
                    <option
                        v-for="category in level3Categories"
                        :value="category.uuid"
                    >
                        {{ category.name }} ({{ getCount(category.uuid) }})
                    </option>
                </select>
            </li>
        </ul>
    </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    inject: ['App'],
    props: {
      categoryUuid: {
        type: String,
        required: false,
      },

      memberUuid: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        selectedIndustry: null,
        selectedLevel2Category: null,
        selectedLevel3Category: null,
        level2Categories: [],
        level3Categories: [],
        loading: false,
        myClassifiedAdsCategoriesRaw: {},
        myClassifiedAdsCategories: {},
        myIndustries: [],
        myLevel2Categories: [],
        myLevel3Categories: [],
      };
    },

    created() {
      this.loading = true;

      this.App.registerOnReadyListener(() => {
          this.loadCategories().then(() => {
              this.loadMyClassifiedAdsCategories({
                uuid: this.memberUuid,
              }).then((data) => {
                this.myClassifiedAdsCategoriesRaw = data;
                this.initMyCategories();
                this.initFilters();
                this.loading = false;
              });
          });
      });
    },

    watch: {
      selectedCategory(selectedCategory) {
        if(!this.loading) {
            $(this.$refs.formCategoryInput).val(selectedCategory).trigger('change');
        }
      },
    },

    computed: {
      ...mapState({
        categories: state => state.offer.categories,
      }),

      ...mapGetters([
        'getCategory',
      ]),

      selectedCategory() {
        return this.selectedLevel3Category || this.selectedLevel2Category || this.selectedIndustry || null;
      },

      industryFilterSelected() {
        return this. selectedIndustry !== null;
      },

      level2CategoryFilterSelected() {
        return this. selectedIndustry !== null && this.selectedLevel2Category !== null;
      },
    },

    methods: {
      ...mapActions([
        'loadCategories',
        'loadMyClassifiedAdsCategories',
      ]),

      initMyCategories() {
        this.myClassifiedAdsCategoriesRaw.forEach((object) => {
          this.myClassifiedAdsCategories[object.data.uuid] = (this.myClassifiedAdsCategories[object.data.uuid] || 0) + object.count;
          const category = this.getCategory(object.data.uuid);

          // Add each category to each array, depending on its level
          // And update category count and parents

          // Category is level 1 (industry)
          if (! category.parent) {
            this.addItemToArray(category, this.myIndustries);
          } else {
            const parent = this.getCategory(category.parent);
            this.myClassifiedAdsCategories[category.parent] = (this.myClassifiedAdsCategories[category.parent] + parseInt(object.count)) || object.count;

            // Category is level 2 (old for that shit)
            if (! parent.parent) {
              this.addItemToArray(category, this.myLevel2Categories);
              this.addItemToArray(parent, this.myIndustries);

              // Category is level3 (leaf category)
            } else {
              this.addItemToArray(category, this.myLevel3Categories);
              this.addItemToArray(parent, this.myLevel2Categories);
              this.addItemToArray(this.getCategory(parent.parent), this.myIndustries);
              this.myClassifiedAdsCategories[parent.parent] = (this.myClassifiedAdsCategories[parent.parent] + parseInt(object.count)) || object.count;
            }
          }
        });
      },

      setLevel2Categories(parentUuid) {
        const selectedParent = parentUuid;

        this.level3Categories = null;
        this.selectedLevel2Category = null;
        this.selectedLevel3Category = null;

        this.level2Categories = this.myLevel2Categories.filter((category) => {
          return category.parent === selectedParent;
        });
      },

      setLevel3Categories(parentUuid) {
        const selectedParent = parentUuid;

        this.selectedLevel3Category = null;

        this.level3Categories = this.myLevel3Categories.filter((category) => {
          return category.parent === selectedParent;
        });
      },

      getCount(uuid) {
        return this.myClassifiedAdsCategories[uuid] || 0;
      },

      initFilters() {
        if (this.categoryUuid) {
          const initCategory = this.getCategory(this.categoryUuid);
          const firstParent = this.getCategory(initCategory.parent);
          const secondParent = firstParent && this.getCategory(firstParent.parent);

          if (secondParent) {
            this.selectedIndustry = secondParent.uuid;
            this.setLevel2Categories(this.selectedIndustry);
            this.selectedLevel2Category = firstParent.uuid;
            this.setLevel3Categories(this.selectedLevel2Category);
            this.selectedLevel3Category = initCategory.uuid;
          } else if (firstParent) {
            this.selectedIndustry = firstParent.uuid;
            this.setLevel2Categories(this.selectedIndustry);
            this.selectedLevel2Category = initCategory.uuid;
            this.setLevel3Categories(this.selectedLevel2Category);
            this.selectedLevel3Category = null;
          } else {
            this.selectedIndustry = initCategory.uuid;
            this.setLevel2Categories(this.selectedIndustry);
            this.selectedLevel2Category = null;
            this.selectedLevel3Category = null;
          }
        }
      },

      addItemToArray(item, array) {
        if (array.indexOf(item) === -1) {
          array.push(item);
        }
      },
    },
  }
</script>
