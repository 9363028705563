import { OfferType as OFFER_TYPES } from "../../../../../../../Common/js/helpers/enums";

const choices = {
  USED_MACHINE: 1,
  NEW_MACHINE: 2,
  AUCTION: 3,
  JOB: 4,
  RESUME: 5,
};

export default choices;

const labels = {
  [choices.USED_MACHINE]: "Used Machine or Equipment",
  [choices.NEW_MACHINE]: "New Machine or Equipment",
  [choices.AUCTION]: "Auctions",
  [choices.JOB]: "Post a job",
  [choices.RESUME]: "Post a resume - CV",
};

export function getChoiceLabel(choice) {
  return labels[choice] || null;
}

/**
 * Determine the choice (@see choices) that corresponds to the offer, based on its type and class (used|new)
 *
 * @param {Object} offer
 *
 * @returns {number}
 */
export function getChoiceForOffer(offer) {
  switch (offer?.type) {
    case OFFER_TYPES.MACHINE:
      return offer.used ? choices.USED_MACHINE : choices.NEW_MACHINE;
    case OFFER_TYPES.AUCTION:
      return choices.AUCTION;
    case OFFER_TYPES.JOB:
      return choices.JOB;
    case OFFER_TYPES.RESUME:
      return choices.RESUME;
    default:
      return choices.USED_MACHINE;
  }
}
