<template>
    <input v-model="value" :class="classes" :required="required" />
</template>

<script>
  import dateHelpers from '../../filters';

  /**
   * @see https://vuejsdevelopers.com/2017/05/20/vue-js-safely-jquery-plugin/
   */
  export default {
    props: {
      value: {
        type: String,
        required: false,
      },
      startDate: {
        type: Date,
        required: false,
      },
      classes: {
        type: [Array, Object, String],
        required: false,
        default: () => '',
      },
      required: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    mounted: function() {
      $(this.$el)
        .val(dateHelpers.formatDate(this.value, 'MM/DD/YYYY'))
        .datepicker({
          startDate: this.startDate,
        }).on('changeDate', (evt) => {
          this.$emit('update-date', $(evt.target).val());
        });
    },
    beforeDestroy: function() {
      $(this.$el).datepicker('hide').datepicker('destroy');
    },
  };
</script>
