import Routing from 'fos-routing';

// https://symfony.com/doc/master/bundles/FOSJsRoutingBundle/usage.html#generating-uris
if (typeof window.fosJsDevData !== 'undefined') {
  // On dev mode, we use the controller to get route definitions.
  // A Jsonp callback set the data in the "window.fosJsDevData" var,
  // so we can retrieve them here to configure the instance:
  Routing.setData(window.fosJsDevData);
}

export default Routing;
