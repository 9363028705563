import { executeOnChange } from '../helpers/forms';

/**
 * Helpers for Select2 widgets used with v-model
 */
export default {
  methods: {
    getSelect2Input() {
      return $(this.$el);
    },

    __select2_emit(value = undefined) {
      return (e, payload = {}) => {
        if (!payload.ignoreEmit) {
          // Allows to ignore emitting to avoid doing it twice (on manual .trigger for instance):
          this.$emit('input', value === undefined ? this.getSelect2Input().val() : value);
        }
      }
    },

    __select2_watch(newValue, oldValue) {
      executeOnChange(newValue, oldValue, () => {
        // Should only be executed on different values (otherwise may be an infinite loop):
        this.$nextTick(() => {
          this.getSelect2Input().val(newValue).trigger('change', { ignoreEmit: true })
        });
      });
    },
  },

  destroyed() {
    this.getSelect2Input().off().select2('destroy');
  },
};
