var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "shadow-container__content" }, [
      _vm.loading
        ? _c("div", { staticClass: "fa fa-spin fa-spinner" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading",
            },
          ],
          staticClass: "form-bloc",
        },
        [
          _c("h2", { staticClass: "form-bloc__title" }, [_vm._v("Categories")]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn--primary flex-right",
              attrs: { type: "submit", disabled: _vm.saving },
              on: { click: _vm.onSubmit },
            },
            [
              _vm.saving
                ? _c("span", { staticClass: "fa fa-spin fa-spinner" })
                : _vm._e(),
              _vm._v("Save modifications"),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.sendError,
                  expression: "sendError",
                },
              ],
              staticClass: "alert alert-danger",
            },
            [_vm._v("\n                An error occurred\n            ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.sendSuccess,
                  expression: "sendSuccess",
                },
              ],
              staticClass: "alert alert-success",
            },
            [
              _vm._v(
                "\n                Your subscriptions have been registered\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "accordion",
              attrs: { id: "accordion", role: "tablist" },
            },
            [
              _c("div", { staticClass: "panel" }, [
                _c("div", { staticClass: "panel-heading" }, [
                  _vm._v(
                    "\n                        Total of alerts\n                        "
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "bullet-number",
                      class: {
                        "bullet-number--primary":
                          _vm.subscriptionsCount.all > 0,
                      },
                    },
                    [_vm._v(_vm._s(_vm.subscriptionsCount.all))]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.industries, function (industry) {
                return _c("div", { key: industry.uuid, staticClass: "panel" }, [
                  _c(
                    "div",
                    { staticClass: "panel-heading", attrs: { role: "tab" } },
                    [
                      _c(
                        "div",
                        {
                          class: [
                            _vm.isMinus(industry.uuid)
                              ? "check check--minus"
                              : "check",
                          ],
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.allCheckedCategoriesUuids,
                                expression: "allCheckedCategoriesUuids",
                              },
                            ],
                            attrs: { type: "checkbox", id: industry.uuid },
                            domProps: {
                              value: industry.uuid,
                              checked: Array.isArray(
                                _vm.allCheckedCategoriesUuids
                              )
                                ? _vm._i(
                                    _vm.allCheckedCategoriesUuids,
                                    industry.uuid
                                  ) > -1
                                : _vm.allCheckedCategoriesUuids,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.allCheckedCategoriesUuids,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = industry.uuid,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.allCheckedCategoriesUuids =
                                        $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.allCheckedCategoriesUuids = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.allCheckedCategoriesUuids = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: industry.uuid } }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.getCategoryChildren(industry.uuid).length > 0
                        ? _c(
                            "a",
                            {
                              attrs: {
                                role: "button",
                                "data-toggle": "collapse",
                                "data-parent": "#accordion",
                                href: "#" + industry.uuid + "-collapse",
                                "aria-expanded": "false",
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(industry.name))]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "bullet-number",
                                  class: {
                                    "bullet-number--primary":
                                      _vm.subscriptionsCount[industry.uuid] > 0,
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.subscriptionsCount[industry.uuid]
                                    )
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c("a", { staticClass: "no-collapse-display" }, [
                            _c("span", [_vm._v(_vm._s(industry.name))]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "bullet-number",
                                class: {
                                  "bullet-number--primary":
                                    _vm.subscriptionsCount[industry.uuid] > 0,
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.subscriptionsCount[industry.uuid])
                                ),
                              ]
                            ),
                          ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "panel-collapse collapse",
                      attrs: {
                        id: industry.uuid + "-collapse",
                        role: "tabpanel",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "panel-body" },
                        _vm._l(
                          _vm.getCategoryChildren(industry.uuid),
                          function (child) {
                            return _c(
                              "div",
                              { key: child.uuid, staticClass: "panel-row" },
                              [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      _vm.isMinus(child.uuid)
                                        ? "check check--minus"
                                        : "check",
                                    ],
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.allCheckedCategoriesUuids,
                                          expression:
                                            "allCheckedCategoriesUuids",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: child.uuid,
                                      },
                                      domProps: {
                                        value: child.uuid,
                                        checked: Array.isArray(
                                          _vm.allCheckedCategoriesUuids
                                        )
                                          ? _vm._i(
                                              _vm.allCheckedCategoriesUuids,
                                              child.uuid
                                            ) > -1
                                          : _vm.allCheckedCategoriesUuids,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a =
                                              _vm.allCheckedCategoriesUuids,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = child.uuid,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.allCheckedCategoriesUuids =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.allCheckedCategoriesUuids =
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.allCheckedCategoriesUuids = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", { attrs: { for: child.uuid } }),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.getCategoryChildren(child.uuid).length > 0
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "category-level",
                                        attrs: {
                                          role: "button",
                                          "data-toggle": "collapse",
                                          href: "#" + child.uuid + "-collapse",
                                          "aria-expanded": "false",
                                        },
                                      },
                                      [_vm._v(_vm._s(child.name))]
                                    )
                                  : _c("a", { staticClass: "category-level" }, [
                                      _vm._v(_vm._s(child.name)),
                                    ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "category-level last collapse",
                                    attrs: { id: child.uuid + "-collapse" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      _vm._l(
                                        _vm.getCategoryChildren(child.uuid),
                                        function (grandChild) {
                                          return _c(
                                            "div",
                                            {
                                              key: grandChild.uuid,
                                              staticClass: "check",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.allCheckedCategoriesUuids,
                                                    expression:
                                                      "allCheckedCategoriesUuids",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: grandChild.uuid,
                                                },
                                                domProps: {
                                                  value: grandChild.uuid,
                                                  checked: Array.isArray(
                                                    _vm.allCheckedCategoriesUuids
                                                  )
                                                    ? _vm._i(
                                                        _vm.allCheckedCategoriesUuids,
                                                        grandChild.uuid
                                                      ) > -1
                                                    : _vm.allCheckedCategoriesUuids,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.allCheckedCategoriesUuids,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = grandChild.uuid,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.allCheckedCategoriesUuids =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.allCheckedCategoriesUuids =
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                      }
                                                    } else {
                                                      _vm.allCheckedCategoriesUuids =
                                                        $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for: grandChild.uuid,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(grandChild.name)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  ),
                ])
              }),
            ],
            2
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "shadow-container__footer" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.sendError,
              expression: "sendError",
            },
          ],
          staticClass: "alert alert-danger",
        },
        [_vm._v("\n            An error occurred\n        ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.sendSuccess,
              expression: "sendSuccess",
            },
          ],
          staticClass: "alert alert-success",
        },
        [
          _vm._v(
            "\n            Your subscriptions have been registered\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn--primary",
          attrs: { type: "submit", disabled: _vm.saving },
          on: { click: _vm.onSubmit },
        },
        [
          _vm.saving
            ? _c("span", { staticClass: "fa fa-spin fa-spinner" })
            : _vm._e(),
          _vm._v("Save modifications"),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }