import Sentry from '../../Common/js/sentry';

Sentry.configure();

import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.js';
import 'bootstrap/js/alert.js';
import 'bootstrap/js/collapse.js';
import 'bootstrap/js/dropdown.js';
import 'bootstrap/js/transition.js';
import 'bootstrap/js/modal.js';
import 'bootstrap/js/tooltip.js';
import 'bootstrap/js/carousel.js';
import 'bootstrap/js/tab.js';
import 'lightbox2/src/js/lightbox.js';
import 'select2/dist/js/select2.min.js';
import './modules/Common/modal-stack';
import './modules/Common/plugins/toggle-url';

import Vue from 'vue';
import VueResource from 'vue-resource';
import VueConstants from './modules/Common/plugins/constants';
import filters from '../../Common/js/filters';

/**
 * Helper to prevent code being executed during an arbitrary delay.
 * Another delay call will replace previous stored code to execute.
 */
const delay = function (callback, ms, timer = 0) {
  clearTimeout(timer);
  return setTimeout(callback, ms);
};

// Main component
import App from './App';

// Components
import RequestPrice from './modules/Offer/components/RequestPrice';
import SubscribeToCategory from './modules/Member/components/SubscribeToCategory';

// Main Vuex store
import store from './modules/Common/store/index';

Vue.use(VueResource);
Vue.use(VueConstants);

// Custom Vue filters (https://vuejs.org/v2/guide/filters.html)
for (const fnName in filters) {
  Vue.filter(fnName, filters[fnName]);
}

// Init app
new Vue({
  delimiters: ['${', '}'],
  store,
  el: '#app',
  components: { App },
});

// Auto submit forms
$(() => {
  $('.js-auto-submit').on('change', function() {
      $('#' +$(this).data('target-form')).trigger('submit');
  });
});

// Mobile menu
$(() => {
  const MENU_EXPANDED_CLASS = 'mobile-nav--expand';
  const MENU_INVISIBLE_CLASS = 'invisible';
  const MENU_HIDDEN_CLASS = 'hidden';
  $('.mobile-burger').on('click', function () {
    const menu = $(this).next('.mobile-nav');
    menu.toggleClass(MENU_EXPANDED_CLASS);
  });
  $('.mobile-nav .mobile-nav__top a.close').on('click', function () {
    const menu = $(this).parents('.mobile-nav');
    menu.removeClass(MENU_EXPANDED_CLASS);
  });
  $('.mobile-nav .mobile-nav__top a.back').on('click', function () {
    $('.mobile-nav .mobile-nav__n1').removeClass(MENU_HIDDEN_CLASS);
    $('[data-toggle="menu-subnav"]').each((i, item) => {
      let target = $(item).data('target');
      $(target).addClass(MENU_HIDDEN_CLASS);
    });
    $('.mobile-nav .mobile-nav__top a.previous').addClass(MENU_INVISIBLE_CLASS);
  });
  $('[data-toggle="menu-subnav"]').on('click', function () {
    const target = $(this).data('target');
    $('.mobile-nav .mobile-nav__n1').addClass(MENU_HIDDEN_CLASS);
    $(target).removeClass(MENU_HIDDEN_CLASS);
    $('.mobile-nav .mobile-nav__top a.previous').removeClass(MENU_INVISIBLE_CLASS);
  });
});

// Mobile search
$(() => {
  const SEARCH_EXPANDED_CLASS = 'search-banner--expand';
  $('.mobile-search__toggle').on('click', function () {
    const search = $('.search-banner');
    const searchBannerHeight = search.height();
    const contentBelow = $('.content');
    search.toggleClass(SEARCH_EXPANDED_CLASS);
    // contentBelow.toggleClass('content--expand');
  });
}
);


// Mobile nav categories
$(() => {
  const MOBILE_NAV_CATEGORIES_EXPANDED_CLASS = 'active';
  const MOBILE_NAV_CATEGORIES_HIDDEN_CLASS = 'hidden';
  
  $('.category-nav__toggle').on('click', function () {
    const categories = $('.category-nav__columns');
    categories.toggleClass(MOBILE_NAV_CATEGORIES_EXPANDED_CLASS);

  });
})

// TODO: move all of this in dedicated functions & files
$(() => {
  $('[data-toggle="tooltip"]').tooltip();
});

$(() => {
  // Handle per page filters
  $('.filter--per-page').on('change', function (e) {
    const input = e.target;
    const perPageValue = $(input).val();
    const perPageParam = $(this).data('per-page-query-param');
    const pageParam = $(this).data('page-query-param');
    const url = new URL(window.location.toString());
    const searchParams = new URLSearchParams(url.search);
    searchParams.set(perPageParam, perPageValue);
    searchParams.set(pageParam, 1);
    url.search = searchParams.toString();
    window.location.assign(url.toString());
  });
});

$(() => {
  // Filter ".filter-search" items according to input
  const FILTER_SEARCH_DELAY = 500;
  $('.filter-search').on('input', function () {
    const input = $(this).children('input[type="text"]').first();
    const handleId = delay(() => {
      const inputValue = input.val();
      const filterItems = $(this).next('.check-filter').children('.check');
      filterItems.each((i, item) => {
        item = $(item);
        if (item.find('label').text().toLowerCase().includes(inputValue.toLowerCase())) {
          item.removeClass('hidden')
        } else {
          item.addClass('hidden')
        }
      });
    }, FILTER_SEARCH_DELAY, input.data('timer-handle-id') || 0);
    input.data('timer-handle-id', handleId);
  });
});

$(() => {
  // Change button icon on input events (toggle between clear & search icon)
  $('.filter-search input').on('input', function () {
    const input = $(this);
    const icon = input.parent('.filter-search').children('button.filter-search--clear').find('i');
    if (0 === input.val().length) {
      icon.removeClass('icon--clear');
      icon.addClass('icon--search');
    } else {
      icon.removeClass('icon--search');
      icon.addClass('icon--clear');
    }
  });
  // Clear button for ".filter-search" inputs
  $('button.filter-search--clear').on('click', function () {
    $(this).prevAll('input').each((i, input) => {
      input = $(input);
      input.val('');
      input.trigger('input');
    });
  });
});

$(() => {
  const EXPANDED_CLASS = 'check-filter--expand';
  $('.check-collapse').on('click', function () {
    const button = $(this);
    const collapsibleFilters = button.prev('.check-filter');

    // Toggle expanded
    if (collapsibleFilters.hasClass(EXPANDED_CLASS)) {
      collapsibleFilters.removeClass(EXPANDED_CLASS)
    } else {
      collapsibleFilters.addClass(EXPANDED_CLASS)
    }
  });
});

$(() => {
  const submittedFacetField = $('input#filter_submitted_facet');
  // Auto-submit filters on click (desktop)
  $('form .check-filter[data-facet-name]').on('input', function () {
    const facetName = $(this).data('facet-name');
    // Set facet name in hidden input:
    submittedFacetField.val(facetName);
    // Submit form && disable any input:
    const form = $(this).closest('form');
    form.submit();
    form.find(':input').attr('disabled', 'disabled');
  });
});

$(() => {
  $('a.unprevent_click_modifiers').on('click', function (event) {
    // Don't prevent ctrl/shift + click usages on links!!
    if (event.ctrlKey || event.metaKey || event.shiftKey) {
      event.stopPropagation();
    }
  })
});

/**
 * Offer list - show each offer in a modal
 *
 * If empty, the content of the modal is Ajax-loaded.
 */
$(() => {
  $('.modal--product').on('show.bs.modal', function (event) {
    const modal = $(this);

    // Already loaded, do nothing.
    if (!modal.find('.modal-body').is(':empty')) {
      return;
    }

    // Not loaded yet. Show loader & fetch content
    const button = $(event.relatedTarget);
    const url = button.data('show-offer-modal-url');
    const modalBody = modal.find('.modal-body');

    if (!url) {
      // No url, ignore
      return;
    }

    // Store show offer uri as the url to redirect to when registering
    localStorage.setItem('afterSignUpUri', url);

    // add class .modal-body--loading to .modal-body
    modalBody.addClass('modal-body--loading');
    modalBody.html('<i class="spinner spinner--large"></i>');
    modalBody.load(url, (responseText, textStatus, xhr) => {
      if (200 !== xhr.status) {
        // on error:
        return modalBody.html(`
            <div class="alert alert-danger">
                <span>An error occured</span>
            </div>
        `);
      }

      modal.data('loaded', true);
      modalBody.removeClass('modal-body--loading');
      const requestPriceComponent = modal.find(`[data-request-price-component]`);

      // TODO: Find a better way. This is asking for troubles. We already cannot access injected services from main App. :/
      new Vue({
        delimiters: ['${', '}'],
        store,
        el: '#' + requestPriceComponent.attr('id'),
        components: { RequestPrice, SubscribeToCategory },
      });
    });
  }).on('hidden.bs.modal', function (event) {
    // Clear URI to use after sign up:
    localStorage.removeItem('afterSignUpUri');

    const modal = $(this);
    const button = $(event.relatedTarget);
    const url = button.data('show-offer-modal-url');

    if (!url) {
      // No url, ignore
      return;
    }

    // Clear modal content & loader modifier if not properly loaded on modal hidden:
    if (!modal.data('loaded')) {
      const modalBody = modal.find('.modal-body');
      modalBody.removeClass('modal-body--loading');
      modalBody.empty();
    }
  });
});

$(() => {
  $(".js-hidden-select2").select2();

  let select2el = $(".js-hidden-select2").next();

  // Hide select2 when page is loaded or when it is submitted
  $(select2el).hide();

  // Show select2 on click on toggle element
  $(".display-hidden-select2").click(function() {
    $(select2el).show();
    return false;
  });

});

$(() => {
  /**
   * Classified Ads List - Delete helper for desktop
   */

  const table = document.querySelector('.table-ads');

  if (null === table) {
    return;
  }
  
  const mainCheckBox = table.querySelector('#select-all-classified-ads');
  const allCheckboxes = table.querySelectorAll('.checkbox-desktop');
  const divCheckbox = table.querySelector('.check');
  const submitConfirm = table.querySelector('button');

  mainCheckBox.addEventListener('change', event => {
    eventOnMainCheckbox(divCheckbox, mainCheckBox, allCheckboxes);
  });

  allCheckboxes.forEach(checkbox => {
    checkbox.addEventListener('change', event => {
      const checkboxesChecked = table.querySelectorAll('input[type=checkbox].checkbox-desktop:checked').length;
      eventOnCheckboxes(checkboxesChecked, mainCheckBox, divCheckbox, allCheckboxes);
    })
  });

  submitConfirm.addEventListener('click', event => {
    const checkboxesChecked = table.querySelectorAll('input[type=checkbox].checkbox-desktop:checked').length;
    confirmDeleteForm(checkboxesChecked, event);
  });

  /**
   * Classified Ads List - Delete helper for mobile
   */

  const tableAdsMobile = document.querySelector('#main--mobile');
  const mainCheckBoxMobile = tableAdsMobile.querySelector('#mobile-select-all-classified-ads');
  const allCheckboxesMobile = tableAdsMobile.querySelectorAll('.checkbox-mobile');
  const divCheckboxMobile = document.querySelector('.check-ad-mobile');
  const submitConfirmMobile = tableAdsMobile.querySelector('button');

  mainCheckBoxMobile.addEventListener('change', event => {
    eventOnMainCheckbox(divCheckboxMobile, mainCheckBoxMobile, allCheckboxesMobile)
  });

  allCheckboxesMobile.forEach(checkbox => {
    checkbox.addEventListener('change', event => {
      const checkboxesChecked = tableAdsMobile.querySelectorAll('input[type=checkbox].checkbox-mobile:checked').length;
      eventOnCheckboxes(checkboxesChecked, mainCheckBoxMobile, divCheckboxMobile, allCheckboxesMobile);
    })
  });

  submitConfirmMobile.addEventListener('click', event => {
    const checkboxesChecked = tableAdsMobile.querySelectorAll('input[type=checkbox].checkbox-mobile:checked').length;
    confirmDeleteForm(checkboxesChecked, event);
  });
});

/**
 * Classified Ads List - Methods to manage Delete Helper
 */

function eventOnMainCheckbox(divCheckbox, mainCheckBox, allCheckBox) {
  if(divCheckbox.classList.contains('check--minus')) {
    divCheckbox.classList.remove('check--minus');
    mainCheckBox.checked = false;
    allCheckBox.forEach(checkbox => checkbox.checked = false);
  } else {
    if (mainCheckBox.checked) {
      allCheckBox.forEach(checkbox => checkbox.checked = true);
    } else {
      allCheckBox.forEach(checkbox => checkbox.checked = false);
    }
  }
}

function eventOnCheckboxes(checkboxesChecked, mainCheckBox, divCheckbox, allCheckboxes) {
  if (checkboxesChecked === 0) {
    mainCheckBox.checked = false;
    divCheckbox.classList.remove('check--minus');
  } else if (allCheckboxes.length === checkboxesChecked) {
    divCheckbox.classList.remove('check--minus');
    mainCheckBox.checked = true;
  } else if (checkboxesChecked > 0) {
    mainCheckBox.checked = false;
    divCheckbox.classList.add('check--minus');
  }
}

function confirmDeleteForm(checkboxesChecked, event) {
  if (checkboxesChecked === 0) {
    event.preventDefault();
  } else {
    if(!confirm('Do you really want to delete ' + checkboxesChecked + ' classified ad(s) ?')) {
      event.preventDefault();
    }
  }
}
