<template>
    <div>
        <div class="form-bloc">
            <h1 class="form-bloc__title">Please choose your option</h1>
            <div class="pricing">
                <!-- Free option -->
                <div class="pricing__item" v-if="suggestFree">
                    <div class="pricing__header">
                        <span class="title">Basic Classified Ad</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>5 photos</span>
                    </div>
                    <div class="pricing__footer">
                        <span class="price">Free ad for 24H</span>
                        <button class="btn btn--primary" @click.prevent="onSubmitFree">Choose this option</button>
                    </div>
                </div>

                <!-- Single Premium option -->
                <div class="pricing__item">
                    <div class="pricing__header">
                        <span class="title">Premium Classified Ad</span>
                        <div class="subtitle">Premium status ensures maximum exposure for your Auction</div>
                        <img src="/build/images/premium.png" alt="">
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Viewable by all visitors through the end of the auction</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Your auction is at the top of the search listings</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Direct link to your lot listings/catalog on your website</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Inclusion of your auction through its end date in our Weekly Auction Newsletter</span>
                    </div>
                    <div class="pricing__advantage">
                        <i class="icon icon--check"></i>
                        <span>Unlimited photos, pdf files and videos</span>
                    </div>
                    <div class="pricing__footer">
                        <span class="price">
                            185 euros for 1 month
                        </span>
                        <button class="btn btn--premium" @click.prevent="onSubmitSingle">Choose this option</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import PremiumOptionSelection from './../Premium/PremiumOptionSelection';

  export default {
    props: {
      suggestFree: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    methods: {
      onSubmitFree() {
        this.$emit('input', { method: PremiumOptionSelection.FREE });
      },
      onSubmitSingle() {
        this.$emit('input', { method: PremiumOptionSelection.SINGLE_PREMIUM_AD });
      },
    },
  };
</script>
