import { Role } from "../../../../Common/js/helpers/enums";

class Security {
  constructor(user = null) {
    this.user = user;
  }

  isAuthenticated() {
    return null !== this.user;
  }

  getUser() {
    return this.user;
  }

  /**
   * True when current user has the POSTER role but neither the ADMIN nor the COMMERCIAL one
   *
   * @returns {boolean}
   */
  isStrictPoster() {
    if (!this.isAuthenticated()) {
      return false;
    }

    const roles = this.user.roles;

    if (!roles.includes(Role.POSTER)) {
      return false;
    }

    // Note: COMMERCIAL role is included in the ADMIN role
    if (roles.includes(Role.COMMERCIAL)) {
      return false;
    }

    return true;
  }

  isGranted(role) {
    if (!this.isAuthenticated()) {
      return false;
    }

    return this.user.roles.includes(role);
  }
}

export default Security;
