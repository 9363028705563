import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client/core";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { ErrorLink } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

const uploadUri = "/graphql/";
const batchUri = "/graphql/batch";

const customFetch = (uri, options) =>
  fetch(uri, {
    ...options,
    // To allow forwarding cookies:
    // https://github.com/github/fetch/blob/7f71c9bdccedaf65cf91b450b74065f8bed26d36/README.md#sending-cookies
    credentials: "same-origin",
  });

// // create an apollo fetch instance with our hacked fetch instance
// const apolloFetch = createApolloFetch({
//   uri: batchUri,
//   customFetch
// });

// Query/Mutation batching:
// https://dev-blog.apollodata.com/query-batching-in-apollo-63acfd859862
const batchHttpLink = new BatchHttpLink({
  uri: batchUri,
  // Batch will be moved in apollo-link-http soon, and will use the same settings format as HttpLink
  // @see https://github.com/apollographql/apollo-link/pull/364
  fetch: customFetch,
});

// Support both batching and file uploads
// https://github.com/jaydenseric/apollo-upload-client/issues/34#issuecomment-372679857
const isObject = (node) => typeof node === "object" && node !== null;
const hasFiles = (node, found = []) => {
  Object.keys(node).forEach((key) => {
    if (!isObject(node[key]) || found.length > 0) {
      return;
    }

    if (
      (typeof File !== "undefined" && node[key] instanceof File) ||
      (typeof Blob !== "undefined" && node[key] instanceof Blob)
    ) {
      found.push(node[key]);
      return;
    }

    hasFiles(node[key], found);
  });

  return found.length > 0;
};

const httpLink = ApolloLink.split(
  ({ variables }) => hasFiles(variables),
  createUploadLink({
    uri: uploadUri,
    fetchOptions: {
      // To allow forwarding cookies:
      // https://github.com/github/fetch/blob/7f71c9bdccedaf65cf91b450b74065f8bed26d36/README.md#sending-cookies
      credentials: "same-origin",
    },
  }),
  batchHttpLink
);

// Global error handling on GraphQL calls
// https://www.apollographql.com/docs/link/links/error.html
const errorLink = new ErrorLink(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ api_problem, message, path, code }) => {
      let formattedError = `[GraphQL error]: Message "${message}" at path "${path}"`;
      if (code) {
        formattedError += ` (code: ${code})`;
      }
      // see App\Infra\Common\GraphQL\Error\InvalidPayloadError
      if (api_problem) {
        formattedError += ":";
        api_problem.violations.forEach(({ code, path, reason }) => {
          formattedError += `\n    - ✗ violation at path "${path}": "${reason}" (code: "${code}")`;
        });
      }
      console.error(formattedError);
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production",
});

export default apolloClient;
