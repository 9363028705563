var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modalFeedback",
      staticClass: "modal fade",
      attrs: { tabindex: "-1", role: "dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("p", [
              _c("span", { staticClass: "fa fa-spinner fa-spin" }),
              _vm._v(_vm._s(_vm.message)),
            ]),
            _vm._v(" "),
            _vm.hasFailed
              ? _c("p", { staticClass: "bg-danger" }, [
                  _c("strong", [_vm._v("Something went wrong. Retry please.")]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [
        _vm._v("Your ad is being processed ..."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }