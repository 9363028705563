<template>
    <div>
        <div v-if="!authenticated" class="product-contact__login">
            <a :href="loginUri" class="btn" :class="{
                'btn--premium': premium,
                'btn--auction': auction,
                'btn--primary': !premium,
            }">Contact the seller</a>

        </div>

        <div class="has-succeeded" v-show="hasSucceeded">
            <span>Your message has been sent to the seller</span>
        </div>

        <form v-if="authenticated && !hasSucceeded" @submit.prevent="onSubmit">
            <div class="has-error" v-if="hasFailed && !errors.content">An error occurred, please retry.</div>
            <div class="form-group" :class="{ 'has-error': !!errors.content }">
                <label :for="'request-price-content-' + uuid + (mobile && '-mobile')">Your message</label>
                <textarea
                    :id="'request-price-content-' + uuid + (mobile && '-mobile')"
                    class="form-control"
                    v-if="!isProcessing"
                    v-model="form.content"
                    :class="{
                       'has-error': !!errors.content,
                       'has-succeeded': hasSucceeded,
                       'form-control--auction': auction,
                       'form-control--premium': premium,
                       'form-control--primary': !premium,
                    }"
                ></textarea>
                <span v-if="errors.content" class="help-block">{{ errors.content }}</span>
                <div class="text-center"><span v-if="isProcessing" class="fa fa-spin fa-spinner"/></div>
            </div>
            <div class="checkbox">
                <label v-if="!auction">
                    <input type="checkbox" v-model="form.subscribe"> Send me email alerts for similar machines
                </label>
            </div>
            <div class="btn" :class="{
                'btn--fixed': mobile,
                'btn--premium': premium,
                'btn--auction': auction,
                'btn--primary': !premium,
            }">
                <button type="submit">
                    Click to Contact the Seller
                </button>
            </div>
        </form>
    </div>
</template>

<script>
  import stateMixin from '../../../../../Common/js/mixins/state';
  import { mapActions } from 'vuex';

  export default {
    mixins: [stateMixin],
    // inject: ['Security'], // Cannot inject Security here, as we create a new Vue app without it
    props: {
      uuid: {
        type: String,
        required: true,
      },
      authenticated: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      premium: {
        type: Boolean,
        required: true,
      },
      auction: {
        type: Boolean,
        required: true,
      },
      mobile: {
        type: Boolean,
        required: false,
        default: () => false,
      },
      loginUri: {
        type: String,
        required: true,
      },
      forgotPasswordUri: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        // form data
        form: {
          content: null,
          subscribe: false,
        },
        errors: {},
      };
    },

    methods: {
      ...mapActions([
        'requestPrice',
      ]),
      onSubmit() {
        this.validateForm();
        if (Object.keys(this.errors).length) {
          return;
        }
        this.setState(this.STATE.PROCESSING);
        this.requestPrice({
          uuid: this.uuid,
          payload: Object.assign({}, this.form),
        }).then(this.onSuccess, this.onFailure);
      },
      onSuccess() {
        this.setState(this.STATE.SUCCESS);
      },
      onFailure(error) {
        (error.graphQLErrors || []).forEach((graphQLError) => {
          if(typeof graphQLError.api_problem !== "undefined") {
            this.errors.content = graphQLError.api_problem.detail;
          }
        });

        this.setState(this.STATE.FAILURE);
        setTimeout(this.resetInitialState, 2000);
      },
      validateForm() {
        this.resetErrors();
        let errors = {};
        if (!this.form.content) {
          errors.content = 'You must type a message';
        }
        this.errors = errors;
      },
      resetErrors() {
        this.errors = {};
      },
    },
  };
</script>

<style lang="scss" scoped>
    .has-succeeded {
        color: #00a65a;
    }
    div.has-succeeded {
        padding: 10px 35px 0px;
    }
    input.has-succeeded {
        border: 1px solid #00a65a;
    }
    .has-error {
        color: #ff0000;
    }
    input.has-error {
        border: 1px solid #ff0000;
    }
</style>
