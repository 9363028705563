<template>
    <div>
        <p class="modal-account__link">New on Wotol? <a href="" data-toggle="modal" data-target="#modal-signup" @click="showStep1">Create an account</a></p>
        <div v-if="displayStep1" ref="modalSignup" id="modal-signup" class="modal modal--account fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                      <div class="modal-header">
                          <span>Create your account</span>
                          <button type="button" class="close" data-dismiss="modal" aria-labelledby="close">
                              <i class="icon icon--close"></i>
                          </button>
                      </div>
                      <div class="modal-body">
                          <div class="modal-account">
                              <form @submit.prevent="" id="sign-up-form-part-1">
                                  <FormRow label="First Name" input-name="first-name" :errors="fieldErrors('firstname')">
                                      <template slot="input" slot-scope="props">
                                          <input type="text" id="first-name" name="first-name" required="required" v-model="form.firstname" :class="props._class">
                                      </template>
                                  </FormRow>

                                  <FormRow label="Last Name" input-name="last-name" :errors="fieldErrors('lastname')">
                                      <template slot="input" slot-scope="props">
                                          <input type="text" required="required" id="last-name" name="last-name" :class="props._class" v-model="form.lastname">
                                      </template>
                                  </FormRow>

                                  <FormRow label="Email" input-name="email-signup" :errors="fieldErrors('email')">
                                      <template slot="input" slot-scope="props">
                                          <input type="email" required="required" minlength="8" id="email-signup" name="email-signup" :class="props._class" v-model="form.email">
                                      </template>
                                  </FormRow>

                                  <FormRow label="Password (minimum 8 characters)" input-name="password-signup" :errors="fieldErrors('password')">
                                      <template slot="input" slot-scope="props">
                                          <input type="password" minlength="8" required="required" id="password-signup" name="password-signup" :class="props._class" v-model="form.password">
                                      </template>
                                  </FormRow>

                                  <div class="validation">
                                      <button ref="submit" @click="validateForm" class="btn btn--primary">Create your Wotol account</button>
                                      <p>By creating an account, you agree to <a :href="termsPageUri">Wotol's Conditions of Use</a> and <a :href="privacyPageUri">Privacy Notice</a></p>
                                  </div>
                              </form>
                              <p class="modal-account__link">Already have an account ? <a :href="loginPageUri">Sign In</a></p>
                          </div>
                      </div>
                </div>
            </div>
        </div>

        <div v-if="displayStep2" id="modal-one-more-thing" ref="modalOneMoreThing" class="modal modal--account fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <span>One more thing</span>
                        <span class="subtitle">Better knowledge, better answer</span>
                        <!--
                            reinitialize step1 & step2 bools in order to correctly display modal on next click on "sign up"
                            but it will not actually display the modal since the data-dismiss action will come up
                        -->
                        <button @click="showStep1" type="button" class="close" data-dismiss="modal" aria-labelledby="close">
                            <i class="icon icon--close"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-account">
                            <form @submit.prevent="onSubmit">
                                <FormRow label="Company" input-name="company" :errors="fieldErrors('company')">
                                    <template slot="input" slot-scope="props">
                                        <input type="text" id="company" name="company" :class="props._class" v-model="form.company">
                                    </template>
                                </FormRow>

                                <FormRow label="Country" input-name="country" :errors="fieldErrors('countryUuid')">
                                    <template slot="input" slot-scope="props">
                                        <SelectCountry
                                            v-model="form.countryUuid"
                                            :multiple="false"
                                            :classes="['form-control']"
                                            dropdownParent="#modal-one-more-thing"
                                        />
                                    </template>
                                </FormRow>

                                <FormRow label="Activity" input-name="activity" :errors="fieldErrors('activity')">
                                    <template slot="input" slot-scope="props">
                                        <select class="form-control" v-model="form.activity">
                                            <option v-for="(activity, key) in activities" v-bind:value="key">{{ activity }}</option>
                                        </select>
                                    </template>
                                </FormRow>

                                <FormRow label="Industry" input-name="industryUuid" :errors="fieldErrors('industryUuid')">
                                    <template slot="input">
                                        <select class="form-control" v-model="form.industryUuid">
                                            <option v-for="industry in industries" v-bind:value="industry.uuid">{{ industry.name }}</option>
                                        </select>
                                    </template>
                                </FormRow>

                                <div class="validation">
                                    <button class="btn btn--primary">Validate</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';
  import FormRow from './FormRow';
  import SelectCountry from '../../../../../../Common/js/components/form/SelectCountry';
  import {Activity as allActivities} from '../../../../../../Common/js/helpers/enums'
  import routes from '../../../Common/routes';


  export default {
    inject: ['Router'],

    components: {
      SelectCountry,
      FormRow,
    },

    constants: {
      STATE: {
        INITIAL: 'INITIAL',
        SAVING: 'SAVING',
        ERROR: 'ERROR',
      },
    },

    data() {
      return {
        state: this.STATE.INITIAL,
        form: {
          firstname: null,
          lastname: null,
          email: null,
          password: null,
          company: null,
          countryUuid: null,
          activity: null,
          industryUuid: null,
        },
        sendError: null,
        errors: {},
        activities: allActivities,
        displayStep1: true,
        displayStep2: false,
      }
    },

    watch: {
      isModalTwoVisible: function (val, oldVal) {
        this.displayStep1 = !val;
        this.displayStep2 = val;
      }
    },

    computed: {
      ...mapState({
        zones: state => state.common.geo.zones,
      }),

      ...mapGetters([
        'industries',
      ]),

      fieldErrors() {
        return (field) => {
          return this.errors[field] || [];
        };
      },

      privacyPageUri() {
        return this.Router.generate(routes.PRIVACY);
      },

      loginPageUri() {
        return this.Router.generate(routes.LOGIN);
      },

      termsPageUri() {
        return this.Router.generate(routes.TERMS_AND_CONDITIONS);
      },

      isModalTwoVisible() {
        return $("#modal-one-more-thing").attr('display') === 'block';
      },
    },

    methods: {
      ...mapActions([
        'userSubscribe',
        'loadIndustries',
      ]),

      saveAndQuit() {
        this.state = this.STATE.SAVING;
          this.errors = {};
          const payload = Object.assign({}, this.form);
          this.userSubscribe({payload}).then(this.onSuccess, this.onFailure);
      },

      onSuccess() {
        this.sendError = null;
        this.state = this.STATE.INITIAL;
        window.location = localStorage.getItem('afterSignUpUri') || this.Router.generate(routes.HOMEPAGE);
      },

      onFailure(error) {
        this.state = this.STATE.ERROR;
        this.sendError = error.message;

        // TODO: global error helper:
        (error.graphQLErrors || []).forEach((graphQLError) => {
          const errors = {};
            ((graphQLError.api_problem && graphQLError.api_problem.violations) || []).forEach(error => {
              if (!errors[error.path]) {
                errors[error.path] = [];
              }
              errors[error.path].push(error.reason);
            });
          this.errors = errors;
        });

        // On fail : hide step 2 and show step 1
        this.showStep1();
      },

      onSubmit() {
        this.saveAndQuit();
      },

      validateForm: function () {
        if($("#sign-up-form-part-1")[0].checkValidity()) {
          this.showStep2();
        }
      },

      showStep1() {
        this.displayStep1 = true;
        this.displayStep2 = false;
      },

      showStep2() {
        setTimeout(this.loadIndustries, 0);
        this.displayStep1 = false;
        this.displayStep2 = true;
      },
    }
  }
</script>
