<template>
    <div>
        <div class="form-bloc form-bloc--striped">
            <h2 class="form-bloc__title">Please fill in the description of your classified ad</h2>
            <div class="form-bloc__row">
                <div>
                    <label>Category</label>
                    <ul class="breadcrumb" v-if="offer.categoryUuid">
                        <li class="breadcrumb__item">{{ categoryName }}</li>
                    </ul>
                </div>
            </div>
            <div class="form-bloc__row">
                <div>
                    <label>Title</label>
                    <input type="text" class="form-control" v-model="form.title" :maxlength="maxLengths.TITLE" id="auction-information-title"  :class="{
                        'input-error': fieldError('title'),
                    }">
                    <div class="error" v-if="fieldError('title')">Title is required</div>
                </div>
            </div>
            <div class="form-bloc__row">
                <div>
                    <label>Country</label>
                    <SelectCountry
                        classes="form-control"
                        v-model="form.countryUuid"
                    />
                </div>
                <div>
                    <label for="auction-information-reference">Your reference</label>
                    <input type="text" class="form-control" v-model="form.reference" :maxlength="maxLengths.SELLER_REFERENCE" id="auction-information-reference">
                </div>
                <div>
                    <label>Dates</label>
                    <div class="input-daterange">
                        <input type="text" placeholder="from" class="form-control" data-field="startsAt" v-model="form.startsAt" :class="{
                            'input-error': fieldError('startsAt') || fieldError('dates'),
                        }">
                        <input type="text" placeholder="to" class="form-control" data-field="endsAt" ref="inputEndsAt" v-model="form.endsAt" :class="{
                            'input-error': fieldError('endsAt') || fieldError('dates'),
                        }">
                    </div>
                </div>
                <ul v-if="fieldError('endsAt') || fieldError('startsAt') || fieldError('dates')" class="date-errors">
                    <li class="error" v-if="fieldError('startsAt')">Start date is required</li>
                    <li class="error" v-if="fieldError('endsAt')">End date is required</li>
                    <li class="error" v-if="fieldError('dates')">Start date cannot be greater than end date</li>
                </ul>
                <div>
                    <label>External link</label>
                    <input type="text" class="form-control" :maxlength="maxLengths.ORIGINAL_URL" v-model="form.originalUrl" id="auction-information-url" placeholder="https://">
                </div>
            </div>
            <div class="form-bloc__row form-bloc__row--vertical">
                <label for="auction-information-description">Description</label>
                <textarea class="form-control" id="auction-information-description" v-model="form.description"></textarea>
            </div>
        </div>
        <div class="shadow-container__footer">
            <hr>
            <div class="inner-footer">
                <button class="button-back btn btn--empty" @click.prevent="$emit('goToPrevious')">
                    <i class="icon icon--previous"></i>
                    Back
                </button>
                <button class="button-next btn btn--primary" @click.prevent="onSubmit()">
                    Next
                    <i class="icon icon--next"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import SelectCountry from '../../../../../../../Common/js/components/form/SelectCountry';
  import { MaxLengths } from '../../../../../../../Common/js/model/Offer/Schema';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      SelectCountry,
    },
    props: {
      offer: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        maxLengths: MaxLengths,
        form: {
          countryUuid: null,
          reference: null,
          description: null,
          startsAt: null,
          endsAt: null,
          title: null,
          originalUrl: null,
        },
        errors: {},
      };
    },
    created() {
      this.initWithOffer();
      // Turn `startsAt` and `endsAt` inputs into datepicker
      this.$nextTick(() => {
        const vm = this;
        $('.input-daterange input').each(function() {
          // @see https://github.com/vuejs/vue/issues/4231#issuecomment-261551511
          $(this).datepicker().on('changeDate', () => {
            const field = $(this).data('field');
            vm.form[field] = $(this).val();

            // if date being picked is start date, auto focus end date input
            if (field === 'startsAt') {
              $(this).datepicker('hide');
              $(vm.$refs['inputEndsAt']).focus();
            }
          });
        });
      });
    },
    watch: {
      offer: function (val) {
        this.form.countryUuid = val.countryUuid;
        this.form.reference = val.reference;
        this.form.description = val.description;
        this.form.startsAt = val.startsAt;
        this.form.endsAt = val.endsAt;
        this.form.title = val.title;
        this.form.originalUrl = val.originalUrl;
      },
    },
    methods: {
      onSubmit() {
        this.validateForm();
        if (Object.keys(this.errors).length > 0) {
          return;
        }
        window.scrollTo(0,0);
        this.$emit('updated', this.form);
      },
      initWithOffer() {
        this.form = {
          countryUuid: this.offer.countryUuid,
          reference: this.offer.reference,
          description: this.offer.description,
          startsAt: this.offer.startsAt,
          endsAt: this.offer.endsAt,
          title: this.offer.title,
          originalUrl: this.offer.originalUrl,
        };
      },
      validateForm() {
        const errors = {};
        if (!this.form.startsAt) {
          errors.startsAt = true;
        }
        if (!this.form.endsAt) {
          errors.endsAt = true;
        }
        if (this.form.startsAt && this.form.endsAt) {
          if (Date.parse(this.form.startsAt) > Date.parse(this.form.endsAt)) {
            errors.dates = true;
          }
        }
        if (!this.form.title) {
          errors.title = true;
        }
        this.errors = errors;
      },
      fieldError(field) {
        return this.errors[field] || false;
      },
    },
    computed: {
      ...mapGetters([
        'getCategory',
      ]),
      category() {
        return this.offer.categoryUuid && this.getCategory(this.offer.categoryUuid);
      },
      categoryName() {
        return this.category && this.category.name;
      },
    },
  };
</script>

<style lang="scss" scoped>
    .input-error {
        border: 1px solid #ff0000;
    }
    .error {
        color: #ff0000;
        padding: 5px;
    }
    .input-daterange {
        width: 320px;
    }
    .input-group-addon {
        width: 100% !important;
    }
    ul.date-errors {
        margin-left: 135px;
    }
</style>
